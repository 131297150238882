import { Text, TextProps } from '@chakra-ui/layout';
import React from 'react';
import es from '../i11n/es/es.json';

export interface ITranslatorProps extends TextProps {
  children: any;
  mode?: 'onlyString' | 'component';
  prefix?: string;
}

export const RenderDecider = (wordToTranslate: string) =>
  es[wordToTranslate as keyof typeof es] || wordToTranslate;

export const TranslatorOnlyString = (
  props: Pick<ITranslatorProps, 'children'>
) => {
  return RenderDecider(props.children);
};

export const Translator: React.FC<ITranslatorProps> = (props) => {
  return (
    <Text {...props}>
      {props.prefix} {RenderDecider(props.children)}
    </Text>
  );
};
