import debounce from 'lodash.debounce'
import { useCallback, useContext, useEffect, useState } from 'react'
import { MESSAGE_TEMPLATES_QUERY_KEY } from '../components/MessageTemplates'
import {
  IMessageTemplate,
  MessageTemplateCtx,
} from '../providers/MessageTemplate.provider'
import { IMessageTemplateSchema } from '../schemas/message-template.schema'
import { useError } from './useError'
import { useQueryClientSingleton } from './useQueryClientSingleton'
import { useRouter } from 'next/router'
import { MessageTemplateTypes } from 'ai-commons'

export const useMessageTemplateEdit = (props: {
  messageTemplate: IMessageTemplate
}) => {
  const router = useRouter()
  const messageTemplateType: MessageTemplateTypes = router.query?.flow
  const formId = 'update-message-template'

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const [isLoading, setIsLoading] = useState(false)

  const { updateMethod, updateStatusMethod, multiple } =
    useContext(MessageTemplateCtx)

  const onUpdateMessageTemplate = useCallback(
    (data: IMessageTemplateSchema, onSuccess: () => void) => {
      setIsLoading(true)

      updateMethod(props.messageTemplate._id, {
        ...data,
        type: messageTemplateType,
      })
        .then((res) => {
          queryClient.invalidateQueries([MESSAGE_TEMPLATES_QUERY_KEY])

          onSuccess()
        })
        .catch(error.handleError)
        .finally(() => setIsLoading(false))
    },
    [messageTemplateType],
  )

  const [active, setActive] = useState<boolean>(
    props.messageTemplate.status === 'active',
  )

  const [statusTouched, setStatusTouched] = useState(false)

  const onChangeChecked = useCallback((checked: boolean) => {
    setActive(checked)
    setStatusTouched(true)
  }, [])

  const debounceSwitch = useCallback(debounce(onChangeChecked, 500), [])

  const onUpdateMessageTemplateStatus = useCallback((check: boolean) => {
    const data = {
      status: check ? 'active' : 'inactive',
      type: messageTemplateType,
    }
    updateStatusMethod(props.messageTemplate._id, data)
      .then((res) =>
        queryClient.invalidateQueries([MESSAGE_TEMPLATES_QUERY_KEY]),
      )
      .catch(error.handleError)
  }, [])

  useEffect(() => {
    if (statusTouched) {
      onUpdateMessageTemplateStatus(active)
    }
  }, [active, statusTouched])

  return {
    onUpdateMessageTemplateStatus,
    debounceSwitch,
    isLoading,
    formId,
    onUpdateMessageTemplate,
  }
}
