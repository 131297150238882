import { Box, Button, MenuButton, MenuItem, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { FiMoreHorizontal } from 'react-icons/fi'
import { GrTemplate } from 'react-icons/gr'
import { templateIconsByInspectionType } from '../constants/templateIconsByInspectionType'
import { ITemplate } from '../interfaces/inspection-template'
import { MenuCustom } from './MenuCustom'

export const TemplateCard = <T extends string>(props: {
  template: Pick<
    ITemplate,
    'name' | 'images' | 'usedCount' | 'inputs' | 'name' | 'inspectionType'
  >
  onClickMenuOption: (optId: T) => void
  menuOpts: {
    label: string
    id: T
    isDisabled?: boolean
  }[]
}) => {
  const InspectionTypeIcon =
    templateIconsByInspectionType[
      props.template
        .inspectionType as keyof typeof templateIconsByInspectionType
    ]

  return (
    <Box
      boxShadow={'lg'}
      borderRadius='lg'
      padding={'5'}
      borderColor={'gray.300'}
      borderWidth={'1px'}
      display='flex'
      flexDir={'column'}
      className='space-y-2'
      bg='white'
      height={'100%'}
    >
      <Box
        display={'flex'}
        justifyContent='space-between'
        alignItems={'center'}
        flexDir={'row'}
      >
        <Box
          className='space-x-5'
          display={'flex'}
          alignItems={'center'}
          flexDir={'row'}
        >
          <GrTemplate opacity={0.5} color='gray' fontSize={'1.5rem'} />
          {InspectionTypeIcon && (
            <InspectionTypeIcon width='30px' height='30px' />
          )}
        </Box>

        <MenuCustom
          menuListChildren={
            <>
              {props.menuOpts.map((ele) => {
                return (
                  <MenuItem
                    isDisabled={ele.isDisabled}
                    onClick={() => props.onClickMenuOption(ele.id)}
                  >
                    {ele.label}
                  </MenuItem>
                )
              })}
            </>
          }
        >
          <MenuButton bg='transparent' as={Button}>
            <FiMoreHorizontal />
          </MenuButton>
        </MenuCustom>
      </Box>
      <Box>
        <Text fontSize={''} fontWeight={'bold'}>
          {props.template.name}
        </Text>
        <Box
          alignItems={'center'}
          display={'flex'}
          flexDir='row'
          className='space-x-2'
          justifyContent='space-between'
        >
          <Box className='space-x-2' display={'flex'} flexDir='row'>
            <Text>{props.template.images.length} imágenes</Text>
            <Text>{props.template.inputs.length} campos</Text>
            <Text>{props.template.usedCount} usos</Text>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
