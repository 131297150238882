import { useCallback, useContext, useMemo, useState } from 'react'
import { useToast } from 'ui'
import { MESSAGE_TEMPLATES_QUERY_KEY } from '../components/MessageTemplates'
import {
  DeliveryChannel,
  IMessageTemplate,
  MessageTemplateCtx,
  deliveryChannels,
} from '../providers/MessageTemplate.provider'
import { IMessageTemplateSchema } from '../schemas/message-template.schema'
import { useError } from './useError'
import { useQueryClientSingleton } from './useQueryClientSingleton'
import { useRouter } from 'next/router'

export const useMessageTemplate = (props: {
  messageTemplates: IMessageTemplate[]
  currentChannel?: IMessageTemplate['channel']
}) => {
  const { postMethod, channels, multiple } = useContext(MessageTemplateCtx)!

  const router = useRouter()
  const messageTemplateType = router.query?.flow

  const findMessageTemplates = useCallback(
    (channel: IMessageTemplate['channel']) => {
      if (props.currentChannel === 'email') {
        return props.messageTemplates?.filter(
          (messageTemplate) =>
            messageTemplate.channel === channel &&
            messageTemplate.type === messageTemplateType,
        )
      }

      return props.messageTemplates?.filter(
        (messageTemplate) => messageTemplate.channel === channel,
      )
    },
    [props.messageTemplates, messageTemplateType],
  )

  const [createMessageTemplate, setCreateMessageTemplate] =
    useState<DeliveryChannel>()

  const formId = 'create-message-template-form'

  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const [tabIndex, setTabIndex] = useState(() => {
    if (props.currentChannel)
      return channels.findIndex((channel) => channel === props.currentChannel)

    return 0
  })

  const currentChannel = useMemo(() => channels[tabIndex], [tabIndex])

  const onCreateMessageTemplate = useCallback(
    (
      messageTemplate: IMessageTemplateSchema,
      channel: (typeof deliveryChannels)[number],
      onSuccess: () => void,
    ) => {
      setIsLoading(true)

      postMethod({
        template: messageTemplate,
        channel: channel,
        type: messageTemplateType,
      })
        .then((res) => {
          queryClient.invalidateQueries([MESSAGE_TEMPLATES_QUERY_KEY])

          onSuccess()

          setCreateMessageTemplate(undefined)
        })
        .catch(error.handleError)
        .finally(() => setIsLoading(false))
    },
    [createMessageTemplate, messageTemplateType],
  )

  const currentMessageTemplates = useMemo(() => {
    return findMessageTemplates(currentChannel)
  }, [tabIndex, props.messageTemplates, messageTemplateType])

  return {
    currentChannel,
    currentMessageTemplates,
    setTabIndex,
    onCreateMessageTemplate,
    tabIndex,
    isLoading,
    channels,
    multiple,
    createMessageTemplate,
    setCreateMessageTemplate,
    formId,
  }
}
