import { MutableRefObject, useLayoutEffect, useRef } from 'react'

export function useObjectRef<T>(
  forwardedRef?:
    | ((instance: T | null) => void)
    | MutableRefObject<T | null>
    | null,
): MutableRefObject<T> {
  const objRef = useRef<T>()

  useLayoutEffect(() => {
    if (!forwardedRef) {
      return
    }

    if (typeof forwardedRef === 'function') {
      forwardedRef(objRef.current)
    } else {
      forwardedRef.current = objRef.current
    }
  }, [forwardedRef])

  return objRef
}
