'use client'
import { VariantProps, cva } from 'class-variance-authority'
import { IconEye, IconEyeOff } from '@tabler/icons-react'
import * as React from 'react'
import { useDisclosure } from '../hooks/useDisclosure'
import { useSafeContext } from '../hooks/useSafeContext'
import { cn } from '../utils/cn'
import { Button } from './Button'

export const inputVariants = cva('', {
  variants: {
    size: {
      xs: 'h-6 px-2 text-xs',
      sm: 'h-8 px-3 text-sm',
      md: 'h-10 px-4',
      lg: 'h-12 px-6 text-lg',
    },
    variant: {
      outline:
        'rounded-md border border-input bg-transparent ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring/75 disabled:cursor-not-allowed disabled:opacity-50',
      filled:
        'bg-gray-100 rounded-md ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring/75 disabled:cursor-not-allowed disabled:opacity-50',
      flushed:
        'peer block w-full border-0 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 outline-none',
      unstyled: 'border-0 outline-none bg-transparent',
    },
    invalid: {
      true: 'ring-2 ring-destructive',
    },
    hasLeftAddon: {
      true: 'rounded-l-none',
    },
    hasRightAddon: {
      true: 'rounded-r-none',
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'outline',
  },
})

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {}

const InputLeftAddon = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      className={cn(
        'text-md px-3 rounded-l-md grow self-stretch border-y border-l rounded-r-none border-input bg-secondary ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 inset-y-0 left-0 flex items-center pl-3 pointer-events-none',
        className,
      )}
      {...props}
    />
  )
})

InputLeftAddon.displayName = 'InputLeftAddon'

const InputRightAddon = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      className={cn(
        'text-md px-3 h-full rounded-r-md rounded-l-none border-y border-r bg-secondary ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 inset-y-0 left-0 flex items-center pl-3 pointer-events-none',
      )}
      {...props}
    />
  )
})

InputRightAddon.displayName = 'InputRightAddon'

interface InputGroupProvider {
  size?: VariantProps<typeof inputVariants>['size']
  group: boolean
  hasLeftAddon?: boolean
  hasRightAddon?: boolean
}

const InputGroupContext = React.createContext<InputGroupProvider>({
  group: true,
  size: 'md',
})

const useInputGroupContext = () => useSafeContext(InputGroupContext)

const InputGroup = React.forwardRef<
  HTMLInputElement,
  Omit<React.HTMLProps<HTMLInputElement>, 'size'> &
    Omit<InputGroupProvider, 'group'>
>(({ className, size, ...props }, ref) => {
  return (
    <InputGroupContext.Provider
      value={{
        group: true,
        size,
        hasLeftAddon: React.Children.toArray(props.children).some(
          (ele) =>
            typeof ele === 'object' &&
            !!ele &&
            'type' in ele &&
            typeof ele.type === 'object' &&
            'displayName' in ele.type &&
            ele.type['displayName'] === InputLeftAddon.displayName,
        ),
        hasRightAddon: React.Children.toArray(props.children).some(
          (ele) =>
            typeof ele === 'object' &&
            !!ele &&
            'type' in ele &&
            typeof ele.type === 'object' &&
            'displayName' in ele.type &&
            ele.type['displayName'] === InputRightAddon.displayName,
        ),
      }}
    >
      <div
        className={cn(
          'flex flex-row items-center',
          inputVariants({
            size,
          }),
          'border-none outline-none',
          className,
        )}
        {...props}
      />
    </InputGroupContext.Provider>
  )
})

export type InputVariantProps = VariantProps<typeof inputVariants>

/**
 * Displays a form input field or a component that looks like an input field.
 */
const Input = React.forwardRef<
  HTMLInputElement,
  InputProps & Omit<InputVariantProps, 'group'>
>(({ className, type = 'text', size, variant, invalid, ...props }, ref) => {
  const ctx = useInputGroupContext()

  const password = useDisclosure(type === 'password')

  return (
    <div className={cn('relative', className)}>
      <input
        type={
          password.isOpen
            ? 'password'
            : type === 'password' && !password.isOpen
            ? 'text'
            : type
        }
        className={cn(
          'flex w-full text-sm px-3 py-2',
          inputVariants({
            hasLeftAddon: ctx.hasLeftAddon,
            hasRightAddon: ctx.hasRightAddon,
            group: ctx.group,
            variant,
            className,
            size,
            invalid,
          }),
        )}
        ref={ref}
        {...props}
      />
      {variant === 'flushed' && (
        <div
          className='absolute mx-1 inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-primary'
          aria-hidden='true'
        />
      )}

      {type === 'password' && (
        <div className='absolute top-0 right-0 bottom-0 h-full flex items-center justify-center p-2'>
          <Button onClick={password.onToggle} variant={'ghost'} size='xs'>
            {password.isOpen ? (
              <IconEyeOff className='text-gray-600 w-4 h-4' />
            ) : (
              <IconEye className='text-gray-600 w-4 h-4' />
            )}
          </Button>
        </div>
      )}
    </div>
  )
})
Input.displayName = 'Input'

export { Input, InputGroup, InputLeftAddon, InputRightAddon }
