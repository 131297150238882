import { useCallback, useContext, useState } from 'react'
import { MESSAGE_TEMPLATES_QUERY_KEY } from '../components/MessageTemplates'
import {
  DeliveryChannel,
  MessageTemplateCtx,
} from '../providers/MessageTemplate.provider'
import { IMessageTemplateSchema } from '../schemas/message-template.schema'
import { useError } from './useError'
import { useQueryClientSingleton } from './useQueryClientSingleton'

export const useMessageTemplateCreate = (props: { btnLabel?: string }) => {
  const btnLabel = props.btnLabel || 'Crear plantilla'

  const [createMessageTemplate, setCreateMessageTemplate] =
    useState<DeliveryChannel>()

  const formId = 'create-message-template-form'

  const { postMethod, channels } = useContext(MessageTemplateCtx)

  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()

  const error = useError()

  const onCreateMessageTemplate = useCallback(
    (messageTemplate: IMessageTemplateSchema, onSuccess: () => void) => {
      if (!createMessageTemplate) return

      setIsLoading(true)

      postMethod({
        template: messageTemplate,
        channel: createMessageTemplate,
      })
        .then((res) => {
          queryClient.invalidateQueries([MESSAGE_TEMPLATES_QUERY_KEY])

          onSuccess()

          setCreateMessageTemplate(undefined)
        })
        .catch(error.handleError)
        .finally(() => setIsLoading(false))
    },
    [createMessageTemplate],
  )

  return {
    onCreateMessageTemplate,
    createMessageTemplate,
    btnLabel,
    formId,
    isLoading,
    setIsLoading,
    setCreateMessageTemplate,
  }
}
