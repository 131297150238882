'use client'
import React, { useEffect, useState } from 'react'
import { Progress } from './Progress'

export const IndeterminateProgress = React.forwardRef<
  React.ElementRef<typeof Progress>,
  React.ComponentPropsWithoutRef<typeof Progress>
>((props, ref) => {
  const [value, setValue] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setValue((value) => {
        if (value >= 100) {
          return 0
        }

        return value + 10
      })
    }, 100)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return <Progress {...props} ref={ref} value={value} />
})
