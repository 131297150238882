// Other Imports Removed for Brevity

import { IconCaretDown, IconCheck } from '@tabler/icons-react'
import { forwardRef, useState } from 'react'
import { cn } from '../utils/cn'
import { Button, ButtonProps } from './Button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from './Command'
import { Popover, PopoverTrigger, PopoverContent } from './Popover'
import { ScrollArea } from './ScrollArea'

export interface ComboboxOption {
  value: string
  label: React.ReactNode | string
}

type ComboboxPropsSingle = {
  options: ComboboxOption[]
  emptyText?: string
  clearable?: boolean
  selectPlaceholder?: string
  searchPlaceholder?: string
  multiple?: false
  value?: string
  onValueChange?: (value: string) => void
  classNames?: {
    button?: string
  }
  properties?: {
    button?: ButtonProps
  }
}

type ComboboxPropsMultiple = {
  withSelectAll?: boolean
  options: ComboboxOption[]
  emptyText?: string
  clearable?: boolean
  selectPlaceholder?: string
  searchPlaceholder?: string
  multiple: true
  value?: string[]
  onValueChange?: (value: string[]) => void
  classNames?: {
    button?: string
    container?: string
  }
  properties?: {
    button?: ButtonProps
  }
}

const normalizeString = (value: string = '') =>
  value
    .toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

export type ComboboxProps = ComboboxPropsSingle | ComboboxPropsMultiple

export const handleSingleSelect = (
  props: ComboboxPropsSingle,
  option: ComboboxOption,
) => {
  if (props.clearable) {
    props.onValueChange?.(option.value === props.value ? '' : option.value)
  } else {
    props.onValueChange?.(option.value)
  }
}

export const handleMultipleSelect = (
  props: ComboboxPropsMultiple,
  option: ComboboxOption,
) => {
  if (props.value?.includes(option.value)) {
    if (!props.clearable && props.value.length === 1) return false
    props.onValueChange?.(props.value.filter((value) => value !== option.value))
  } else {
    props.onValueChange?.([...(props.value ?? []), option.value])
  }
}

export const ComboboxSelect = forwardRef(
  (
    { properties, classNames, ...props }: ComboboxProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const [open, setOpen] = useState(false)

    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            role='combobox'
            variant='outline'
            aria-expanded={open}
            className={cn(
              'w-full justify-between hover:bg-secondary/20 active:scale-100',
              classNames?.button,
            )}
            {...properties?.button}
          >
            <span className='line-clamp-1 text-left font-normal'>
              {props.multiple && props.value && props.value.length === 1 && (
                <span className='mr-2'>
                  {props.value
                    .map(
                      (value) =>
                        props.options.find((opt) => opt.value === value)!.label,
                    )
                    .join(', ')}
                </span>
              )}

              {props.multiple && props.value && props.value.length > 1 && (
                <span className='mr-2'>
                  {props.value.length} opciones seleccionadas
                </span>
              )}

              {!props.multiple &&
                props.value &&
                props.value !== '' &&
                props.options.find((option) => option.value === props.value)
                  ?.label}

              {(!props.value || props.value.length === 0) &&
                (props.selectPlaceholder ?? 'Select an option')}
            </span>
            <IconCaretDown
              className={cn(
                'ml-2 h-4 w-4 shrink-0 rotate-0 opacity-50 transition-transform',
                open && 'rotate-180',
              )}
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent align='start' className='p-0'>
          <Command
            filter={(value, search) => {
              const label = props.options
                .find((opt) => opt.value === value)
                ?.label?.toString()

              if (normalizeString(label).includes(normalizeString(search)))
                return 1

              return 0
            }}
          >
            <CommandInput
              ref={ref}
              placeholder={props.searchPlaceholder ?? 'Busca una opción'}
            />
            <CommandEmpty>
              {props.emptyText ?? 'No se encontraron resultados'}
            </CommandEmpty>
            <CommandGroup>
              <ScrollArea orientation='vertical'>
                <div className='max-h-60'>
                  {props.multiple && props.withSelectAll && (
                    <CommandItem
                      value='all'
                      onSelect={() => {
                        const selectedAll =
                          props.value?.length === props.options.length

                        if (props.multiple && props.onValueChange) {
                          if (selectedAll) {
                            props.onValueChange([])
                          }

                          if (!selectedAll) {
                            props.onValueChange(
                              props.options.map((opt) => opt.value),
                            )
                          }
                        }
                      }}
                    >
                      <IconCheck
                        className={cn('mr-2 h-4 w-4 opacity-0', {
                          'opacity-100':
                            props.value?.length === props.options.length,
                        })}
                      />
                      Seleccionar todos
                    </CommandItem>
                  )}

                  {props.options.map((option) => (
                    <CommandItem
                      value={option.value.toLowerCase().trim()}
                      onSelect={(selectedValue) => {
                        const option = props.options.find(
                          (option) =>
                            option.value.toLowerCase().trim() === selectedValue,
                        )

                        if (!option) return null

                        if (props.multiple) {
                          handleMultipleSelect(props, option)
                        } else {
                          handleSingleSelect(props, option)

                          setOpen(false)
                        }
                      }}
                    >
                      <IconCheck
                        className={cn(
                          'mr-2 h-4 w-4 opacity-0',
                          !props.multiple &&
                            props.value === option.value &&
                            'opacity-100',
                          props.multiple &&
                            props.value?.includes(option.value) &&
                            'opacity-100',
                        )}
                      />
                      {option.label}
                    </CommandItem>
                  ))}
                </div>
              </ScrollArea>
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    )
  },
)
