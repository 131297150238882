import React from 'react'
import { Spinner } from 'ui'

export const LoadingDataWrapperV2 = (props: {
  isLoading: boolean
  error?: unknown
  children: React.ReactNode
}) => {
  if (props.isLoading) {
    return (
      <div className='mt-5 w-full'>
        <Spinner />
      </div>
    )
  }

  if (props.error) {
    return <p>Algo no salio como se esperaba</p>
  }

  return <>{props.children}</>
}
