'use client'

import { Box, BoxProps } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { EphemeralFile } from 'bff'
import React, { useEffect, useRef, useState } from 'react'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { VariableSizeList } from 'react-window'
import usePrevious from '../hooks/usePrevious'
import { DocumentField, EditableField } from './DocumentEditionLayer'
import { LoadingDocumentSkeleton } from './LoadingDocumentSkeleton'

interface PDFViewerImagesProps {
  resizing?: boolean
  refreshPageURL: (refreshToken: string) => Promise<{ url: string }>
  style?: BoxProps['style']
  fields?: DocumentField[]
  onFieldChange?: (
    fieldIdentifier: string,
    state: Partial<DocumentField>,
  ) => void
  onDeleteField?: (fieldIdentifier: string) => void
  onFocusField?: (fieldIdentifier: string) => void
  onBlurField?: (fieldIdentifier: string) => void
  scale?: number
  onDocumentLoadSuccess?: (data: {
    clientWidth: number
    clientHeight: number
    originalWidth: number
    originalHeight: number
  }) => void
  fieldDimensions?: {
    width: number
    height: number
  }
  pages?: number
  responsive?: boolean
  onMouseDown?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index?: number,
  ) => void
  onMouseUp?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index?: number,
  ) => void
}

type DocumentSpecs = Pick<EphemeralFile, 'pages'>

const GAP_ITEM = 40

export const PAGE_IMAGE_CLASSNAME = 'pdf-page-displaying'

const PageImage = ({
  page,
  scale,
  style,
  refreshPageURL,
  dimensions,
}: {
  page: DocumentSpecs['pages'][number]
  scale: number
  dimensions: ImageDimensions
} & Pick<
  PDFViewerImagesProps,
  'style' | 'refreshPageURL' | 'onDocumentLoadSuccess'
>) => {
  const query = useQuery({
    placeholderData: {
      url: page.url,
    },
    queryKey: [`page-${page.key}`],
    queryFn: () => refreshPageURL(page.refreshToken),
    refetchInterval: 240000,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  return (
    <img
      className={PAGE_IMAGE_CLASSNAME}
      style={{
        width: `${dimensions.clientWidth * scale}px`,
        maxWidth: 'unset',
        ...style,
      }}
      onError={() => query.refetch()}
      src={query.data!.url}
    />
  )
}

type WindowItemData = Omit<PDFViewerImagesProps, 'pdf'> &
  DocumentSpecs['pages'][number] & {
    dimensions: ImageDimensions
  }

const WindowItem = ({
  data,
  index: pageIndex,
  style: containerStyle,
  ...props
}: {
  style: any
  index: number
  data: Array<WindowItemData>
}) => {
  const {
    style,
    fields,
    onFieldChange,
    onDeleteField,
    onFocusField,
    onBlurField,
    onMouseDown,
    onMouseUp,
    pages,
    responsive,
    scale,
    refreshPageURL,
    resizing,
    dimensions,
    ...page
  } = data[pageIndex]

  const isFirstPage = pageIndex === 0
  const isLastPage = pageIndex === data.length - 1

  return (
    <div
      style={{
        ...containerStyle,
        display: 'flex',
        paddingTop: isFirstPage ? 0 : `${GAP_ITEM}px`,
        paddingBottom: isLastPage || data.length === 1 ? 0 : `${GAP_ITEM}px`,
      }}
    >
      <Box
        onMouseDown={(e) => {
          if (e.button === 0 && onMouseDown) onMouseDown(e, pageIndex)
        }}
        onMouseUp={(e) => {
          if (e.button === 0 && onMouseUp) onMouseUp(e, pageIndex)
        }}
        zIndex={10}
        style={style}
      >
        <Box
          display={'flex'}
          alignItems='center'
          justifyContent={'start'}
          shadow='lg'
          bg='white'
          pos='relative'
        >
          <PageImage
            dimensions={dimensions}
            style={style}
            refreshPageURL={refreshPageURL}
            scale={scale!}
            page={page}
          />
          {!resizing &&
            (fields || [])
              .filter((field) => field.page === pageIndex)
              .map((field) => {
                if (field.readonly) {
                  return null
                }

                return (
                  <EditableField
                    onBlurField={() => {
                      if (onBlurField) {
                        onBlurField(field.identifier)
                      }
                    }}
                    onFocusField={() => {
                      if (onFocusField) {
                        onFocusField(field.identifier)
                      }
                    }}
                    onDelete={() => {
                      if (onDeleteField) {
                        onDeleteField(field.identifier)
                      }
                    }}
                    key={field.identifier}
                    field={field}
                    onFieldChange={(data) => {
                      if (onFieldChange) {
                        onFieldChange(field.identifier, data)
                      }
                    }}
                  />
                )
              })}
        </Box>
      </Box>
    </div>
  )
}

export const PDF_PREVIEW_WINDOW_HEIGHT = 640

interface ImageDimensions {
  originalWidth: number
  originalHeight: number
  clientWidth: number
  clientHeight: number
}

export const MeasureImage = ({
  onImageLoaded,
  src,
}: {
  src: string
  onImageLoaded: (specs: ImageDimensions) => void
}) => {
  return (
    <img
      onLoad={(e) => {
        onImageLoaded({
          clientHeight: e.currentTarget.clientHeight,
          clientWidth: e.currentTarget.clientWidth,
          originalWidth: e.currentTarget.naturalWidth,
          originalHeight: e.currentTarget.naturalHeight,
        })
      }}
      src={src}
    />
  )
}

export const PDFViewerImages = ({
  style,
  fields,
  onFieldChange,
  onDeleteField,
  onFocusField,
  onBlurField,
  onMouseDown,
  onMouseUp,
  pages,
  responsive = false,
  onDocumentLoadSuccess,
  scale = 1,
  document,
  refreshPageURL,
  resizing,
  itemHeight,
  setItemHeight,
}: PDFViewerImagesProps & {
  document: DocumentSpecs
  resizing?: boolean
  itemHeight: number
  setItemHeight: (height: number) => void
}) => {
  const [loaded, setLoaded] = useState(false)
  const [isLoadingImage, setIsLoadingImage] = useState(
    !document.pages.length ? false : true,
  )
  const listRef = useRef<VariableSizeList>(null)
  const [clientDimensions, setClientDimensions] = useState<ImageDimensions>()
  const [scrollOffset, setScrollOffset] = useState(0)

  const previousHeight = usePrevious(itemHeight)

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0)
      const heightRatio = (itemHeight + GAP_ITEM) / previousHeight!

      listRef.current.scrollTo(scrollOffset * heightRatio)
    }
  }, [itemHeight])

  return (
    <div className='w-full h-full relative'>
      {isLoadingImage && (
        <div className='absolute inset-0'>
          <LoadingDocumentSkeleton />
        </div>
      )}
      <div className='flex flex-col space-y-6'>
        {document.pages.length > 0 && isLoadingImage ? (
          <MeasureImage
            src={document.pages[0].url}
            onImageLoaded={(specs) => {
              console.log('specs', specs)

              setClientDimensions(specs)
              setIsLoadingImage(false)

              if (onDocumentLoadSuccess) {
                onDocumentLoadSuccess(specs)
              }
            }}
          />
        ) : (
          <VariableSizeList
            ref={listRef}
            onItemsRendered={(props) => {
              if (!props.visibleStopIndex && !loaded) {
                setLoaded(true)
              }
            }}
            itemCount={document.pages.length}
            itemData={document.pages.map(
              (page): WindowItemData => ({
                ...page,
                resizing: resizing || !loaded,
                style,
                fields,
                onFieldChange,
                onDeleteField,
                onFocusField,
                onBlurField,
                onMouseDown,
                onMouseUp,
                pages,
                responsive,
                scale,
                refreshPageURL,
                dimensions: clientDimensions!,
              }),
            )}
            className='custom-scrollbar'
            itemSize={() => itemHeight + GAP_ITEM}
            width={'100%'}
            height={PDF_PREVIEW_WINDOW_HEIGHT}
            onScroll={(props) => setScrollOffset(props.scrollOffset)}
          >
            {WindowItem}
          </VariableSizeList>
        )}
      </div>
    </div>
  )
}
