import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { IoMdAdd } from 'react-icons/io'
import { useMessageTemplateCreate } from '../hooks/useMessageTemplateCreate'
import { IMessageTemplate } from '../providers/MessageTemplate.provider'
import { MessageTemplateEditor } from './MessageTemplateEditor'
import { TranslatorOnlyString } from './Translator'

export const CreateMessageTemplate = (props: {
  channel: IMessageTemplate['channel']
  btnLabel?: string
}) => {
  const {
    btnLabel,
    createMessageTemplate,
    formId,
    isLoading,
    onCreateMessageTemplate,
    setIsLoading,
    setCreateMessageTemplate,
  } = useMessageTemplateCreate(props)

  const toast = useToast()

  return (
    <>
      <Button
        onClick={() => setCreateMessageTemplate(props.channel)}
        size='sm'
        mt='3'
        leftIcon={<IoMdAdd />}
        variant='primary'
      >
        {btnLabel}
      </Button>
      <Modal
        size='6xl'
        isOpen={!!createMessageTemplate}
        onClose={() => setCreateMessageTemplate(undefined)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Crear plantilla para{' '}
            {TranslatorOnlyString({ children: createMessageTemplate })}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <MessageTemplateEditor
              onSubmit={(data) =>
                onCreateMessageTemplate(data, () => {
                  toast({
                    description: 'Plantilla de mensaje creada',
                    status: 'success',
                    isClosable: true,
                  })
                })
              }
              formId={formId}
              channel={createMessageTemplate}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              type='submit'
              isDisabled={isLoading}
              isLoading={isLoading}
              form={formId}
              variant='primary'
              mr={3}
            >
              Crear
            </Button>
            <Button
              isDisabled={isLoading}
              onClick={() => setCreateMessageTemplate(undefined)}
              variant='ghost'
            >
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
