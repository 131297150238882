'use client'

import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { IconCheck, IconMinus } from '@tabler/icons-react'
import { cn } from '../utils/cn'
import { VariantProps, cva } from 'class-variance-authority'

const variants = cva('', {
  variants: {
    customIcon: {
      true: '',
    },
    size: {
      sm: 'h-3 w-3',
      md: 'h-4 w-4',
      lg: 'h-5 w-5',
    },
    invalid: {
      true: 'ring-2 ring-red-500 border-0',
    },
  },
  compoundVariants: [
    {
      customIcon: true,
      size: 'sm',
      className: '[&>*]:h-3 [&>*]:w-3',
    },
    {
      customIcon: true,
      size: 'md',
      className: '[&>*]:h-4 [&>*]:w-4',
    },
    {
      customIcon: true,
      size: 'lg',
      className: '[&>svg]:h-5 [&>svg]:w-5',
    },
  ],
  defaultVariants: {
    size: 'md',
  },
})

/**
 * A control that allows the user to toggle between checked and not checked.
 */
const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> &
    VariantProps<typeof variants> & {
      icon?: React.ReactNode
      indeterminate?: boolean
    }
>(({ className, invalid, size, icon, indeterminate, ...props }, ref) => {
  return (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        'border border-primary rounded  ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground',
        variants({
          size,
          className,
          invalid,
        }),
      )}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn('flex items-center justify-center text-current')}
      >
        {(() => {
          if (indeterminate) {
            return (
              <IconMinus
                className={variants({
                  size,
                })}
              />
            )
          }

          if (icon) {
            return (
              <span
                className={variants({
                  size,
                  customIcon: true,
                })}
              >
                {icon}
              </span>
            )
          }

          return (
            <IconCheck
              className={variants({
                size,
              })}
            />
          )
        })()}
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  )
})
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
