import React from 'react';
import { FaPaintBrush, FaShoppingBasket, FaUserAlt } from 'react-icons/fa';
import { GiFarmTractor } from 'react-icons/gi';
import BikeIcon from '../components/BikeIcon';
import CarFrontIcon from '../components/CarFrontIcon';
import FrontIconMoto from '../components/MotoFrontIcon';

export const templateIconsByInspectionType = {
  bike: BikeIcon,
  car: CarFrontIcon,
  moto: FrontIconMoto,
  goods: FaShoppingBasket,
  custom: FaPaintBrush,
  people: FaUserAlt,
  machinery: () => <GiFarmTractor fontSize={'2rem'} />,
};
