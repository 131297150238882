"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  DEFAULT_DECIMAL_PRECISION: () => DEFAULT_DECIMAL_PRECISION,
  EMPTY_RULE_ACTION: () => EMPTY_RULE_ACTION,
  MAX_PROGRESS_TRACKER_STEPS: () => MAX_PROGRESS_TRACKER_STEPS,
  MAX_WEBHOOKS_PER_COMPANY: () => MAX_WEBHOOKS_PER_COMPANY,
  REPORT_REQUEST_AUTHENTICATION_HEADER: () => REPORT_REQUEST_AUTHENTICATION_HEADER,
  VARIABLE_NAME_MAX_LENGTH: () => VARIABLE_NAME_MAX_LENGTH,
  VIDEO_MAX_DURATION: () => VIDEO_MAX_DURATION,
  alignments: () => alignments,
  alphabet: () => alphabet,
  assetActionReferences: () => assetActionReferences,
  assetTargets: () => assetTargets,
  assetTypes: () => assetTypes,
  booleanInputs: () => booleanInputs,
  buttonActions: () => buttonActions,
  buttonShowWhenConditions: () => buttonShowWhenConditions,
  buttonVariants: () => buttonVariants,
  canvasSlots: () => canvasSlots,
  carDamageModels: () => carDamageModels,
  colors: () => colors,
  companyVariableNames: () => companyVariableNames,
  conditionActionDispatches: () => conditionActionDispatches,
  conditionTermTargetsType: () => conditionTermTargetsType,
  conditionTermTypes: () => conditionTermTypes,
  conditionalOperatorsList: () => conditionalOperatorsList,
  conditionalsOperatorsRules: () => conditionalsOperatorsRules,
  constantDataType2VariableType: () => constantDataType2VariableType,
  containerSpacingOptions: () => containerSpacingOptions,
  countriesIso3: () => countriesIso3,
  countryISO2Dict: () => countryISO2Dict,
  customValidationDefinitionTypes: () => customValidationDefinitionTypes,
  datasetQueryEntities: () => datasetQueryEntities,
  defaultTextAlignment: () => defaultTextAlignment,
  dialogDecisionActionTypes: () => dialogDecisionActionTypes,
  documentCanvasBoxSize: () => documentCanvasBoxSize,
  documentDependantPaths: () => documentDependantPaths,
  documentFieldBoxSize: () => documentFieldBoxSize,
  documentFieldConditions: () => documentFieldConditions,
  documentFieldFontSizeLimits: () => documentFieldFontSizeLimits,
  documentFieldMaxHeight: () => documentFieldMaxHeight,
  documentFieldMaxWidth: () => documentFieldMaxWidth,
  documentFieldTypes: () => documentFieldTypes,
  eventTrackingActions: () => eventTrackingActions,
  eventTrackingScopes: () => eventTrackingScopes,
  fallbackScreenTypes: () => fallbackScreenTypes,
  fallbackSectionTypes: () => fallbackSectionTypes,
  featureFieldOperators: () => featureFieldOperators,
  featureFieldType: () => featureFieldType,
  fileTypes: () => fileTypes,
  fontList: () => fontList,
  fontTypes: () => fontTypes,
  frequencyTypes: () => frequencyTypes,
  goodsCategoryList: () => goodsCategoryList,
  goodsProductsList: () => goodsProductsList,
  headingLevelList: () => headingLevelList,
  headingLevels: () => headingLevels,
  iconsNames: () => iconsNames,
  imageFeatureAngles: () => imageFeatureAngles,
  imageFeatureCarParts: () => imageFeatureCarParts,
  imageFeatureColors: () => imageFeatureColors,
  imageFeatureDamages: () => imageFeatureDamages,
  imageFeatureFields: () => imageFeatureFields,
  imageFeatureObjects: () => imageFeatureObjects,
  imageFeatureSeverities: () => imageFeatureSeverities,
  imageFeatureVehicleTypes: () => imageFeatureVehicleTypes,
  inferredEntities: () => inferredEntities,
  inputBehaviour: () => inputBehaviour,
  inputStages: () => inputStages,
  inputTypes: () => inputTypes,
  inputTypesWithOptions: () => inputTypesWithOptions,
  inspectionClientRequestTrackingKey: () => inspectionClientRequestTrackingKey,
  inspectionLimits: () => inspectionLimits,
  inspectionStages: () => inspectionStages,
  inspectionStatus: () => inspectionStatus,
  inspectionTemplateInputHintTypes: () => inspectionTemplateInputHintTypes,
  inspectionTypes: () => inspectionTypes,
  jobStatus: () => jobStatus,
  languagesISO6391: () => languagesISO6391,
  locales: () => locales,
  logicRuleActions: () => logicRuleActions,
  logicRuleBlockTypes: () => logicRuleBlockTypes,
  logicRuleCarDamageSeverities: () => logicRuleCarDamageSeverities,
  logicRuleCarDamages: () => logicRuleCarDamages,
  logicRuleCarParts: () => logicRuleCarParts,
  logicRuleCombinations: () => logicRuleCombinations,
  logicRuleInputReferenceTypes: () => logicRuleInputReferenceTypes,
  logicRuleOperators: () => logicRuleOperators,
  logicRuleUses: () => logicRuleUses,
  machineryPurposes: () => machineryPurposes,
  machineryTypes: () => machineryTypes,
  machineryUses: () => machineryUses,
  mentionTypes: () => mentionTypes,
  messageTemplateVariables: () => messageTemplateVariables,
  messageTemplateVariablesPath: () => messageTemplateVariablesPath,
  notificationEventChannels: () => notificationEventChannels,
  notificationEventSources: () => notificationEventSources,
  notificationEvents: () => notificationEvents,
  operatorsByFieldType: () => operatorsByFieldType,
  overwritablesSectionTypes: () => overwritablesSectionTypes,
  passports: () => passports,
  pdfVariants: () => pdfVariants,
  presignResourceTypes: () => presignResourceTypes,
  progressTrackerVariants: () => progressTrackerVariants,
  revertActionDispatches: () => revertActionDispatches,
  sectionChildrenTypes: () => sectionChildrenTypes,
  sectionReorderConstraint: () => sectionReorderConstraint,
  sectionTypes: () => sectionTypes,
  sexTypes: () => sexTypes,
  signatureModes: () => signatureModes,
  templateConditionLinks: () => templateConditionLinks,
  templateConditionTermOperators: () => templateConditionTermOperators,
  templateConditionTypes: () => templateConditionTypes,
  templateContainerAlignments: () => templateContainerAlignments,
  templateContainerTypes: () => templateContainerTypes,
  templateInputValidationTypes: () => templateInputValidationTypes,
  templateStatus: () => templateStatus,
  templateVariableTypes: () => templateVariableTypes,
  textAlignmentTypes: () => textAlignmentTypes,
  textAlignments: () => textAlignments,
  themeBorderRadiusList: () => themeBorderRadiusList,
  themeColorVariant: () => themeColorVariant,
  timezones: () => timezones,
  timezonesOptions: () => timezonesOptions,
  variableActionReferences: () => variableActionReferences,
  variableNumericDispatches: () => variableNumericDispatches,
  variableTextDispatches: () => variableTextDispatches,
  vehicleUses: () => vehicleUses,
  videoProviders: () => videoProviders,
  webhookEvents: () => webhookEvents,
  widgetTypes: () => widgetTypes
});
module.exports = __toCommonJS(src_exports);

// src/constants/goods.ts
var goodsCategoryList = {
  sports: {
    skies: {
      hasDataset: false
    },
    surf_table: {
      hasDataset: false
    },
    kayak: {
      hasDataset: false
    },
    golf_set: {
      hasDataset: false
    },
    skate_board: {
      hasDataset: false
    },
    other: {
      hasDataset: false
    }
  },
  electronics: {
    handledGameConsole: {
      hasDataset: false
    },
    robotVacuumCleaner: {
      hasDataset: false
    },
    audiovisualEquipment: {
      hasDataset: false
    },
    projector: {
      hasDataset: false
    },
    dvd: {
      hasDataset: false
    },
    cameraAccessories: {
      hasDataset: false
    },
    VRHeadset: {
      hasDataset: false
    },
    desktopComputer: {
      hasDataset: false
    },
    gamingConsole: {
      hasDataset: false
    },
    graphicTablet: {
      hasDataset: false
    },
    mobile: {
      hasDataset: true
    },
    laptop: {
      hasDataset: true
    },
    tablets: {
      hasDataset: false
    },
    monitor: {
      hasDataset: false
    },
    watch_digital: {
      hasDataset: false
    },
    printer_3d: {
      hasDataset: false
    },
    printer: {
      hasDataset: false
    },
    headsets: {
      hasDataset: false
    },
    camera: {
      hasDataset: false
    },
    controllers: {
      hasDataset: false
    },
    drone: {
      hasDataset: false
    },
    other: {
      hasDataset: false
    }
  },
  instruments: {
    musicalInstruments: {
      hasDataset: false
    },
    sax: {
      hasDataset: false
    },
    violin: {
      hasDataset: false
    },
    guitar: {
      hasDataset: false
    },
    drums: {
      hasDataset: false
    },
    keyboard: {
      hasDataset: false
    },
    amplifier: {
      hasDataset: false
    },
    other: {
      hasDataset: false
    }
  },
  jewellery: {
    jewel: {
      hasDataset: false
    },
    watch_luxury: {
      hasDataset: false
    },
    other: {
      hasDataset: false
    }
  },
  art: {
    paint: {
      hasDataset: false
    },
    other: {
      hasDataset: false
    }
  },
  home: {
    refrigerator: {
      hasDataset: false
    },
    microwave: {
      hasDataset: false
    },
    tv: {
      hasDataset: false
    },
    freezer: {
      hasDataset: false
    },
    other: {
      hasDataset: false
    }
  },
  mobility: {
    bike: {
      hasDataset: false
    },
    scuter: {
      hasDataset: false
    },
    other: {
      hasDataset: false
    }
  }
};
function distinctArrayOfObjectsByKey(arr, key) {
  return arr.filter(
    (thing, index, self) => index === self.findIndex((t) => t[key] === thing[key])
  );
}
var goodsProductsList = distinctArrayOfObjectsByKey(
  Object.entries(goodsCategoryList).reduce((acc, [category, products]) => {
    return [
      ...acc,
      ...Object.entries(products).map(
        ([product, { hasDataset }]) => {
          return {
            hasDataset,
            category,
            product
          };
        }
      )
    ];
  }, []),
  "product"
);

// src/constants/section.ts
var sectionTypes = [
  "checkout_summary",
  "welcome",
  "identity_inputs",
  "identity_images",
  "product_images",
  "product_inputs",
  "product_list",
  "damage_declaration",
  "custom",
  "feedback",
  "onboarding",
  "instructions"
];
var fallbackSectionTypes = ["blocked", "expired"];
var overwritablesSectionTypes = [
  "product_inputs",
  "product_images"
];

// src/constants/notificationEvents.ts
var notificationEventSources = [
  "initializer",
  "reminder",
  "manual",
  "fallback"
];
var notificationEventChannels = ["sms", "wsp", "email"];
var notificationEvents = [
  "open",
  "sent",
  "unsent",
  "failed",
  "delivered",
  "read",
  "reattempt_scheduled"
];

// src/constants/webhooks.ts
var MAX_WEBHOOKS_PER_COMPANY = 8;
var webhookEvents = [
  "inspection_started",
  "inspection_blocked",
  "inspection_completed",
  "hi_from_autoinspector",
  "image_processed",
  "inspection_reviewed",
  "extra_image_processed",
  "inspection_created",
  "children_inspection_created"
];

// src/constants/locale.ts
var locales = {
  af: "Afrikaans",
  af_NA: "Afrikaans (Namibia)",
  af_ZA: "Afrikaans (South Africa)",
  ak: "Akan",
  ak_GH: "Akan (Ghana)",
  sq: "Albanian",
  sq_AL: "Albanian (Albania)",
  sq_XK: "Albanian (Kosovo)",
  sq_MK: "Albanian (Macedonia)",
  am: "Amharic",
  am_ET: "Amharic (Ethiopia)",
  ar: "Arabic",
  ar_DZ: "Arabic (Algeria)",
  ar_BH: "Arabic (Bahrain)",
  ar_TD: "Arabic (Chad)",
  ar_KM: "Arabic (Comoros)",
  ar_DJ: "Arabic (Djibouti)",
  ar_EG: "Arabic (Egypt)",
  ar_ER: "Arabic (Eritrea)",
  ar_IQ: "Arabic (Iraq)",
  ar_IL: "Arabic (Israel)",
  ar_JO: "Arabic (Jordan)",
  ar_KW: "Arabic (Kuwait)",
  ar_LB: "Arabic (Lebanon)",
  ar_LY: "Arabic (Libya)",
  ar_MR: "Arabic (Mauritania)",
  ar_MA: "Arabic (Morocco)",
  ar_OM: "Arabic (Oman)",
  ar_PS: "Arabic (Palestinian Territories)",
  ar_QA: "Arabic (Qatar)",
  ar_SA: "Arabic (Saudi Arabia)",
  ar_SO: "Arabic (Somalia)",
  ar_SS: "Arabic (South Sudan)",
  ar_SD: "Arabic (Sudan)",
  ar_SY: "Arabic (Syria)",
  ar_TN: "Arabic (Tunisia)",
  ar_AE: "Arabic (United Arab Emirates)",
  ar_EH: "Arabic (Western Sahara)",
  ar_YE: "Arabic (Yemen)",
  hy: "Armenian",
  hy_AM: "Armenian (Armenia)",
  as: "Assamese",
  as_IN: "Assamese (India)",
  az: "Azerbaijani",
  az_AZ: "Azerbaijani (Azerbaijan)",
  az_Cyrl_AZ: "Azerbaijani (Cyrillic, Azerbaijan)",
  az_Cyrl: "Azerbaijani (Cyrillic)",
  az_Latn_AZ: "Azerbaijani (Latin, Azerbaijan)",
  az_Latn: "Azerbaijani (Latin)",
  bm: "Bambara",
  bm_Latn_ML: "Bambara (Latin, Mali)",
  bm_Latn: "Bambara (Latin)",
  eu: "Basque",
  eu_ES: "Basque (Spain)",
  be: "Belarusian",
  be_BY: "Belarusian (Belarus)",
  bn: "Bengali",
  bn_BD: "Bengali (Bangladesh)",
  bn_IN: "Bengali (India)",
  bs: "Bosnian",
  bs_BA: "Bosnian (Bosnia & Herzegovina)",
  bs_Cyrl_BA: "Bosnian (Cyrillic, Bosnia & Herzegovina)",
  bs_Cyrl: "Bosnian (Cyrillic)",
  bs_Latn_BA: "Bosnian (Latin, Bosnia & Herzegovina)",
  bs_Latn: "Bosnian (Latin)",
  br: "Breton",
  br_FR: "Breton (France)",
  bg: "Bulgarian",
  bg_BG: "Bulgarian (Bulgaria)",
  my: "Burmese",
  my_MM: "Burmese (Myanmar (Burma))",
  ca: "Catalan",
  ca_AD: "Catalan (Andorra)",
  ca_FR: "Catalan (France)",
  ca_IT: "Catalan (Italy)",
  ca_ES: "Catalan (Spain)",
  zh: "Chinese",
  zh_CN: "Chinese (China)",
  zh_HK: "Chinese (Hong Kong SAR China)",
  zh_MO: "Chinese (Macau SAR China)",
  zh_Hans_CN: "Chinese (Simplified, China)",
  zh_Hans_HK: "Chinese (Simplified, Hong Kong SAR China)",
  zh_Hans_MO: "Chinese (Simplified, Macau SAR China)",
  zh_Hans_SG: "Chinese (Simplified, Singapore)",
  zh_Hans: "Chinese (Simplified)",
  zh_SG: "Chinese (Singapore)",
  zh_TW: "Chinese (Taiwan)",
  zh_Hant_HK: "Chinese (Traditional, Hong Kong SAR China)",
  zh_Hant_MO: "Chinese (Traditional, Macau SAR China)",
  zh_Hant_TW: "Chinese (Traditional, Taiwan)",
  zh_Hant: "Chinese (Traditional)",
  kw: "Cornish",
  kw_GB: "Cornish (United Kingdom)",
  hr: "Croatian",
  hr_BA: "Croatian (Bosnia & Herzegovina)",
  hr_HR: "Croatian (Croatia)",
  cs: "Czech",
  cs_CZ: "Czech (Czech Republic)",
  da: "Danish",
  da_DK: "Danish (Denmark)",
  da_GL: "Danish (Greenland)",
  nl: "Dutch",
  nl_AW: "Dutch (Aruba)",
  nl_BE: "Dutch (Belgium)",
  nl_BQ: "Dutch (Caribbean Netherlands)",
  nl_CW: "Dutch (Cura\xE7ao)",
  nl_NL: "Dutch (Netherlands)",
  nl_SX: "Dutch (Sint Maarten)",
  nl_SR: "Dutch (Suriname)",
  dz: "Dzongkha",
  dz_BT: "Dzongkha (Bhutan)",
  en: "English",
  en_AS: "English (American Samoa)",
  en_AI: "English (Anguilla)",
  en_AG: "English (Antigua & Barbuda)",
  en_AU: "English (Australia)",
  en_BS: "English (Bahamas)",
  en_BB: "English (Barbados)",
  en_BE: "English (Belgium)",
  en_BZ: "English (Belize)",
  en_BM: "English (Bermuda)",
  en_BW: "English (Botswana)",
  en_IO: "English (British Indian Ocean Territory)",
  en_VG: "English (British Virgin Islands)",
  en_CM: "English (Cameroon)",
  en_CA: "English (Canada)",
  en_KY: "English (Cayman Islands)",
  en_CX: "English (Christmas Island)",
  en_CC: "English (Cocos (Keeling) Islands)",
  en_CK: "English (Cook Islands)",
  en_DG: "English (Diego Garcia)",
  en_DM: "English (Dominica)",
  en_ER: "English (Eritrea)",
  en_FK: "English (Falkland Islands)",
  en_FJ: "English (Fiji)",
  en_GM: "English (Gambia)",
  en_GH: "English (Ghana)",
  en_GI: "English (Gibraltar)",
  en_GD: "English (Grenada)",
  en_GU: "English (Guam)",
  en_GG: "English (Guernsey)",
  en_GY: "English (Guyana)",
  en_HK: "English (Hong Kong SAR China)",
  en_IN: "English (India)",
  en_IE: "English (Ireland)",
  en_IM: "English (Isle of Man)",
  en_JM: "English (Jamaica)",
  en_JE: "English (Jersey)",
  en_KE: "English (Kenya)",
  en_KI: "English (Kiribati)",
  en_LS: "English (Lesotho)",
  en_LR: "English (Liberia)",
  en_MO: "English (Macau SAR China)",
  en_MG: "English (Madagascar)",
  en_MW: "English (Malawi)",
  en_MY: "English (Malaysia)",
  en_MT: "English (Malta)",
  en_MH: "English (Marshall Islands)",
  en_MU: "English (Mauritius)",
  en_FM: "English (Micronesia)",
  en_MS: "English (Montserrat)",
  en_NA: "English (Namibia)",
  en_NR: "English (Nauru)",
  en_NZ: "English (New Zealand)",
  en_NG: "English (Nigeria)",
  en_NU: "English (Niue)",
  en_NF: "English (Norfolk Island)",
  en_MP: "English (Northern Mariana Islands)",
  en_PK: "English (Pakistan)",
  en_PW: "English (Palau)",
  en_PG: "English (Papua New Guinea)",
  en_PH: "English (Philippines)",
  en_PN: "English (Pitcairn Islands)",
  en_PR: "English (Puerto Rico)",
  en_RW: "English (Rwanda)",
  en_WS: "English (Samoa)",
  en_SC: "English (Seychelles)",
  en_SL: "English (Sierra Leone)",
  en_SG: "English (Singapore)",
  en_SX: "English (Sint Maarten)",
  en_SB: "English (Solomon Islands)",
  en_ZA: "English (South Africa)",
  en_SS: "English (South Sudan)",
  en_SH: "English (St. Helena)",
  en_KN: "English (St. Kitts & Nevis)",
  en_LC: "English (St. Lucia)",
  en_VC: "English (St. Vincent & Grenadines)",
  en_SD: "English (Sudan)",
  en_SZ: "English (Swaziland)",
  en_TZ: "English (Tanzania)",
  en_TK: "English (Tokelau)",
  en_TO: "English (Tonga)",
  en_TT: "English (Trinidad & Tobago)",
  en_TC: "English (Turks & Caicos Islands)",
  en_TV: "English (Tuvalu)",
  en_UM: "English (U.S. Outlying Islands)",
  en_VI: "English (U.S. Virgin Islands)",
  en_UG: "English (Uganda)",
  en_GB: "English (United Kingdom)",
  en_US: "English (United States)",
  en_VU: "English (Vanuatu)",
  en_ZM: "English (Zambia)",
  en_ZW: "English (Zimbabwe)",
  eo: "Esperanto",
  et: "Estonian",
  et_EE: "Estonian (Estonia)",
  ee: "Ewe",
  ee_GH: "Ewe (Ghana)",
  ee_TG: "Ewe (Togo)",
  fo: "Faroese",
  fo_FO: "Faroese (Faroe Islands)",
  fi: "Finnish",
  fi_FI: "Finnish (Finland)",
  fr: "French",
  fr_DZ: "French (Algeria)",
  fr_BE: "French (Belgium)",
  fr_BJ: "French (Benin)",
  fr_BF: "French (Burkina Faso)",
  fr_BI: "French (Burundi)",
  fr_CM: "French (Cameroon)",
  fr_CA: "French (Canada)",
  fr_CF: "French (Central African Republic)",
  fr_TD: "French (Chad)",
  fr_KM: "French (Comoros)",
  fr_CG: "French (Congo - Brazzaville)",
  fr_CD: "French (Congo - Kinshasa)",
  fr_CI: "French (C\xF4te d\u2019Ivoire)",
  fr_DJ: "French (Djibouti)",
  fr_GQ: "French (Equatorial Guinea)",
  fr_FR: "French (France)",
  fr_GF: "French (French Guiana)",
  fr_PF: "French (French Polynesia)",
  fr_GA: "French (Gabon)",
  fr_GP: "French (Guadeloupe)",
  fr_GN: "French (Guinea)",
  fr_HT: "French (Haiti)",
  fr_LU: "French (Luxembourg)",
  fr_MG: "French (Madagascar)",
  fr_ML: "French (Mali)",
  fr_MQ: "French (Martinique)",
  fr_MR: "French (Mauritania)",
  fr_MU: "French (Mauritius)",
  fr_YT: "French (Mayotte)",
  fr_MC: "French (Monaco)",
  fr_MA: "French (Morocco)",
  fr_NC: "French (New Caledonia)",
  fr_NE: "French (Niger)",
  fr_RE: "French (R\xE9union)",
  fr_RW: "French (Rwanda)",
  fr_SN: "French (Senegal)",
  fr_SC: "French (Seychelles)",
  fr_BL: "French (St. Barth\xE9lemy)",
  fr_MF: "French (St. Martin)",
  fr_PM: "French (St. Pierre & Miquelon)",
  fr_CH: "French (Switzerland)",
  fr_SY: "French (Syria)",
  fr_TG: "French (Togo)",
  fr_TN: "French (Tunisia)",
  fr_VU: "French (Vanuatu)",
  fr_WF: "French (Wallis & Futuna)",
  ff: "Fulah",
  ff_CM: "Fulah (Cameroon)",
  ff_GN: "Fulah (Guinea)",
  ff_MR: "Fulah (Mauritania)",
  ff_SN: "Fulah (Senegal)",
  gl: "Galician",
  gl_ES: "Galician (Spain)",
  lg: "Ganda",
  lg_UG: "Ganda (Uganda)",
  ka: "Georgian",
  ka_GE: "Georgian (Georgia)",
  de: "German",
  de_AT: "German (Austria)",
  de_BE: "German (Belgium)",
  de_DE: "German (Germany)",
  de_LI: "German (Liechtenstein)",
  de_LU: "German (Luxembourg)",
  de_CH: "German (Switzerland)",
  el: "Greek",
  el_CY: "Greek (Cyprus)",
  el_GR: "Greek (Greece)",
  gu: "Gujarati",
  gu_IN: "Gujarati (India)",
  ha: "Hausa",
  ha_GH: "Hausa (Ghana)",
  ha_Latn_GH: "Hausa (Latin, Ghana)",
  ha_Latn_NE: "Hausa (Latin, Niger)",
  ha_Latn_NG: "Hausa (Latin, Nigeria)",
  ha_Latn: "Hausa (Latin)",
  ha_NE: "Hausa (Niger)",
  ha_NG: "Hausa (Nigeria)",
  he: "Hebrew",
  he_IL: "Hebrew (Israel)",
  hi: "Hindi",
  hi_IN: "Hindi (India)",
  hu: "Hungarian",
  hu_HU: "Hungarian (Hungary)",
  is: "Icelandic",
  is_IS: "Icelandic (Iceland)",
  ig: "Igbo",
  ig_NG: "Igbo (Nigeria)",
  id: "Indonesian",
  id_ID: "Indonesian (Indonesia)",
  ga: "Irish",
  ga_IE: "Irish (Ireland)",
  it: "Italian",
  it_IT: "Italian (Italy)",
  it_SM: "Italian (San Marino)",
  it_CH: "Italian (Switzerland)",
  ja: "Japanese",
  ja_JP: "Japanese (Japan)",
  kl: "Kalaallisut",
  kl_GL: "Kalaallisut (Greenland)",
  kn: "Kannada",
  kn_IN: "Kannada (India)",
  ks: "Kashmiri",
  ks_Arab_IN: "Kashmiri (Arabic, India)",
  ks_Arab: "Kashmiri (Arabic)",
  ks_IN: "Kashmiri (India)",
  kk: "Kazakh",
  kk_Cyrl_KZ: "Kazakh (Cyrillic, Kazakhstan)",
  kk_Cyrl: "Kazakh (Cyrillic)",
  kk_KZ: "Kazakh (Kazakhstan)",
  km: "Khmer",
  km_KH: "Khmer (Cambodia)",
  ki: "Kikuyu",
  ki_KE: "Kikuyu (Kenya)",
  rw: "Kinyarwanda",
  rw_RW: "Kinyarwanda (Rwanda)",
  ko: "Korean",
  ko_KP: "Korean (North Korea)",
  ko_KR: "Korean (South Korea)",
  ky: "Kyrgyz",
  ky_Cyrl_KG: "Kyrgyz (Cyrillic, Kyrgyzstan)",
  ky_Cyrl: "Kyrgyz (Cyrillic)",
  ky_KG: "Kyrgyz (Kyrgyzstan)",
  lo: "Lao",
  lo_LA: "Lao (Laos)",
  lv: "Latvian",
  lv_LV: "Latvian (Latvia)",
  ln: "Lingala",
  ln_AO: "Lingala (Angola)",
  ln_CF: "Lingala (Central African Republic)",
  ln_CG: "Lingala (Congo - Brazzaville)",
  ln_CD: "Lingala (Congo - Kinshasa)",
  lt: "Lithuanian",
  lt_LT: "Lithuanian (Lithuania)",
  lu: "Luba-Katanga",
  lu_CD: "Luba-Katanga (Congo - Kinshasa)",
  lb: "Luxembourgish",
  lb_LU: "Luxembourgish (Luxembourg)",
  mk: "Macedonian",
  mk_MK: "Macedonian (Macedonia)",
  mg: "Malagasy",
  mg_MG: "Malagasy (Madagascar)",
  ms: "Malay",
  ms_BN: "Malay (Brunei)",
  ms_Latn_BN: "Malay (Latin, Brunei)",
  ms_Latn_MY: "Malay (Latin, Malaysia)",
  ms_Latn_SG: "Malay (Latin, Singapore)",
  ms_Latn: "Malay (Latin)",
  ms_MY: "Malay (Malaysia)",
  ms_SG: "Malay (Singapore)",
  ml: "Malayalam",
  ml_IN: "Malayalam (India)",
  mt: "Maltese",
  mt_MT: "Maltese (Malta)",
  gv: "Manx",
  gv_IM: "Manx (Isle of Man)",
  mr: "Marathi",
  mr_IN: "Marathi (India)",
  mn: "Mongolian",
  mn_Cyrl_MN: "Mongolian (Cyrillic, Mongolia)",
  mn_Cyrl: "Mongolian (Cyrillic)",
  mn_MN: "Mongolian (Mongolia)",
  ne: "Nepali",
  ne_IN: "Nepali (India)",
  ne_NP: "Nepali (Nepal)",
  nd: "North Ndebele",
  nd_ZW: "North Ndebele (Zimbabwe)",
  se: "Northern Sami",
  se_FI: "Northern Sami (Finland)",
  se_NO: "Northern Sami (Norway)",
  se_SE: "Northern Sami (Sweden)",
  no: "Norwegian",
  no_NO: "Norwegian (Norway)",
  nb: "Norwegian Bokm\xE5l",
  nb_NO: "Norwegian Bokm\xE5l (Norway)",
  nb_SJ: "Norwegian Bokm\xE5l (Svalbard & Jan Mayen)",
  nn: "Norwegian Nynorsk",
  nn_NO: "Norwegian Nynorsk (Norway)",
  or: "Oriya",
  or_IN: "Oriya (India)",
  om: "Oromo",
  om_ET: "Oromo (Ethiopia)",
  om_KE: "Oromo (Kenya)",
  os: "Ossetic",
  os_GE: "Ossetic (Georgia)",
  os_RU: "Ossetic (Russia)",
  ps: "Pashto",
  ps_AF: "Pashto (Afghanistan)",
  fa: "Persian",
  fa_AF: "Persian (Afghanistan)",
  fa_IR: "Persian (Iran)",
  pl: "Polish",
  pl_PL: "Polish (Poland)",
  pt: "Portuguese",
  pt_AO: "Portuguese (Angola)",
  pt_BR: "Portuguese (Brazil)",
  pt_CV: "Portuguese (Cape Verde)",
  pt_GW: "Portuguese (Guinea-Bissau)",
  pt_MO: "Portuguese (Macau SAR China)",
  pt_MZ: "Portuguese (Mozambique)",
  pt_PT: "Portuguese (Portugal)",
  pt_ST: "Portuguese (S\xE3o Tom\xE9 & Pr\xEDncipe)",
  pt_TL: "Portuguese (Timor-Leste)",
  pa: "Punjabi",
  pa_Arab_PK: "Punjabi (Arabic, Pakistan)",
  pa_Arab: "Punjabi (Arabic)",
  pa_Guru_IN: "Punjabi (Gurmukhi, India)",
  pa_Guru: "Punjabi (Gurmukhi)",
  pa_IN: "Punjabi (India)",
  pa_PK: "Punjabi (Pakistan)",
  qu: "Quechua",
  qu_BO: "Quechua (Bolivia)",
  qu_EC: "Quechua (Ecuador)",
  qu_PE: "Quechua (Peru)",
  ro: "Romanian",
  ro_MD: "Romanian (Moldova)",
  ro_RO: "Romanian (Romania)",
  rm: "Romansh",
  rm_CH: "Romansh (Switzerland)",
  rn: "Rundi",
  rn_BI: "Rundi (Burundi)",
  ru: "Russian",
  ru_BY: "Russian (Belarus)",
  ru_KZ: "Russian (Kazakhstan)",
  ru_KG: "Russian (Kyrgyzstan)",
  ru_MD: "Russian (Moldova)",
  ru_RU: "Russian (Russia)",
  ru_UA: "Russian (Ukraine)",
  sg: "Sango",
  sg_CF: "Sango (Central African Republic)",
  gd: "Scottish Gaelic",
  gd_GB: "Scottish Gaelic (United Kingdom)",
  sr: "Serbian",
  sr_BA: "Serbian (Bosnia & Herzegovina)",
  sr_Cyrl_BA: "Serbian (Cyrillic, Bosnia & Herzegovina)",
  sr_Cyrl_XK: "Serbian (Cyrillic, Kosovo)",
  sr_Cyrl_ME: "Serbian (Cyrillic, Montenegro)",
  sr_Cyrl_RS: "Serbian (Cyrillic, Serbia)",
  sr_Cyrl: "Serbian (Cyrillic)",
  sr_XK: "Serbian (Kosovo)",
  sr_Latn_BA: "Serbian (Latin, Bosnia & Herzegovina)",
  sr_Latn_XK: "Serbian (Latin, Kosovo)",
  sr_Latn_ME: "Serbian (Latin, Montenegro)",
  sr_Latn_RS: "Serbian (Latin, Serbia)",
  sr_Latn: "Serbian (Latin)",
  sr_ME: "Serbian (Montenegro)",
  sr_RS: "Serbian (Serbia)",
  sh: "Serbo-Croatian",
  sh_BA: "Serbo-Croatian (Bosnia & Herzegovina)",
  sn: "Shona",
  sn_ZW: "Shona (Zimbabwe)",
  ii: "Sichuan Yi",
  ii_CN: "Sichuan Yi (China)",
  si: "Sinhala",
  si_LK: "Sinhala (Sri Lanka)",
  sk: "Slovak",
  sk_SK: "Slovak (Slovakia)",
  sl: "Slovenian",
  sl_SI: "Slovenian (Slovenia)",
  so: "Somali",
  so_DJ: "Somali (Djibouti)",
  so_ET: "Somali (Ethiopia)",
  so_KE: "Somali (Kenya)",
  so_SO: "Somali (Somalia)",
  es: "Spanish",
  es_AR: "Spanish (Argentina)",
  es_BO: "Spanish (Bolivia)",
  es_IC: "Spanish (Canary Islands)",
  es_EA: "Spanish (Ceuta & Melilla)",
  es_CL: "Spanish (Chile)",
  es_CO: "Spanish (Colombia)",
  es_CR: "Spanish (Costa Rica)",
  es_CU: "Spanish (Cuba)",
  es_DO: "Spanish (Dominican Republic)",
  es_EC: "Spanish (Ecuador)",
  es_SV: "Spanish (El Salvador)",
  es_GQ: "Spanish (Equatorial Guinea)",
  es_GT: "Spanish (Guatemala)",
  es_HN: "Spanish (Honduras)",
  es_MX: "Spanish (Mexico)",
  es_NI: "Spanish (Nicaragua)",
  es_PA: "Spanish (Panama)",
  es_PY: "Spanish (Paraguay)",
  es_PE: "Spanish (Peru)",
  es_PH: "Spanish (Philippines)",
  es_PR: "Spanish (Puerto Rico)",
  es_ES: "Spanish (Spain)",
  es_US: "Spanish (United States)",
  es_UY: "Spanish (Uruguay)",
  es_VE: "Spanish (Venezuela)",
  sw: "Swahili",
  sw_KE: "Swahili (Kenya)",
  sw_TZ: "Swahili (Tanzania)",
  sw_UG: "Swahili (Uganda)",
  sv: "Swedish",
  sv_AX: "Swedish (\xC5land Islands)",
  sv_FI: "Swedish (Finland)",
  sv_SE: "Swedish (Sweden)",
  tl: "Tagalog",
  tl_PH: "Tagalog (Philippines)",
  ta: "Tamil",
  ta_IN: "Tamil (India)",
  ta_MY: "Tamil (Malaysia)",
  ta_SG: "Tamil (Singapore)",
  ta_LK: "Tamil (Sri Lanka)",
  te: "Telugu",
  te_IN: "Telugu (India)",
  th: "Thai",
  th_TH: "Thai (Thailand)",
  bo: "Tibetan",
  bo_CN: "Tibetan (China)",
  bo_IN: "Tibetan (India)",
  ti: "Tigrinya",
  ti_ER: "Tigrinya (Eritrea)",
  ti_ET: "Tigrinya (Ethiopia)",
  to: "Tongan",
  to_TO: "Tongan (Tonga)",
  tr: "Turkish",
  tr_CY: "Turkish (Cyprus)",
  tr_TR: "Turkish (Turkey)",
  uk: "Ukrainian",
  uk_UA: "Ukrainian (Ukraine)",
  ur: "Urdu",
  ur_IN: "Urdu (India)",
  ur_PK: "Urdu (Pakistan)",
  ug: "Uyghur",
  ug_Arab_CN: "Uyghur (Arabic, China)",
  ug_Arab: "Uyghur (Arabic)",
  ug_CN: "Uyghur (China)",
  uz: "Uzbek",
  uz_AF: "Uzbek (Afghanistan)",
  uz_Arab_AF: "Uzbek (Arabic, Afghanistan)",
  uz_Arab: "Uzbek (Arabic)",
  uz_Cyrl_UZ: "Uzbek (Cyrillic, Uzbekistan)",
  uz_Cyrl: "Uzbek (Cyrillic)",
  uz_Latn_UZ: "Uzbek (Latin, Uzbekistan)",
  uz_Latn: "Uzbek (Latin)",
  uz_UZ: "Uzbek (Uzbekistan)",
  vi: "Vietnamese",
  vi_VN: "Vietnamese (Vietnam)",
  cy: "Welsh",
  cy_GB: "Welsh (United Kingdom)",
  fy: "Western Frisian",
  fy_NL: "Western Frisian (Netherlands)",
  yi: "Yiddish",
  yo: "Yoruba",
  yo_BJ: "Yoruba (Benin)",
  yo_NG: "Yoruba (Nigeria)",
  zu: "Zulu",
  zu_ZA: "Zulu (South Africa)"
};

// src/constants/template.ts
var buildMessageTemplateNestedPaths = (data) => {
  const result = [];
  for (const item of data) {
    if (item.paths) {
      const paths = buildMessageTemplateNestedPaths(item.paths);
      for (const path of paths) {
        result.push(`${item.group}.${path}`);
      }
    } else {
      result.push(item.path);
    }
  }
  return result;
};
var inputBehaviour = ["static", "dynamic"];
var templateVariableTypes = ["text", "numeric"];
var messageTemplateVariables = [
  {
    group: "inspection",
    paths: [
      {
        path: "type"
      },
      {
        group: "consumer",
        paths: [
          {
            path: "firstName"
          },
          {
            path: "lastName"
          },
          {
            path: "identification"
          },
          {
            path: "email"
          },
          {
            path: "country"
          },
          {
            path: "state"
          },
          {
            path: "city"
          },
          {
            path: "address"
          }
        ]
      },
      {
        group: "template",
        paths: [
          {
            path: "name"
          }
        ]
      },
      {
        group: "rules",
        paths: [
          {
            path: "deadlineDays"
          }
        ]
      }
    ]
  }
];
var messageTemplateVariablesPath = buildMessageTemplateNestedPaths(
  messageTemplateVariables
);
var documentDependantPaths = ["firstName", "email"];
var assetTypes = [
  "video",
  "input",
  "image",
  "slider",
  "article",
  "filter",
  "termsAndConditions",
  "document",
  "illustration",
  "text",
  "button",
  "progressTracker",
  "certificate",
  "damageDeclaration",
  "pdf",
  "productsSummary",
  "touchScreenTest"
];
var widgetTypes = ["touchScreenTest"];
var sectionReorderConstraint = ["BEFORE", "AFTER"];
var fontList = [
  {
    label: "Space Grotesk",
    value: "space_grotesk"
  },
  {
    label: "Roboto",
    value: "roboto"
  },
  {
    label: "Inter",
    value: "inter"
  },
  {
    label: "Roboto Mono",
    value: "roboto_mono"
  },
  {
    label: "Space Mono",
    value: "space_mono"
  },
  {
    label: "Playfair Display",
    value: "playfair_display"
  },
  {
    label: "Prata",
    value: "prata"
  },
  {
    label: "Glegoo",
    value: "glegoo"
  },
  {
    label: "Roboto Flex",
    value: "roboto_flex"
  },
  {
    label: "Roboto Condensed",
    value: "roboto_condensed"
  },
  {
    label: "Roboto Slab",
    value: "roboto_slab"
  },
  {
    label: "IBM Plex Sans Condensed",
    value: "ibm_plex_sans_condensed"
  },
  {
    label: "Ubuntu Condensed",
    value: "ubuntu_condensed"
  },
  {
    label: "Barlow Condensed",
    value: "barlow_condensed"
  },
  {
    label: "Saira Condensed",
    value: "saira_condensed"
  }
];
var fontTypes = fontList.map((ele) => ele.value);
var fallbackScreenTypes = ["blocked", "expired"];
var themeColorVariant = ["light", "dark"];
var templateContainerTypes = ["header", "body", "footer"];
var templateContainerAlignments = ["start", "center", "end"];
var containerSpacingOptions = [2, 4, 6, 8, 10];
var progressTrackerVariants = ["lines", "points"];
var MAX_PROGRESS_TRACKER_STEPS = 4;
var videoProviders = ["youtube", "local"];
var sectionChildrenTypes = ["group", "section"];
var dialogDecisionActionTypes = ["positive", "negative"];
var mentionTypes = ["path", "variable", "input", "function"];
var templateStatus = ["draft", "published"];
var pdfVariants = ["pagination", "scroll"];
var frequencyTypes = ["days"];

// src/constants/theme.ts
var themeBorderRadiusList = [10, 5, 0];

// src/constants/canvas.ts
var canvasSlots = [
  "footer",
  "top-left",
  "bottom",
  "pre-bottom"
];
var alignments = ["start", "center", "end"];
var headingLevels = {
  1: "text-3xl",
  2: "text-2xl",
  3: "text-lg",
  4: "text-md"
};
var headingLevelList = [
  8,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  20,
  24,
  32,
  36,
  40,
  48,
  64,
  96,
  128
];
var defaultTextAlignment = "left";
var textAlignments = [
  {
    label: "Izquierda",
    value: "left"
  },
  {
    label: "Centro",
    value: "center"
  },
  {
    label: "Derecha",
    value: "right"
  },
  {
    label: "Justificado",
    value: "justify"
  }
];
var textAlignmentTypes = textAlignments.map((ele) => ele.value);
var buttonVariants = ["primary", "secondary"];
var buttonActions = [
  "continue",
  "recreate",
  "request_unlock",
  "help",
  "declare_damages",
  "commit_product_inspections",
  "show_committed_product_inspections",
  "jump_to_parent_section",
  "jump_to_target_section"
];
var buttonShowWhenConditions = ["can_submit_form"];

// src/constants/logic-rule.ts
var logicRuleCombinations = ["and", "or"];
var logicRuleUses = ["inspection_approval"];
var logicRuleBlockTypes = ["damage", "image", "input"];
var logicRuleOperators = ["===", "!==", ">", "<", ">=", "<="];
var logicRuleCarDamages = [
  "light_broken",
  "glass_broken",
  "corrosion",
  "dent",
  "scratch",
  "missing",
  "tire_flat",
  "crack",
  "dislocation"
];
var logicRuleCarParts = [
  "back_bumper",
  "back_glass",
  "back_left_door",
  "back_left_light",
  "back_right_door",
  "back_right_light",
  "front_bumper",
  "front_glass",
  "front_left_door",
  "front_left_light",
  "front_right_door",
  "front_right_light",
  "hood",
  "left_mirror",
  "right_mirror",
  "tailgate",
  "trunk",
  "wheel",
  "front_right_fender",
  "front_left_fender",
  "back_left_fender",
  "back_right_fender",
  "roof"
];
var logicRuleInputReferenceTypes = ["identifier", "value"];
var logicRuleCarDamageSeverities = [
  "minor",
  "moderate",
  "severe",
  "unknown"
];
var logicRuleActions = ["approve", "disapprove", "nothing"];
var EMPTY_RULE_ACTION = "nothing";

// src/constants/computer-vision.ts
var imageFeatureColors = [
  "yellow",
  "blue",
  "white",
  "gold",
  "grey",
  "orange",
  "black",
  "red",
  "green",
  "gray",
  "brown"
];
var imageFeatureAngles = [
  "back",
  "backSideLeft",
  "sideRight",
  "front",
  "frontSideRight",
  "frontSideLeft",
  "sideLeft"
];
var imageFeatureObjects = ["vehicle", "unknown"];
var imageFeatureDamages = [
  "light_broken",
  "glass_broken",
  "corrosion",
  "dent",
  "scratch",
  "missing_part",
  "tire_flat",
  "crack",
  "dislocation"
];
var imageFeatureCarParts = [
  "back_bumper",
  "back_glass",
  "back_left_door",
  "back_left_light",
  "back_right_door",
  "back_right_light",
  "front_bumper",
  "front_glass",
  "front_left_door",
  "front_left_light",
  "front_right_door",
  "front_right_light",
  "hood",
  "left_mirror",
  "right_mirror",
  "tailgate",
  "trunk",
  "wheel",
  "front_right_fender",
  "front_left_fender",
  "back_left_fender",
  "back_right_fender",
  "roof"
];
var imageFeatureVehicleTypes = [
  "moto",
  "sedan",
  "rural",
  "coupe",
  "pick_up",
  "utility",
  "truck",
  "bus",
  "minibus"
];
var imageFeatureSeverities = ["minor", "moderate", "severe"];
var imageFeatureFields = [
  "reviewed",
  "features.probability_of_internal_damage",
  "features.color",
  "features.recaptured",
  "features.angle",
  "features.license_plate_country",
  "features.complete",
  "features.insurable",
  "features.damages.part",
  "features.damages.damage",
  "features.damages.severity",
  "features.damages.confidence",
  "features.object",
  "features.vehicle_type",
  "features.parts",
  "features.moire_pattern",
  "features.blur_score",
  "features.luma_score",
  "features.zoom"
];
var featureFieldType = ["boolean", "enum", "number"];
var operatorsByFieldType = {
  boolean: ["equal", "not_equal"],
  enum: ["equal", "not_equal"],
  number: [
    "equal",
    "not_equal",
    "greater_than",
    "less_than",
    "less_than_or_equal",
    "greater_than_or_equal"
  ]
};
var featureFieldOperators = Object.values(operatorsByFieldType).flat();

// src/constants/report-request.ts
var REPORT_REQUEST_AUTHENTICATION_HEADER = "x-report-request-token";

// src/constants/asset.ts
var assetTargets = ["product", "identity", "custom"];
var inputTypes = [
  "video",
  "audio",
  "text",
  "date",
  "textarea",
  "select",
  "numeric",
  "check",
  "file",
  "multiselect",
  "dialogDecision",
  "signature",
  "checkboxGroup"
];

// src/constants/icons.ts
var iconsNames = [
  "IconZoomQuestion",
  "IconZoomReplace",
  "IconZoomReset",
  "IconZzzOff",
  "IconZzz",
  "IconWorldDownload",
  "IconWorldLatitude",
  "IconWorldLongitude",
  "IconWorldOff",
  "IconWorldUpload",
  "IconWorldWww",
  "IconWorld",
  "IconWreckingBall",
  "IconWritingOff",
  "IconWritingSignOff",
  "IconWritingSign",
  "IconWriting",
  "IconX",
  "IconXboxA",
  "IconXboxB",
  "IconXboxX",
  "IconXboxY",
  "IconYinYangFilled",
  "IconYinYang",
  "IconYoga",
  "IconZeppelinOff",
  "IconZeppelin",
  "IconZip",
  "IconZodiacAquarius",
  "IconZodiacAries",
  "IconZodiacCancer",
  "IconZodiacCapricorn",
  "IconZodiacGemini",
  "IconZodiacLeo",
  "IconZodiacLibra",
  "IconZodiacPisces",
  "IconZodiacSagittarius",
  "IconZodiacScorpio",
  "IconZodiacTaurus",
  "IconZodiacVirgo",
  "IconZoomCancel",
  "IconZoomCheckFilled",
  "IconZoomCheck",
  "IconZoomCode",
  "IconZoomExclamation",
  "IconZoomFilled",
  "IconZoomInAreaFilled",
  "IconZoomInArea",
  "IconZoomInFilled",
  "IconZoomIn",
  "IconZoomMoney",
  "IconZoomOutArea",
  "IconZoomOutFilled",
  "IconZoomOut",
  "IconZoomPan",
  "IconWashDryF",
  "IconWashDryHang",
  "IconWashDryOff",
  "IconWashDryP",
  "IconWashDryShade",
  "IconWashDryW",
  "IconWashDry",
  "IconWashDrycleanOff",
  "IconWashDryclean",
  "IconWashGentle",
  "IconWashMachine",
  "IconWashOff",
  "IconWashPress",
  "IconWashTemperature1",
  "IconWashTemperature2",
  "IconWashTemperature3",
  "IconWashTemperature4",
  "IconWashTemperature5",
  "IconWashTemperature6",
  "IconWashTumbleDry",
  "IconWashTumbleOff",
  "IconWash",
  "IconWaveSawTool",
  "IconWaveSine",
  "IconWaveSquare",
  "IconWebhookOff",
  "IconWebhook",
  "IconWeight",
  "IconWheelchairOff",
  "IconWheelchair",
  "IconWhirl",
  "IconWifi0",
  "IconWifi1",
  "IconWifi2",
  "IconWifiOff",
  "IconWifi",
  "IconWindOff",
  "IconWind",
  "IconWindmillFilled",
  "IconWindmillOff",
  "IconWindmill",
  "IconWindowMaximize",
  "IconWindowMinimize",
  "IconWindowOff",
  "IconWindow",
  "IconWindsock",
  "IconWiperWash",
  "IconWiper",
  "IconWoman",
  "IconWood",
  "IconVectorBezier2",
  "IconVectorBezierArc",
  "IconVectorBezierCircle",
  "IconVectorBezier",
  "IconVectorOff",
  "IconVectorSpline",
  "IconVectorTriangleOff",
  "IconVectorTriangle",
  "IconVector",
  "IconVenus",
  "IconVersionsFilled",
  "IconVersionsOff",
  "IconVersions",
  "IconVideoMinus",
  "IconVideoOff",
  "IconVideoPlus",
  "IconVideo",
  "IconView360Off",
  "IconView360",
  "IconViewfinderOff",
  "IconViewfinder",
  "IconViewportNarrow",
  "IconViewportWide",
  "IconVinyl",
  "IconVipOff",
  "IconVip",
  "IconVirusOff",
  "IconVirusSearch",
  "IconVirus",
  "IconVocabularyOff",
  "IconVocabulary",
  "IconVolcano",
  "IconVolume2",
  "IconVolume3",
  "IconVolumeOff",
  "IconVolume",
  "IconWalk",
  "IconWallOff",
  "IconWall",
  "IconWalletOff",
  "IconWallet",
  "IconWallpaperOff",
  "IconWallpaper",
  "IconWandOff",
  "IconWand",
  "IconWashDry1",
  "IconWashDry2",
  "IconWashDry3",
  "IconWashDryA",
  "IconWashDryDip",
  "IconTriangleInverted",
  "IconTriangleOff",
  "IconTriangleSquareCircle",
  "IconTriangle",
  "IconTriangles",
  "IconTrident",
  "IconTrolley",
  "IconTrophyFilled",
  "IconTrophyOff",
  "IconTrophy",
  "IconTrowel",
  "IconTruckDelivery",
  "IconTruckLoading",
  "IconTruckOff",
  "IconTruckReturn",
  "IconTruck",
  "IconTxt",
  "IconTypographyOff",
  "IconTypography",
  "IconUfoOff",
  "IconUfo",
  "IconUmbrellaFilled",
  "IconUmbrellaOff",
  "IconUmbrella",
  "IconUnderline",
  "IconUnlink",
  "IconUpload",
  "IconUrgent",
  "IconUsb",
  "IconUserCheck",
  "IconUserCircle",
  "IconUserExclamation",
  "IconUserMinus",
  "IconUserOff",
  "IconUserPlus",
  "IconUserSearch",
  "IconUserX",
  "IconUser",
  "IconUsers",
  "IconUvIndex",
  "IconUxCircle",
  "IconVaccineBottleOff",
  "IconVaccineBottle",
  "IconVaccineOff",
  "IconVaccine",
  "IconVacuumCleaner",
  "IconVariableMinus",
  "IconVariableOff",
  "IconVariablePlus",
  "IconVariable",
  "IconTopologyBus",
  "IconTopologyComplex",
  "IconTopologyFullHierarchy",
  "IconTopologyFull",
  "IconTopologyRing2",
  "IconTopologyRing3",
  "IconTopologyRing",
  "IconTopologyStar2",
  "IconTopologyStar3",
  "IconTopologyStarRing2",
  "IconTopologyStarRing3",
  "IconTopologyStarRing",
  "IconTopologyStar",
  "IconTorii",
  "IconTornado",
  "IconTournament",
  "IconTowerOff",
  "IconTower",
  "IconTrack",
  "IconTractor",
  "IconTrademark",
  "IconTrafficConeOff",
  "IconTrafficCone",
  "IconTrafficLightsOff",
  "IconTrafficLights",
  "IconTrain",
  "IconTransferIn",
  "IconTransferOut",
  "IconTransformFilled",
  "IconTransform",
  "IconTransitionBottom",
  "IconTransitionLeft",
  "IconTransitionRight",
  "IconTransitionTop",
  "IconTrashFilled",
  "IconTrashOff",
  "IconTrashXFilled",
  "IconTrashX",
  "IconTrash",
  "IconTree",
  "IconTrees",
  "IconTrekking",
  "IconTrendingDown2",
  "IconTrendingDown3",
  "IconTrendingDown",
  "IconTrendingUp2",
  "IconTrendingUp3",
  "IconTrendingUp",
  "IconTriangleFilled",
  "IconTriangleInvertedFilled",
  "IconTextCaption",
  "IconTextColor",
  "IconTextDecrease",
  "IconTextDirectionLtr",
  "IconTextDirectionRtl",
  "IconTextIncrease",
  "IconTextOrientation",
  "IconTextPlus",
  "IconTextRecognition",
  "IconTextResize",
  "IconTextSize",
  "IconTextSpellcheck",
  "IconTextWrapDisabled",
  "IconTextWrap",
  "IconTexture",
  "IconTheater",
  "IconThermometer",
  "IconThumbDownFilled",
  "IconThumbDownOff",
  "IconThumbDown",
  "IconThumbUpFilled",
  "IconThumbUpOff",
  "IconThumbUp",
  "IconTicTac",
  "IconTicketOff",
  "IconTicket",
  "IconTie",
  "IconTilde",
  "IconTiltShiftOff",
  "IconTiltShift",
  "IconTimelineEventExclamation",
  "IconTimelineEventMinus",
  "IconTimelineEventPlus",
  "IconTimelineEventText",
  "IconTimelineEventX",
  "IconTimelineEvent",
  "IconTimeline",
  "IconTir",
  "IconToggleLeft",
  "IconToggleRight",
  "IconToiletPaperOff",
  "IconToiletPaper",
  "IconTool",
  "IconToolsKitchen2Off",
  "IconToolsKitchen2",
  "IconToolsKitchenOff",
  "IconToolsKitchen",
  "IconToolsOff",
  "IconTools",
  "IconTooltip",
  "IconSwimming",
  "IconSwipe",
  "IconSwitch2",
  "IconSwitch3",
  "IconSwitchHorizontal",
  "IconSwitchVertical",
  "IconSwitch",
  "IconSwordOff",
  "IconSword",
  "IconSwords",
  "IconTableAlias",
  "IconTableExport",
  "IconTableFilled",
  "IconTableImport",
  "IconTableOff",
  "IconTableOptions",
  "IconTableShortcut",
  "IconTable",
  "IconTagOff",
  "IconTag",
  "IconTagsOff",
  "IconTags",
  "IconTallymark1",
  "IconTallymark2",
  "IconTallymark3",
  "IconTallymark4",
  "IconTallymarks",
  "IconTank",
  "IconTargetArrow",
  "IconTargetOff",
  "IconTarget",
  "IconTeapot",
  "IconTelescopeOff",
  "IconTelescope",
  "IconTemperatureCelsius",
  "IconTemperatureFahrenheit",
  "IconTemperatureMinus",
  "IconTemperatureOff",
  "IconTemperaturePlus",
  "IconTemperature",
  "IconTemplateOff",
  "IconTemplate",
  "IconTentOff",
  "IconTent",
  "IconTerminal2",
  "IconTerminal",
  "IconTestPipe2",
  "IconTestPipeOff",
  "IconTestPipe",
  "IconTex",
  "IconSquaresDiagonal",
  "IconSquaresFilled",
  "IconStack2",
  "IconStack3",
  "IconStackPop",
  "IconStackPush",
  "IconStack",
  "IconStairsDown",
  "IconStairsUp",
  "IconStairs",
  "IconStarFilled",
  "IconStarHalfFilled",
  "IconStarHalf",
  "IconStarOff",
  "IconStar",
  "IconStarsFilled",
  "IconStarsOff",
  "IconStars",
  "IconStatusChange",
  "IconSteam",
  "IconSteeringWheelOff",
  "IconSteeringWheel",
  "IconStepInto",
  "IconStepOut",
  "IconStereoGlasses",
  "IconStethoscopeOff",
  "IconStethoscope",
  "IconSticker",
  "IconStormOff",
  "IconStorm",
  "IconStretching",
  "IconStrikethrough",
  "IconSubmarine",
  "IconSubscript",
  "IconSubtask",
  "IconSumOff",
  "IconSum",
  "IconSunFilled",
  "IconSunHigh",
  "IconSunLow",
  "IconSunMoon",
  "IconSunOff",
  "IconSunWind",
  "IconSun",
  "IconSunglasses",
  "IconSunrise",
  "IconSunset2",
  "IconSunset",
  "IconSuperscript",
  "IconSvg",
  "IconSquareRoundedLetterG",
  "IconSquareRoundedLetterH",
  "IconSquareRoundedLetterI",
  "IconSquareRoundedLetterJ",
  "IconSquareRoundedLetterK",
  "IconSquareRoundedLetterL",
  "IconSquareRoundedLetterM",
  "IconSquareRoundedLetterN",
  "IconSquareRoundedLetterO",
  "IconSquareRoundedLetterP",
  "IconSquareRoundedLetterQ",
  "IconSquareRoundedLetterR",
  "IconSquareRoundedLetterS",
  "IconSquareRoundedLetterT",
  "IconSquareRoundedLetterU",
  "IconSquareRoundedLetterV",
  "IconSquareRoundedLetterW",
  "IconSquareRoundedLetterX",
  "IconSquareRoundedLetterY",
  "IconSquareRoundedLetterZ",
  "IconSquareRoundedMinus",
  "IconSquareRoundedNumber0Filled",
  "IconSquareRoundedNumber0",
  "IconSquareRoundedNumber1Filled",
  "IconSquareRoundedNumber1",
  "IconSquareRoundedNumber2Filled",
  "IconSquareRoundedNumber2",
  "IconSquareRoundedNumber3Filled",
  "IconSquareRoundedNumber3",
  "IconSquareRoundedNumber4Filled",
  "IconSquareRoundedNumber4",
  "IconSquareRoundedNumber5Filled",
  "IconSquareRoundedNumber5",
  "IconSquareRoundedNumber6Filled",
  "IconSquareRoundedNumber6",
  "IconSquareRoundedNumber7Filled",
  "IconSquareRoundedNumber7",
  "IconSquareRoundedNumber8Filled",
  "IconSquareRoundedNumber8",
  "IconSquareRoundedNumber9Filled",
  "IconSquareRoundedNumber9",
  "IconSquareRoundedPlusFilled",
  "IconSquareRoundedPlus",
  "IconSquareRoundedXFilled",
  "IconSquareRoundedX",
  "IconSquareRounded",
  "IconSquareToggleHorizontal",
  "IconSquareToggle",
  "IconSquareX",
  "IconSquare",
  "IconSquareNumber2",
  "IconSquareNumber3",
  "IconSquareNumber4",
  "IconSquareNumber5",
  "IconSquareNumber6",
  "IconSquareNumber7",
  "IconSquareNumber8",
  "IconSquareNumber9",
  "IconSquareOff",
  "IconSquarePlus",
  "IconSquareRoot2",
  "IconSquareRoot",
  "IconSquareRotatedFilled",
  "IconSquareRotatedForbid2",
  "IconSquareRotatedForbid",
  "IconSquareRotatedOff",
  "IconSquareRotated",
  "IconSquareRoundedArrowDownFilled",
  "IconSquareRoundedArrowDown",
  "IconSquareRoundedArrowLeftFilled",
  "IconSquareRoundedArrowLeft",
  "IconSquareRoundedArrowRightFilled",
  "IconSquareRoundedArrowRight",
  "IconSquareRoundedArrowUpFilled",
  "IconSquareRoundedArrowUp",
  "IconSquareRoundedCheckFilled",
  "IconSquareRoundedCheck",
  "IconSquareRoundedChevronDownFilled",
  "IconSquareRoundedChevronDown",
  "IconSquareRoundedChevronLeftFilled",
  "IconSquareRoundedChevronLeft",
  "IconSquareRoundedChevronRightFilled",
  "IconSquareRoundedChevronRight",
  "IconSquareRoundedChevronUpFilled",
  "IconSquareRoundedChevronUp",
  "IconSquareRoundedChevronsDownFilled",
  "IconSquareRoundedChevronsDown",
  "IconSquareRoundedChevronsLeftFilled",
  "IconSquareRoundedChevronsLeft",
  "IconSquareRoundedChevronsRightFilled",
  "IconSquareRoundedChevronsRight",
  "IconSquareRoundedChevronsUpFilled",
  "IconSquareRoundedChevronsUp",
  "IconSquareRoundedFilled",
  "IconSquareRoundedLetterA",
  "IconSquareRoundedLetterB",
  "IconSquareRoundedLetterC",
  "IconSquareRoundedLetterD",
  "IconSquareRoundedLetterE",
  "IconSquareRoundedLetterF",
  "IconSquareF1",
  "IconSquareF2Filled",
  "IconSquareF2",
  "IconSquareF3Filled",
  "IconSquareF3",
  "IconSquareF4Filled",
  "IconSquareF4",
  "IconSquareF5Filled",
  "IconSquareF5",
  "IconSquareF6Filled",
  "IconSquareF6",
  "IconSquareF7Filled",
  "IconSquareF7",
  "IconSquareF8Filled",
  "IconSquareF8",
  "IconSquareF9Filled",
  "IconSquareF9",
  "IconSquareForbid2",
  "IconSquareForbid",
  "IconSquareHalf",
  "IconSquareKey",
  "IconSquareLetterA",
  "IconSquareLetterB",
  "IconSquareLetterC",
  "IconSquareLetterD",
  "IconSquareLetterE",
  "IconSquareLetterF",
  "IconSquareLetterG",
  "IconSquareLetterH",
  "IconSquareLetterI",
  "IconSquareLetterJ",
  "IconSquareLetterK",
  "IconSquareLetterL",
  "IconSquareLetterM",
  "IconSquareLetterN",
  "IconSquareLetterO",
  "IconSquareLetterP",
  "IconSquareLetterQ",
  "IconSquareLetterR",
  "IconSquareLetterS",
  "IconSquareLetterT",
  "IconSquareLetterU",
  "IconSquareLetterV",
  "IconSquareLetterW",
  "IconSquareLetterX",
  "IconSquareLetterY",
  "IconSquareLetterZ",
  "IconSquareMinus",
  "IconSquareNumber0",
  "IconSquareNumber1",
  "IconSortZA",
  "IconSos",
  "IconSoupOff",
  "IconSoup",
  "IconSourceCode",
  "IconSpaceOff",
  "IconSpace",
  "IconSpacingHorizontal",
  "IconSpacingVertical",
  "IconSpadeFilled",
  "IconSpade",
  "IconSparkles",
  "IconSpeakerphone",
  "IconSpeedboat",
  "IconSpider",
  "IconSpiralOff",
  "IconSpiral",
  "IconSportBillard",
  "IconSpray",
  "IconSpyOff",
  "IconSpy",
  "IconSql",
  "IconSquare0Filled",
  "IconSquare1Filled",
  "IconSquare3Filled",
  "IconSquare4Filled",
  "IconSquare5Filled",
  "IconSquare6Filled",
  "IconSquare7Filled",
  "IconSquare8Filled",
  "IconSquare9Filled",
  "IconSquareArrowDown",
  "IconSquareArrowLeft",
  "IconSquareArrowRight",
  "IconSquareArrowUp",
  "IconSquareAsterisk",
  "IconSquareCheckFilled",
  "IconSquareCheck",
  "IconSquareChevronDown",
  "IconSquareChevronLeft",
  "IconSquareChevronRight",
  "IconSquareChevronUp",
  "IconSquareChevronsDown",
  "IconSquareChevronsLeft",
  "IconSquareChevronsRight",
  "IconSquareChevronsUp",
  "IconSquareDot",
  "IconSquareF0Filled",
  "IconSquareF0",
  "IconSquareF1Filled",
  "IconShoppingCart",
  "IconShovel",
  "IconShredder",
  "IconSignLeftFilled",
  "IconSignLeft",
  "IconSignRightFilled",
  "IconSignRight",
  "IconSignal2g",
  "IconSignal3g",
  "IconSignal4gPlus",
  "IconSignal4g",
  "IconSignal5g",
  "IconSignatureOff",
  "IconSignature",
  "IconSitemapOff",
  "IconSitemap",
  "IconSkateboardOff",
  "IconSkateboard",
  "IconSkull",
  "IconSlash",
  "IconSlashes",
  "IconSleigh",
  "IconSlice",
  "IconSlideshow",
  "IconSmartHomeOff",
  "IconSmartHome",
  "IconSmokingNo",
  "IconSmoking",
  "IconSnowflakeOff",
  "IconSnowflake",
  "IconSnowman",
  "IconSoccerField",
  "IconSocialOff",
  "IconSocial",
  "IconSock",
  "IconSofaOff",
  "IconSofa",
  "IconSolarPanel2",
  "IconSolarPanel",
  "IconSort09",
  "IconSort90",
  "IconSortAZ",
  "IconSortAscending2",
  "IconSortAscendingLetters",
  "IconSortAscendingNumbers",
  "IconSortAscending",
  "IconSortDescending2",
  "IconSortDescendingLetters",
  "IconSortDescendingNumbers",
  "IconSortDescending",
  "IconSeparatorHorizontal",
  "IconSeparatorVertical",
  "IconSeparator",
  "IconServer2",
  "IconServerBolt",
  "IconServerCog",
  "IconServerOff",
  "IconServer",
  "IconServicemark",
  "IconSettings2",
  "IconSettingsAutomation",
  "IconSettingsFilled",
  "IconSettingsOff",
  "IconSettings",
  "IconShadowOff",
  "IconShadow",
  "IconShape2",
  "IconShape3",
  "IconShapeOff",
  "IconShape",
  "IconShare2",
  "IconShare3",
  "IconShareOff",
  "IconShare",
  "IconShieldCheckFilled",
  "IconShieldCheck",
  "IconShieldCheckeredFilled",
  "IconShieldCheckered",
  "IconShieldChevron",
  "IconShieldFilled",
  "IconShieldHalfFilled",
  "IconShieldHalf",
  "IconShieldLockFilled",
  "IconShieldLock",
  "IconShieldOff",
  "IconShieldX",
  "IconShield",
  "IconShipOff",
  "IconShip",
  "IconShirtFilled",
  "IconShirtOff",
  "IconShirtSport",
  "IconShirt",
  "IconShoeOff",
  "IconShoe",
  "IconShoppingBag",
  "IconShoppingCartDiscount",
  "IconShoppingCartOff",
  "IconShoppingCartPlus",
  "IconShoppingCartX",
  "IconSTurnDown",
  "IconSTurnLeft",
  "IconSTurnRight",
  "IconSTurnUp",
  "IconSailboat2",
  "IconSailboatOff",
  "IconSailboat",
  "IconSalad",
  "IconSalt",
  "IconSatelliteOff",
  "IconSatellite",
  "IconSausage",
  "IconScaleOff",
  "IconScaleOutlineOff",
  "IconScaleOutline",
  "IconScale",
  "IconScanEye",
  "IconScan",
  "IconSchemaOff",
  "IconSchema",
  "IconSchoolBell",
  "IconSchoolOff",
  "IconSchool",
  "IconScissorsOff",
  "IconScissors",
  "IconScooterElectric",
  "IconScooter",
  "IconScreenShareOff",
  "IconScreenShare",
  "IconScreenshot",
  "IconScribbleOff",
  "IconScribble",
  "IconScriptMinus",
  "IconScriptPlus",
  "IconScriptX",
  "IconScript",
  "IconScubaMaskOff",
  "IconScubaMask",
  "IconSdk",
  "IconSearchOff",
  "IconSearch",
  "IconSectionSign",
  "IconSection",
  "IconSeedingOff",
  "IconSeeding",
  "IconSelect",
  "IconSelector",
  "IconSendOff",
  "IconSend",
  "IconSeo",
  "IconResize",
  "IconRibbonHealth",
  "IconRippleOff",
  "IconRipple",
  "IconRoadOff",
  "IconRoadSign",
  "IconRoad",
  "IconRobotOff",
  "IconRobot",
  "IconRocketOff",
  "IconRocket",
  "IconRollerSkating",
  "IconRollercoasterOff",
  "IconRollercoaster",
  "IconRosetteFilled",
  "IconRosetteNumber0",
  "IconRosetteNumber1",
  "IconRosetteNumber2",
  "IconRosetteNumber3",
  "IconRosetteNumber4",
  "IconRosetteNumber5",
  "IconRosetteNumber6",
  "IconRosetteNumber7",
  "IconRosetteNumber8",
  "IconRosetteNumber9",
  "IconRosette",
  "IconRotate2",
  "IconRotate360",
  "IconRotateClockwise2",
  "IconRotateClockwise",
  "IconRotateDot",
  "IconRotateRectangle",
  "IconRotate",
  "IconRoute2",
  "IconRouteOff",
  "IconRoute",
  "IconRouterOff",
  "IconRouter",
  "IconRowInsertBottom",
  "IconRowInsertTop",
  "IconRss",
  "IconRubberStampOff",
  "IconRubberStamp",
  "IconRuler2Off",
  "IconRuler2",
  "IconRuler3",
  "IconRulerMeasure",
  "IconRulerOff",
  "IconRuler",
  "IconRun",
  "IconRadiusBottomLeft",
  "IconRadiusBottomRight",
  "IconRadiusTopLeft",
  "IconRadiusTopRight",
  "IconRainbowOff",
  "IconRainbow",
  "IconRating12Plus",
  "IconRating14Plus",
  "IconRating16Plus",
  "IconRating18Plus",
  "IconRating21Plus",
  "IconRazorElectric",
  "IconRazor",
  "IconReceipt2",
  "IconReceiptOff",
  "IconReceiptRefund",
  "IconReceiptTax",
  "IconReceipt",
  "IconRecharging",
  "IconRecordMailOff",
  "IconRecordMail",
  "IconRectangleFilled",
  "IconRectangleVerticalFilled",
  "IconRectangleVertical",
  "IconRectangle",
  "IconRecycleOff",
  "IconRecycle",
  "IconRefreshAlert",
  "IconRefreshDot",
  "IconRefreshOff",
  "IconRefresh",
  "IconRegexOff",
  "IconRegex",
  "IconRegistered",
  "IconRelationManyToMany",
  "IconRelationOneToMany",
  "IconRelationOneToOne",
  "IconReload",
  "IconRepeatOff",
  "IconRepeatOnce",
  "IconRepeat",
  "IconReplaceFilled",
  "IconReplaceOff",
  "IconReplace",
  "IconReportAnalytics",
  "IconReportMedical",
  "IconReportMoney",
  "IconReportOff",
  "IconReportSearch",
  "IconReport",
  "IconPng",
  "IconPodiumOff",
  "IconPodium",
  "IconPointFilled",
  "IconPointOff",
  "IconPoint",
  "IconPointer",
  "IconPokeballOff",
  "IconPokeball",
  "IconPokerChip",
  "IconPolaroid",
  "IconPolygonOff",
  "IconPolygon",
  "IconPoo",
  "IconPoolOff",
  "IconPool",
  "IconPower",
  "IconPray",
  "IconPremiumRights",
  "IconPrescription",
  "IconPresentationAnalytics",
  "IconPresentationOff",
  "IconPresentation",
  "IconPrinterOff",
  "IconPrinter",
  "IconPrison",
  "IconPrompt",
  "IconPropellerOff",
  "IconPropeller",
  "IconPumpkinScary",
  "IconPuzzle2",
  "IconPuzzleFilled",
  "IconPuzzleOff",
  "IconPuzzle",
  "IconPyramidOff",
  "IconPyramid",
  "IconQrcodeOff",
  "IconQrcode",
  "IconQuestionCircle",
  "IconQuestionMark",
  "IconQuoteOff",
  "IconQuote",
  "IconRadar2",
  "IconRadarOff",
  "IconRadar",
  "IconRadioOff",
  "IconRadio",
  "IconRadioactiveFilled",
  "IconRadioactiveOff",
  "IconRadioactive",
  "IconPizza",
  "IconPlaceholder",
  "IconPlaneArrival",
  "IconPlaneDeparture",
  "IconPlaneInflight",
  "IconPlaneOff",
  "IconPlaneTilt",
  "IconPlane",
  "IconPlanetOff",
  "IconPlanet",
  "IconPlant2Off",
  "IconPlant2",
  "IconPlantOff",
  "IconPlant",
  "IconPlayCardOff",
  "IconPlayCard",
  "IconPlayerEjectFilled",
  "IconPlayerEject",
  "IconPlayerPauseFilled",
  "IconPlayerPause",
  "IconPlayerPlayFilled",
  "IconPlayerPlay",
  "IconPlayerRecordFilled",
  "IconPlayerRecord",
  "IconPlayerSkipBackFilled",
  "IconPlayerSkipBack",
  "IconPlayerSkipForwardFilled",
  "IconPlayerSkipForward",
  "IconPlayerStopFilled",
  "IconPlayerStop",
  "IconPlayerTrackNextFilled",
  "IconPlayerTrackNext",
  "IconPlayerTrackPrevFilled",
  "IconPlayerTrackPrev",
  "IconPlaylistAdd",
  "IconPlaylistOff",
  "IconPlaylistX",
  "IconPlaylist",
  "IconPlaystationCircle",
  "IconPlaystationSquare",
  "IconPlaystationTriangle",
  "IconPlaystationX",
  "IconPlugConnectedX",
  "IconPlugConnected",
  "IconPlugOff",
  "IconPlugX",
  "IconPlug",
  "IconPlusEqual",
  "IconPlusMinus",
  "IconPlus",
  "IconPercentage",
  "IconPerfume",
  "IconPerspectiveOff",
  "IconPerspective",
  "IconPhoneCall",
  "IconPhoneCalling",
  "IconPhoneCheck",
  "IconPhoneIncoming",
  "IconPhoneOff",
  "IconPhoneOutgoing",
  "IconPhonePause",
  "IconPhonePlus",
  "IconPhoneX",
  "IconPhone",
  "IconPhotoCancel",
  "IconPhotoCheck",
  "IconPhotoDown",
  "IconPhotoEdit",
  "IconPhotoHeart",
  "IconPhotoMinus",
  "IconPhotoOff",
  "IconPhotoPlus",
  "IconPhotoSearch",
  "IconPhotoSensor2",
  "IconPhotoSensor3",
  "IconPhotoSensor",
  "IconPhotoShield",
  "IconPhotoStar",
  "IconPhotoUp",
  "IconPhotoX",
  "IconPhoto",
  "IconPhysotherapist",
  "IconPictureInPictureOff",
  "IconPictureInPictureOn",
  "IconPictureInPictureTop",
  "IconPictureInPicture",
  "IconPigMoney",
  "IconPigOff",
  "IconPig",
  "IconPilcrow",
  "IconPillOff",
  "IconPill",
  "IconPills",
  "IconPinFilled",
  "IconPin",
  "IconPingPong",
  "IconPinnedFilled",
  "IconPinnedOff",
  "IconPinned",
  "IconPizzaOff",
  "IconOvalVerticalFilled",
  "IconOvalVertical",
  "IconOval",
  "IconOverline",
  "IconPackageExport",
  "IconPackageImport",
  "IconPackageOff",
  "IconPackage",
  "IconPackages",
  "IconPacman",
  "IconPageBreak",
  "IconPaintFilled",
  "IconPaintOff",
  "IconPaint",
  "IconPaletteOff",
  "IconPalette",
  "IconPanoramaHorizontalOff",
  "IconPanoramaHorizontal",
  "IconPanoramaVerticalOff",
  "IconPanoramaVertical",
  "IconPaperBagOff",
  "IconPaperBag",
  "IconPaperclip",
  "IconParachuteOff",
  "IconParachute",
  "IconParenthesesOff",
  "IconParentheses",
  "IconParkingOff",
  "IconParking",
  "IconPassword",
  "IconPawFilled",
  "IconPawOff",
  "IconPaw",
  "IconPdf",
  "IconPeace",
  "IconPencilMinus",
  "IconPencilOff",
  "IconPencilPlus",
  "IconPencil",
  "IconPennant2Filled",
  "IconPennant2",
  "IconPennantFilled",
  "IconPennantOff",
  "IconPennant",
  "IconPentagonFilled",
  "IconPentagonOff",
  "IconPentagon",
  "IconPentagram",
  "IconPepperOff",
  "IconPepper",
  "IconMusicOff",
  "IconMusic",
  "IconNavigationFilled",
  "IconNavigationOff",
  "IconNavigation",
  "IconNeedleThread",
  "IconNeedle",
  "IconNetworkOff",
  "IconNetwork",
  "IconNewSection",
  "IconNewsOff",
  "IconNews",
  "IconNfcOff",
  "IconNfc",
  "IconNoCopyright",
  "IconNoCreativeCommons",
  "IconNoDerivatives",
  "IconNorthStar",
  "IconNoteOff",
  "IconNote",
  "IconNotebookOff",
  "IconNotebook",
  "IconNotesOff",
  "IconNotes",
  "IconNotificationOff",
  "IconNotification",
  "IconNumber0",
  "IconNumber1",
  "IconNumber2",
  "IconNumber3",
  "IconNumber4",
  "IconNumber5",
  "IconNumber6",
  "IconNumber7",
  "IconNumber8",
  "IconNumber9",
  "IconNumber",
  "IconNumbers",
  "IconNurse",
  "IconOctagonFilled",
  "IconOctagonOff",
  "IconOctagon",
  "IconOld",
  "IconOlympicsOff",
  "IconOlympics",
  "IconOm",
  "IconOmega",
  "IconOutbound",
  "IconOutlet",
  "IconOvalFilled",
  "IconMoodNervous",
  "IconMoodNeutral",
  "IconMoodOff",
  "IconMoodPin",
  "IconMoodPlus",
  "IconMoodSad2",
  "IconMoodSadDizzy",
  "IconMoodSadSquint",
  "IconMoodSad",
  "IconMoodSearch",
  "IconMoodSick",
  "IconMoodSilence",
  "IconMoodSing",
  "IconMoodSmileBeam",
  "IconMoodSmileDizzy",
  "IconMoodSmile",
  "IconMoodSuprised",
  "IconMoodTongueWink2",
  "IconMoodTongueWink",
  "IconMoodTongue",
  "IconMoodUnamused",
  "IconMoodUp",
  "IconMoodWink2",
  "IconMoodWink",
  "IconMoodWrrr",
  "IconMoodX",
  "IconMoodXd",
  "IconMoon2",
  "IconMoonFilled",
  "IconMoonOff",
  "IconMoonStars",
  "IconMoon",
  "IconMoped",
  "IconMotorbike",
  "IconMountainOff",
  "IconMountain",
  "IconMouse2",
  "IconMouseOff",
  "IconMouse",
  "IconMoustache",
  "IconMovieOff",
  "IconMovie",
  "IconMugOff",
  "IconMug",
  "IconMultiplier05x",
  "IconMultiplier15x",
  "IconMultiplier1x",
  "IconMultiplier2x",
  "IconMushroomOff",
  "IconMushroom",
  "IconMessageForward",
  "IconMessageLanguage",
  "IconMessageOff",
  "IconMessagePlus",
  "IconMessageReport",
  "IconMessageShare",
  "IconMessage",
  "IconMessagesOff",
  "IconMessages",
  "IconMeteorOff",
  "IconMeteor",
  "IconMickeyFilled",
  "IconMickey",
  "IconMicrophone2Off",
  "IconMicrophone2",
  "IconMicrophoneOff",
  "IconMicrophone",
  "IconMicroscopeOff",
  "IconMicroscope",
  "IconMicrowaveOff",
  "IconMicrowave",
  "IconMilitaryAward",
  "IconMilitaryRank",
  "IconMilkOff",
  "IconMilk",
  "IconMilkshake",
  "IconMinimize",
  "IconMinusVertical",
  "IconMinus",
  "IconMistOff",
  "IconMist",
  "IconMoneybag",
  "IconMoodAngry",
  "IconMoodAnnoyed2",
  "IconMoodAnnoyed",
  "IconMoodBoy",
  "IconMoodCheck",
  "IconMoodCog",
  "IconMoodConfuzed",
  "IconMoodCrazyHappy",
  "IconMoodCry",
  "IconMoodDollar",
  "IconMoodEmpty",
  "IconMoodHappy",
  "IconMoodHeart",
  "IconMoodKid",
  "IconMoodLookLeft",
  "IconMoodLookRight",
  "IconMoodMinus",
  "IconMoodNerd",
  "IconMathFunction",
  "IconMathGreater",
  "IconMathIntegralX",
  "IconMathIntegral",
  "IconMathIntegrals",
  "IconMathLower",
  "IconMathMax",
  "IconMathMin",
  "IconMathNot",
  "IconMathOff",
  "IconMathPiDivide2",
  "IconMathPi",
  "IconMathSymbols",
  "IconMathXDivide2",
  "IconMathXDivideY2",
  "IconMathXDivideY",
  "IconMathXMinusX",
  "IconMathXMinusY",
  "IconMathXPlusX",
  "IconMathXPlusY",
  "IconMathXy",
  "IconMathYMinusY",
  "IconMathYPlusY",
  "IconMath",
  "IconMaximizeOff",
  "IconMaximize",
  "IconMeatOff",
  "IconMeat",
  "IconMedal2",
  "IconMedal",
  "IconMedicalCrossFilled",
  "IconMedicalCrossOff",
  "IconMedicalCross",
  "IconMedicineSyrup",
  "IconMeeple",
  "IconMenorah",
  "IconMenu2",
  "IconMenuOrder",
  "IconMenu",
  "IconMessage2Code",
  "IconMessage2Off",
  "IconMessage2Share",
  "IconMessage2",
  "IconMessageChatbot",
  "IconMessageCircle2Filled",
  "IconMessageCircle2",
  "IconMessageCircleOff",
  "IconMessageCircle",
  "IconMessageCode",
  "IconMessageDots",
  "IconLogicXor",
  "IconLogin",
  "IconLogout",
  "IconLollipopOff",
  "IconLollipop",
  "IconLuggageOff",
  "IconLuggage",
  "IconLungsOff",
  "IconLungs",
  "IconMacroOff",
  "IconMacro",
  "IconMagnetOff",
  "IconMagnet",
  "IconMailFast",
  "IconMailForward",
  "IconMailOff",
  "IconMailOpened",
  "IconMail",
  "IconMailboxOff",
  "IconMailbox",
  "IconMan",
  "IconManualGearbox",
  "IconMap2",
  "IconMapOff",
  "IconMapPinFilled",
  "IconMapPinOff",
  "IconMapPinShare",
  "IconMapPin",
  "IconMapPins",
  "IconMapSearch",
  "IconMap",
  "IconMarkdownOff",
  "IconMarkdown",
  "IconMarquee2",
  "IconMarqueeOff",
  "IconMarquee",
  "IconMars",
  "IconMaskOff",
  "IconMask",
  "IconMasksTheaterOff",
  "IconMasksTheater",
  "IconMassage",
  "IconMatchstick",
  "IconMath1Divide2",
  "IconMath1Divide3",
  "IconMathAvg",
  "IconMathEqualGreater",
  "IconMathEqualLower",
  "IconMathFunctionOff",
  "IconMathFunctionY",
  "IconLetterT",
  "IconLetterU",
  "IconLetterV",
  "IconLetterW",
  "IconLetterX",
  "IconLetterY",
  "IconLetterZ",
  "IconLicenseOff",
  "IconLicense",
  "IconLifebuoyOff",
  "IconLifebuoy",
  "IconLighter",
  "IconLineDashed",
  "IconLineDotted",
  "IconLineHeight",
  "IconLine",
  "IconLinkOff",
  "IconLink",
  "IconListCheck",
  "IconListDetails",
  "IconListNumbers",
  "IconListSearch",
  "IconList",
  "IconLivePhotoOff",
  "IconLivePhoto",
  "IconLiveView",
  "IconLoader2",
  "IconLoader3",
  "IconLoaderQuarter",
  "IconLoader",
  "IconLocationBroken",
  "IconLocationFilled",
  "IconLocationOff",
  "IconLocation",
  "IconLockAccessOff",
  "IconLockAccess",
  "IconLockOff",
  "IconLockOpenOff",
  "IconLockOpen",
  "IconLockSquareRoundedFilled",
  "IconLockSquareRounded",
  "IconLockSquare",
  "IconLock",
  "IconLogicAnd",
  "IconLogicBuffer",
  "IconLogicNand",
  "IconLogicNor",
  "IconLogicNot",
  "IconLogicOr",
  "IconLogicXnor",
  "IconLayoutColumns",
  "IconLayoutDashboard",
  "IconLayoutDistributeHorizontal",
  "IconLayoutDistributeVertical",
  "IconLayoutGridAdd",
  "IconLayoutGrid",
  "IconLayoutKanban",
  "IconLayoutList",
  "IconLayoutNavbarCollapse",
  "IconLayoutNavbarExpand",
  "IconLayoutNavbar",
  "IconLayoutOff",
  "IconLayoutRows",
  "IconLayoutSidebarLeftCollapse",
  "IconLayoutSidebarLeftExpand",
  "IconLayoutSidebarRightCollapse",
  "IconLayoutSidebarRightExpand",
  "IconLayoutSidebarRight",
  "IconLayoutSidebar",
  "IconLayout",
  "IconLeafOff",
  "IconLeaf",
  "IconLegoOff",
  "IconLego",
  "IconLemon2",
  "IconLemon",
  "IconLetterA",
  "IconLetterB",
  "IconLetterC",
  "IconLetterCaseLower",
  "IconLetterCaseToggle",
  "IconLetterCaseUpper",
  "IconLetterCase",
  "IconLetterD",
  "IconLetterE",
  "IconLetterF",
  "IconLetterG",
  "IconLetterH",
  "IconLetterI",
  "IconLetterJ",
  "IconLetterK",
  "IconLetterL",
  "IconLetterM",
  "IconLetterN",
  "IconLetterO",
  "IconLetterP",
  "IconLetterQ",
  "IconLetterR",
  "IconLetterS",
  "IconLetterSpacing",
  "IconJson",
  "IconJumpRope",
  "IconKarate",
  "IconKayak",
  "IconKering",
  "IconKeyOff",
  "IconKey",
  "IconKeyboardHide",
  "IconKeyboardOff",
  "IconKeyboardShow",
  "IconKeyboard",
  "IconKeyframeAlignCenter",
  "IconKeyframeAlignHorizontal",
  "IconKeyframeAlignVertical",
  "IconKeyframe",
  "IconKeyframes",
  "IconLadderOff",
  "IconLadder",
  "IconLambda",
  "IconLamp2",
  "IconLampOff",
  "IconLamp",
  "IconLanguageHiragana",
  "IconLanguageKatakana",
  "IconLanguageOff",
  "IconLanguage",
  "IconLassoOff",
  "IconLassoPolygon",
  "IconLasso",
  "IconLayersDifference",
  "IconLayersIntersect2",
  "IconLayersIntersect",
  "IconLayersLinked",
  "IconLayersOff",
  "IconLayersSubtract",
  "IconLayersUnion",
  "IconLayout2",
  "IconLayoutAlignBottom",
  "IconLayoutAlignCenter",
  "IconLayoutAlignLeft",
  "IconLayoutAlignMiddle",
  "IconLayoutAlignRight",
  "IconLayoutAlignTop",
  "IconLayoutBoardSplit",
  "IconLayoutBoard",
  "IconLayoutBottombarCollapse",
  "IconLayoutBottombarExpand",
  "IconLayoutBottombar",
  "IconLayoutCards",
  "IconLayoutCollage",
  "IconIceSkating",
  "IconIconsOff",
  "IconIcons",
  "IconIdBadge2",
  "IconIdBadgeOff",
  "IconIdBadge",
  "IconIdOff",
  "IconId",
  "IconInboxOff",
  "IconInbox",
  "IconIndentDecrease",
  "IconIndentIncrease",
  "IconInfinityOff",
  "IconInfinity",
  "IconInfoCircleFilled",
  "IconInfoCircle",
  "IconInfoHexagon",
  "IconInfoOctagon",
  "IconInfoSquareRoundedFilled",
  "IconInfoSquareRounded",
  "IconInfoSquare",
  "IconInnerShadowBottomFilled",
  "IconInnerShadowBottomLeftFilled",
  "IconInnerShadowBottomLeft",
  "IconInnerShadowBottomRightFilled",
  "IconInnerShadowBottomRight",
  "IconInnerShadowBottom",
  "IconInnerShadowLeftFilled",
  "IconInnerShadowLeft",
  "IconInnerShadowRightFilled",
  "IconInnerShadowRight",
  "IconInnerShadowTopFilled",
  "IconInnerShadowTopLeftFilled",
  "IconInnerShadowTopLeft",
  "IconInnerShadowTopRightFilled",
  "IconInnerShadowTopRight",
  "IconInnerShadowTop",
  "IconInputSearch",
  "IconIroning1",
  "IconIroning2",
  "IconIroning3",
  "IconIroningOff",
  "IconIroningSteamOff",
  "IconIroningSteam",
  "IconItalic",
  "IconJacket",
  "IconJetpack",
  "IconJewishStarFilled",
  "IconJewishStar",
  "IconJpg",
  "IconHierarchy3",
  "IconHierarchyOff",
  "IconHierarchy",
  "IconHighlightOff",
  "IconHighlight",
  "IconHistoryOff",
  "IconHistoryToggle",
  "IconHistory",
  "IconHome2",
  "IconHomeBolt",
  "IconHomeCancel",
  "IconHomeCheck",
  "IconHomeCog",
  "IconHomeDollar",
  "IconHomeDot",
  "IconHomeDown",
  "IconHomeEco",
  "IconHomeEdit",
  "IconHomeExclamation",
  "IconHomeHand",
  "IconHomeHeart",
  "IconHomeInfinity",
  "IconHomeLink",
  "IconHomeMinus",
  "IconHomeMove",
  "IconHomeOff",
  "IconHomePlus",
  "IconHomeQuestion",
  "IconHomeRibbon",
  "IconHomeSearch",
  "IconHomeShare",
  "IconHomeShield",
  "IconHomeSignal",
  "IconHomeStar",
  "IconHomeStats",
  "IconHomeUp",
  "IconHomeX",
  "IconHome",
  "IconHorseToy",
  "IconHotelService",
  "IconHourglassEmpty",
  "IconHourglassFilled",
  "IconHourglassHigh",
  "IconHourglassLow",
  "IconHourglassOff",
  "IconHourglass",
  "IconHtml",
  "IconIceCream2",
  "IconIceCreamOff",
  "IconIceCream",
  "IconHexagon3Filled",
  "IconHexagon3d",
  "IconHexagon4Filled",
  "IconHexagon5Filled",
  "IconHexagon6Filled",
  "IconHexagon7Filled",
  "IconHexagon8Filled",
  "IconHexagon9Filled",
  "IconHexagonFilled",
  "IconHexagonLetterA",
  "IconHexagonLetterB",
  "IconHexagonLetterC",
  "IconHexagonLetterD",
  "IconHexagonLetterE",
  "IconHexagonLetterF",
  "IconHexagonLetterG",
  "IconHexagonLetterH",
  "IconHexagonLetterI",
  "IconHexagonLetterJ",
  "IconHexagonLetterK",
  "IconHexagonLetterL",
  "IconHexagonLetterM",
  "IconHexagonLetterN",
  "IconHexagonLetterO",
  "IconHexagonLetterP",
  "IconHexagonLetterQ",
  "IconHexagonLetterR",
  "IconHexagonLetterS",
  "IconHexagonLetterT",
  "IconHexagonLetterU",
  "IconHexagonLetterV",
  "IconHexagonLetterW",
  "IconHexagonLetterX",
  "IconHexagonLetterY",
  "IconHexagonLetterZ",
  "IconHexagonNumber0",
  "IconHexagonNumber1",
  "IconHexagonNumber2",
  "IconHexagonNumber3",
  "IconHexagonNumber4",
  "IconHexagonNumber5",
  "IconHexagonNumber6",
  "IconHexagonNumber7",
  "IconHexagonNumber8",
  "IconHexagonNumber9",
  "IconHexagonOff",
  "IconHexagon",
  "IconHexagonsOff",
  "IconHexagons",
  "IconHierarchy2",
  "IconHammerOff",
  "IconHammer",
  "IconHandClick",
  "IconHandFingerOff",
  "IconHandFinger",
  "IconHandGrab",
  "IconHandLittleFinger",
  "IconHandMiddleFinger",
  "IconHandMove",
  "IconHandOff",
  "IconHandRingFinger",
  "IconHandRock",
  "IconHandSanitizer",
  "IconHandStop",
  "IconHandThreeFingers",
  "IconHandTwoFingers",
  "IconHanger2",
  "IconHangerOff",
  "IconHanger",
  "IconHash",
  "IconHaze",
  "IconHeadingOff",
  "IconHeading",
  "IconHeadphonesOff",
  "IconHeadphones",
  "IconHeadsetOff",
  "IconHeadset",
  "IconHealthRecognition",
  "IconHeartBroken",
  "IconHeartFilled",
  "IconHeartHandshake",
  "IconHeartMinus",
  "IconHeartOff",
  "IconHeartPlus",
  "IconHeartRateMonitor",
  "IconHeart",
  "IconHeartbeat",
  "IconHeartsOff",
  "IconHearts",
  "IconHelicopterLanding",
  "IconHelicopter",
  "IconHelmetOff",
  "IconHelmet",
  "IconHelpHexagon",
  "IconHelpOctagon",
  "IconHelpOff",
  "IconHelp",
  "IconHexagon0Filled",
  "IconHexagon1Filled",
  "IconHexagon2Filled",
  "IconGhostFilled",
  "IconGhostOff",
  "IconGhost",
  "IconGif",
  "IconGiftCard",
  "IconGiftOff",
  "IconGift",
  "IconGitBranchDeleted",
  "IconGitBranch",
  "IconGitCherryPick",
  "IconGitCommit",
  "IconGitCompare",
  "IconGitFork",
  "IconGitMerge",
  "IconGitPullRequestClosed",
  "IconGitPullRequestDraft",
  "IconGitPullRequest",
  "IconGizmo",
  "IconGlassFull",
  "IconGlassOff",
  "IconGlass",
  "IconGlobeOff",
  "IconGlobe",
  "IconGoGame",
  "IconGolfOff",
  "IconGolf",
  "IconGps",
  "IconGradienter",
  "IconGrain",
  "IconGraphOff",
  "IconGraph",
  "IconGrave2",
  "IconGrave",
  "IconGridDots",
  "IconGridPattern",
  "IconGrillFork",
  "IconGrillOff",
  "IconGrillSpatula",
  "IconGrill",
  "IconGripHorizontal",
  "IconGripVertical",
  "IconGrowth",
  "IconGuitarPickFilled",
  "IconGuitarPick",
  "IconH1",
  "IconH2",
  "IconH3",
  "IconH4",
  "IconH5",
  "IconH6",
  "IconFolderMinus",
  "IconFolderOff",
  "IconFolderPlus",
  "IconFolderX",
  "IconFolder",
  "IconFoldersOff",
  "IconFolders",
  "IconForbid2",
  "IconForbid",
  "IconForklift",
  "IconForms",
  "IconFountainOff",
  "IconFountain",
  "IconFrameOff",
  "IconFrame",
  "IconFreeRights",
  "IconFridgeOff",
  "IconFridge",
  "IconFriendsOff",
  "IconFriends",
  "IconFunctionOff",
  "IconFunction",
  "IconGardenCartOff",
  "IconGardenCart",
  "IconGasStationOff",
  "IconGasStation",
  "IconGaugeOff",
  "IconGauge",
  "IconGavel",
  "IconGenderAgender",
  "IconGenderAndrogyne",
  "IconGenderBigender",
  "IconGenderDemiboy",
  "IconGenderDemigirl",
  "IconGenderEpicene",
  "IconGenderFemale",
  "IconGenderFemme",
  "IconGenderGenderfluid",
  "IconGenderGenderless",
  "IconGenderGenderqueer",
  "IconGenderHermaphrodite",
  "IconGenderIntergender",
  "IconGenderMale",
  "IconGenderNeutrois",
  "IconGenderThird",
  "IconGenderTransgender",
  "IconGenderTrasvesti",
  "IconGeometry",
  "IconGhost2Filled",
  "IconGhost2",
  "IconFile",
  "IconFilesOff",
  "IconFiles",
  "IconFilterOff",
  "IconFilter",
  "IconFilters",
  "IconFingerprintOff",
  "IconFingerprint",
  "IconFireHydrantOff",
  "IconFireHydrant",
  "IconFiretruck",
  "IconFirstAidKitOff",
  "IconFirstAidKit",
  "IconFishBone",
  "IconFishChristianity",
  "IconFishHookOff",
  "IconFishHook",
  "IconFishOff",
  "IconFish",
  "IconFlag2Filled",
  "IconFlag2Off",
  "IconFlag2",
  "IconFlag3Filled",
  "IconFlag3",
  "IconFlagFilled",
  "IconFlagOff",
  "IconFlag",
  "IconFlameOff",
  "IconFlame",
  "IconFlare",
  "IconFlask2Off",
  "IconFlask2",
  "IconFlaskOff",
  "IconFlask",
  "IconFlipFlops",
  "IconFlipHorizontal",
  "IconFlipVertical",
  "IconFloatCenter",
  "IconFloatLeft",
  "IconFloatNone",
  "IconFloatRight",
  "IconFlowerOff",
  "IconFlower",
  "IconFocus2",
  "IconFocusCentered",
  "IconFocus",
  "IconFoldDown",
  "IconFoldUp",
  "IconFold",
  "IconFolderFilled",
  "IconFileCode",
  "IconFileDatabase",
  "IconFileDelta",
  "IconFileDescription",
  "IconFileDiff",
  "IconFileDigit",
  "IconFileDislike",
  "IconFileDollar",
  "IconFileDots",
  "IconFileDownload",
  "IconFileEuro",
  "IconFileExport",
  "IconFileFilled",
  "IconFileFunction",
  "IconFileHorizontal",
  "IconFileImport",
  "IconFileInfinity",
  "IconFileInfo",
  "IconFileInvoice",
  "IconFileLambda",
  "IconFileLike",
  "IconFileMinus",
  "IconFileMusic",
  "IconFileOff",
  "IconFileOrientation",
  "IconFilePencil",
  "IconFilePercent",
  "IconFilePhone",
  "IconFilePlus",
  "IconFilePower",
  "IconFileReport",
  "IconFileRss",
  "IconFileScissors",
  "IconFileSearch",
  "IconFileSettings",
  "IconFileShredder",
  "IconFileSignal",
  "IconFileSpreadsheet",
  "IconFileStack",
  "IconFileStar",
  "IconFileSymlink",
  "IconFileText",
  "IconFileTime",
  "IconFileTypography",
  "IconFileUnknown",
  "IconFileUpload",
  "IconFileVector",
  "IconFileXFilled",
  "IconFileX",
  "IconFileZip",
  "IconEqual",
  "IconEraserOff",
  "IconEraser",
  "IconError404Off",
  "IconError404",
  "IconExchangeOff",
  "IconExchange",
  "IconExclamationCircle",
  "IconExclamationMarkOff",
  "IconExclamationMark",
  "IconExplicitOff",
  "IconExplicit",
  "IconExposure0",
  "IconExposureMinus1",
  "IconExposureMinus2",
  "IconExposureOff",
  "IconExposurePlus1",
  "IconExposurePlus2",
  "IconExposure",
  "IconExternalLinkOff",
  "IconExternalLink",
  "IconEyeCheck",
  "IconEyeFilled",
  "IconEyeOff",
  "IconEyeTable",
  "IconEye",
  "IconEyeglass2",
  "IconEyeglassOff",
  "IconEyeglass",
  "IconFaceIdError",
  "IconFaceId",
  "IconFaceMaskOff",
  "IconFaceMask",
  "IconFall",
  "IconFeatherOff",
  "IconFeather",
  "IconFenceOff",
  "IconFence",
  "IconFidgetSpinner",
  "IconFile3d",
  "IconFileAlert",
  "IconFileAnalytics",
  "IconFileArrowLeft",
  "IconFileArrowRight",
  "IconFileBarcode",
  "IconFileBroken",
  "IconFileCertificate",
  "IconFileChart",
  "IconFileCheck",
  "IconFileCode2",
  "IconDoorOff",
  "IconDoor",
  "IconDotsCircleHorizontal",
  "IconDotsDiagonal2",
  "IconDotsDiagonal",
  "IconDotsVertical",
  "IconDots",
  "IconDownloadOff",
  "IconDownload",
  "IconDragDrop2",
  "IconDragDrop",
  "IconDroneOff",
  "IconDrone",
  "IconDropCircle",
  "IconDropletFilled2",
  "IconDropletFilled",
  "IconDropletHalf2",
  "IconDropletHalfFilled",
  "IconDropletHalf",
  "IconDropletOff",
  "IconDroplet",
  "IconEPassport",
  "IconEarOff",
  "IconEar",
  "IconEaseInControlPoint",
  "IconEaseInOutControlPoints",
  "IconEaseInOut",
  "IconEaseIn",
  "IconEaseOutControlPoint",
  "IconEaseOut",
  "IconEditCircleOff",
  "IconEditCircle",
  "IconEditOff",
  "IconEdit",
  "IconEggCracked",
  "IconEggFilled",
  "IconEggFried",
  "IconEggOff",
  "IconEgg",
  "IconEggs",
  "IconElevatorOff",
  "IconElevator",
  "IconEmergencyBed",
  "IconEmpathizeOff",
  "IconEmpathize",
  "IconEmphasis",
  "IconEngineOff",
  "IconEngine",
  "IconEqualDouble",
  "IconEqualNot",
  "IconDialpadOff",
  "IconDialpad",
  "IconDiamondFilled",
  "IconDiamondOff",
  "IconDiamond",
  "IconDiamondsFilled",
  "IconDiamonds",
  "IconDice1Filled",
  "IconDice1",
  "IconDice2Filled",
  "IconDice2",
  "IconDice3Filled",
  "IconDice3",
  "IconDice4Filled",
  "IconDice4",
  "IconDice5Filled",
  "IconDice5",
  "IconDice6Filled",
  "IconDice6",
  "IconDiceFilled",
  "IconDice",
  "IconDimensions",
  "IconDirectionHorizontal",
  "IconDirectionSignFilled",
  "IconDirectionSignOff",
  "IconDirectionSign",
  "IconDirection",
  "IconDirectionsOff",
  "IconDirections",
  "IconDisabled2",
  "IconDisabledOff",
  "IconDisabled",
  "IconDiscGolf",
  "IconDiscOff",
  "IconDisc",
  "IconDiscount2Off",
  "IconDiscount2",
  "IconDiscountCheckFilled",
  "IconDiscountCheck",
  "IconDiscountOff",
  "IconDiscount",
  "IconDivide",
  "IconDna2Off",
  "IconDna2",
  "IconDnaOff",
  "IconDna",
  "IconDogBowl",
  "IconDog",
  "IconDoorEnter",
  "IconDoorExit",
  "IconDeviceAnalytics",
  "IconDeviceAudioTape",
  "IconDeviceCameraPhone",
  "IconDeviceCctvOff",
  "IconDeviceCctv",
  "IconDeviceComputerCameraOff",
  "IconDeviceComputerCamera",
  "IconDeviceDesktopAnalytics",
  "IconDeviceDesktopOff",
  "IconDeviceDesktop",
  "IconDeviceFloppy",
  "IconDeviceGamepad2",
  "IconDeviceGamepad",
  "IconDeviceHeartMonitor",
  "IconDeviceImac",
  "IconDeviceIpadHorizontal",
  "IconDeviceIpad",
  "IconDeviceLandlinePhone",
  "IconDeviceLaptopOff",
  "IconDeviceLaptop",
  "IconDeviceMobileCharging",
  "IconDeviceMobileMessage",
  "IconDeviceMobileOff",
  "IconDeviceMobileRotated",
  "IconDeviceMobileVibration",
  "IconDeviceMobile",
  "IconDeviceNintendoOff",
  "IconDeviceNintendo",
  "IconDeviceRemote",
  "IconDeviceSdCard",
  "IconDeviceSim1",
  "IconDeviceSim2",
  "IconDeviceSim3",
  "IconDeviceSim",
  "IconDeviceSpeakerOff",
  "IconDeviceSpeaker",
  "IconDeviceTabletOff",
  "IconDeviceTablet",
  "IconDeviceTvOff",
  "IconDeviceTvOld",
  "IconDeviceTv",
  "IconDeviceWatchOff",
  "IconDeviceWatchStats2",
  "IconDeviceWatchStats",
  "IconDeviceWatch",
  "IconDevices2",
  "IconDevicesOff",
  "IconDevicesPcOff",
  "IconDevicesPc",
  "IconDevices",
  "IconCurrencyMonero",
  "IconCurrencyNaira",
  "IconCurrencyNano",
  "IconCurrencyOff",
  "IconCurrencyPaanga",
  "IconCurrencyPeso",
  "IconCurrencyPoundOff",
  "IconCurrencyPound",
  "IconCurrencyQuetzal",
  "IconCurrencyReal",
  "IconCurrencyRenminbi",
  "IconCurrencyRipple",
  "IconCurrencyRiyal",
  "IconCurrencyRubel",
  "IconCurrencyRufiyaa",
  "IconCurrencyRupeeNepalese",
  "IconCurrencyRupee",
  "IconCurrencyShekel",
  "IconCurrencySolana",
  "IconCurrencySom",
  "IconCurrencyTaka",
  "IconCurrencyTenge",
  "IconCurrencyTugrik",
  "IconCurrencyWon",
  "IconCurrencyYenOff",
  "IconCurrencyYen",
  "IconCurrencyYuan",
  "IconCurrencyZloty",
  "IconCurrency",
  "IconCurrentLocationOff",
  "IconCurrentLocation",
  "IconCursorOff",
  "IconCursorText",
  "IconCut",
  "IconCylinder",
  "IconDashboardOff",
  "IconDashboard",
  "IconDatabaseExport",
  "IconDatabaseImport",
  "IconDatabaseOff",
  "IconDatabase",
  "IconDeer",
  "IconDelta",
  "IconDentalBroken",
  "IconDentalOff",
  "IconDental",
  "IconDetailsOff",
  "IconDetails",
  "IconDeviceAirpodsCase",
  "IconDeviceAirpods",
  "IconCrossOff",
  "IconCross",
  "IconCrosshair",
  "IconCrownOff",
  "IconCrown",
  "IconCrutchesOff",
  "IconCrutches",
  "IconCrystalBall",
  "IconCsv",
  "IconCubeSend",
  "IconCubeUnfolded",
  "IconCupOff",
  "IconCup",
  "IconCurling",
  "IconCurlyLoop",
  "IconCurrencyAfghani",
  "IconCurrencyBahraini",
  "IconCurrencyBaht",
  "IconCurrencyBitcoin",
  "IconCurrencyCent",
  "IconCurrencyDinar",
  "IconCurrencyDirham",
  "IconCurrencyDogecoin",
  "IconCurrencyDollarAustralian",
  "IconCurrencyDollarBrunei",
  "IconCurrencyDollarCanadian",
  "IconCurrencyDollarGuyanese",
  "IconCurrencyDollarOff",
  "IconCurrencyDollarSingapore",
  "IconCurrencyDollarZimbabwean",
  "IconCurrencyDollar",
  "IconCurrencyDong",
  "IconCurrencyDram",
  "IconCurrencyEthereum",
  "IconCurrencyEuroOff",
  "IconCurrencyEuro",
  "IconCurrencyForint",
  "IconCurrencyFrank",
  "IconCurrencyGuarani",
  "IconCurrencyHryvnia",
  "IconCurrencyKip",
  "IconCurrencyKroneCzech",
  "IconCurrencyKroneDanish",
  "IconCurrencyKroneSwedish",
  "IconCurrencyLari",
  "IconCurrencyLeu",
  "IconCurrencyLira",
  "IconCurrencyLitecoin",
  "IconCurrencyLyd",
  "IconCurrencyManat",
  "IconContainer",
  "IconContrast2Off",
  "IconContrast2",
  "IconContrastOff",
  "IconContrast",
  "IconCooker",
  "IconCookieMan",
  "IconCookieOff",
  "IconCookie",
  "IconCopyOff",
  "IconCopy",
  "IconCopyleftFilled",
  "IconCopyleftOff",
  "IconCopyleft",
  "IconCopyrightFilled",
  "IconCopyrightOff",
  "IconCopyright",
  "IconCornerDownLeftDouble",
  "IconCornerDownLeft",
  "IconCornerDownRightDouble",
  "IconCornerDownRight",
  "IconCornerLeftDownDouble",
  "IconCornerLeftDown",
  "IconCornerLeftUpDouble",
  "IconCornerLeftUp",
  "IconCornerRightDownDouble",
  "IconCornerRightDown",
  "IconCornerRightUpDouble",
  "IconCornerRightUp",
  "IconCornerUpLeftDouble",
  "IconCornerUpLeft",
  "IconCornerUpRightDouble",
  "IconCornerUpRight",
  "IconCpu2",
  "IconCpuOff",
  "IconCpu",
  "IconCraneOff",
  "IconCrane",
  "IconCreativeCommonsBy",
  "IconCreativeCommonsNc",
  "IconCreativeCommonsNd",
  "IconCreativeCommonsOff",
  "IconCreativeCommonsSa",
  "IconCreativeCommonsZero",
  "IconCreativeCommons",
  "IconCreditCardOff",
  "IconCreditCard",
  "IconCricket",
  "IconCrop",
  "IconCrossFilled",
  "IconClover",
  "IconClubsFilled",
  "IconClubs",
  "IconCodeAsterix",
  "IconCodeCircle2",
  "IconCodeCircle",
  "IconCodeDots",
  "IconCodeMinus",
  "IconCodeOff",
  "IconCodePlus",
  "IconCode",
  "IconCoffeeOff",
  "IconCoffee",
  "IconCoffin",
  "IconCoinBitcoin",
  "IconCoinEuro",
  "IconCoinMonero",
  "IconCoinOff",
  "IconCoinPound",
  "IconCoinRupee",
  "IconCoinYen",
  "IconCoinYuan",
  "IconCoin",
  "IconCoins",
  "IconColorFilter",
  "IconColorPickerOff",
  "IconColorPicker",
  "IconColorSwatchOff",
  "IconColorSwatch",
  "IconColumnInsertLeft",
  "IconColumnInsertRight",
  "IconColumns1",
  "IconColumns2",
  "IconColumns3",
  "IconColumnsOff",
  "IconColumns",
  "IconComet",
  "IconCommandOff",
  "IconCommand",
  "IconCompassOff",
  "IconCompass",
  "IconComponentsOff",
  "IconComponents",
  "IconCone2",
  "IconConeOff",
  "IconCone",
  "IconConfettiOff",
  "IconConfetti",
  "IconConfucius",
  "IconContainerOff",
  "IconClick",
  "IconClipboardCheck",
  "IconClipboardCopy",
  "IconClipboardData",
  "IconClipboardHeart",
  "IconClipboardList",
  "IconClipboardOff",
  "IconClipboardPlus",
  "IconClipboardText",
  "IconClipboardTypography",
  "IconClipboardX",
  "IconClipboard",
  "IconClock2",
  "IconClockCancel",
  "IconClockEdit",
  "IconClockFilled",
  "IconClockHour1",
  "IconClockHour10",
  "IconClockHour11",
  "IconClockHour12",
  "IconClockHour2",
  "IconClockHour3",
  "IconClockHour4",
  "IconClockHour5",
  "IconClockHour6",
  "IconClockHour7",
  "IconClockHour8",
  "IconClockHour9",
  "IconClockOff",
  "IconClockPause",
  "IconClockPlay",
  "IconClockRecord",
  "IconClockStop",
  "IconClock",
  "IconClothesRackOff",
  "IconClothesRack",
  "IconCloudComputing",
  "IconCloudDataConnection",
  "IconCloudDownload",
  "IconCloudFilled",
  "IconCloudFog",
  "IconCloudLockOpen",
  "IconCloudLock",
  "IconCloudOff",
  "IconCloudRain",
  "IconCloudSnow",
  "IconCloudStorm",
  "IconCloudUpload",
  "IconCloud",
  "IconClover2",
  "IconCircleLetterU",
  "IconCircleLetterV",
  "IconCircleLetterW",
  "IconCircleLetterX",
  "IconCircleLetterY",
  "IconCircleLetterZ",
  "IconCircleMinus",
  "IconCircleNumber0",
  "IconCircleNumber1",
  "IconCircleNumber2",
  "IconCircleNumber3",
  "IconCircleNumber4",
  "IconCircleNumber5",
  "IconCircleNumber6",
  "IconCircleNumber7",
  "IconCircleNumber8",
  "IconCircleNumber9",
  "IconCircleOff",
  "IconCirclePlus",
  "IconCircleRectangleOff",
  "IconCircleRectangle",
  "IconCircleSquare",
  "IconCircleTriangle",
  "IconCircleXFilled",
  "IconCircleX",
  "IconCircle",
  "IconCirclesFilled",
  "IconCirclesRelation",
  "IconCircles",
  "IconCircuitAmmeter",
  "IconCircuitBattery",
  "IconCircuitBulb",
  "IconCircuitCapacitorPolarized",
  "IconCircuitCapacitor",
  "IconCircuitCellPlus",
  "IconCircuitCell",
  "IconCircuitChangeover",
  "IconCircuitDiodeZener",
  "IconCircuitDiode",
  "IconCircuitGroundDigital",
  "IconCircuitGround",
  "IconCircuitInductor",
  "IconCircuitMotor",
  "IconCircuitPushbutton",
  "IconCircuitResistor",
  "IconCircuitSwitchClosed",
  "IconCircuitSwitchOpen",
  "IconCircuitVoltmeter",
  "IconClearAll",
  "IconClearFormatting",
  "IconCircleArrowUpFilled",
  "IconCircleArrowUpLeftFilled",
  "IconCircleArrowUpLeft",
  "IconCircleArrowUpRightFilled",
  "IconCircleArrowUpRight",
  "IconCircleArrowUp",
  "IconCircleCaretDown",
  "IconCircleCaretLeft",
  "IconCircleCaretRight",
  "IconCircleCaretUp",
  "IconCircleCheckFilled",
  "IconCircleCheck",
  "IconCircleChevronDown",
  "IconCircleChevronLeft",
  "IconCircleChevronRight",
  "IconCircleChevronUp",
  "IconCircleChevronsDown",
  "IconCircleChevronsLeft",
  "IconCircleChevronsRight",
  "IconCircleChevronsUp",
  "IconCircleDashed",
  "IconCircleDotFilled",
  "IconCircleDot",
  "IconCircleDotted",
  "IconCircleFilled",
  "IconCircleHalf2",
  "IconCircleHalfVertical",
  "IconCircleHalf",
  "IconCircleKeyFilled",
  "IconCircleKey",
  "IconCircleLetterA",
  "IconCircleLetterB",
  "IconCircleLetterC",
  "IconCircleLetterD",
  "IconCircleLetterE",
  "IconCircleLetterF",
  "IconCircleLetterG",
  "IconCircleLetterH",
  "IconCircleLetterI",
  "IconCircleLetterJ",
  "IconCircleLetterK",
  "IconCircleLetterL",
  "IconCircleLetterM",
  "IconCircleLetterN",
  "IconCircleLetterO",
  "IconCircleLetterP",
  "IconCircleLetterQ",
  "IconCircleLetterR",
  "IconCircleLetterS",
  "IconCircleLetterT",
  "IconChessBishop",
  "IconChessFilled",
  "IconChessKingFilled",
  "IconChessKing",
  "IconChessKnightFilled",
  "IconChessKnight",
  "IconChessQueenFilled",
  "IconChessQueen",
  "IconChessRookFilled",
  "IconChessRook",
  "IconChess",
  "IconChevronDownLeft",
  "IconChevronDownRight",
  "IconChevronDown",
  "IconChevronLeft",
  "IconChevronRight",
  "IconChevronUpLeft",
  "IconChevronUpRight",
  "IconChevronUp",
  "IconChevronsDownLeft",
  "IconChevronsDownRight",
  "IconChevronsDown",
  "IconChevronsLeft",
  "IconChevronsRight",
  "IconChevronsUpLeft",
  "IconChevronsUpRight",
  "IconChevronsUp",
  "IconChisel",
  "IconChristmasTreeOff",
  "IconChristmasTree",
  "IconCircle0Filled",
  "IconCircle1Filled",
  "IconCircle2Filled",
  "IconCircle3Filled",
  "IconCircle4Filled",
  "IconCircle5Filled",
  "IconCircle6Filled",
  "IconCircle7Filled",
  "IconCircle8Filled",
  "IconCircle9Filled",
  "IconCircleArrowDownFilled",
  "IconCircleArrowDownLeftFilled",
  "IconCircleArrowDownLeft",
  "IconCircleArrowDownRightFilled",
  "IconCircleArrowDownRight",
  "IconCircleArrowDown",
  "IconCircleArrowLeftFilled",
  "IconCircleArrowLeft",
  "IconCircleArrowRightFilled",
  "IconCircleArrowRight",
  "IconChalkboard",
  "IconChargingPile",
  "IconChartArcs3",
  "IconChartArcs",
  "IconChartAreaFilled",
  "IconChartAreaLineFilled",
  "IconChartAreaLine",
  "IconChartArea",
  "IconChartArrowsVertical",
  "IconChartArrows",
  "IconChartBarOff",
  "IconChartBar",
  "IconChartBubbleFilled",
  "IconChartBubble",
  "IconChartCandleFilled",
  "IconChartCandle",
  "IconChartCircles",
  "IconChartDonut2",
  "IconChartDonut3",
  "IconChartDonut4",
  "IconChartDonutFilled",
  "IconChartDonut",
  "IconChartDots2",
  "IconChartDots3",
  "IconChartDots",
  "IconChartGridDots",
  "IconChartHistogram",
  "IconChartInfographic",
  "IconChartLine",
  "IconChartPie2",
  "IconChartPie3",
  "IconChartPie4",
  "IconChartPieFilled",
  "IconChartPieOff",
  "IconChartPie",
  "IconChartPpf",
  "IconChartRadar",
  "IconChartSankey",
  "IconChartTreemap",
  "IconCheck",
  "IconCheckbox",
  "IconChecklist",
  "IconChecks",
  "IconCheckupList",
  "IconCheese",
  "IconChefHatOff",
  "IconChefHat",
  "IconCherryFilled",
  "IconCherry",
  "IconChessBishopFilled",
  "IconCamera",
  "IconCampfire",
  "IconCandle",
  "IconCandyOff",
  "IconCandy",
  "IconCane",
  "IconCannabis",
  "IconCaptureOff",
  "IconCapture",
  "IconCarCrane",
  "IconCarCrash",
  "IconCarOff",
  "IconCarTurbine",
  "IconCar",
  "IconCaravan",
  "IconCardboardsOff",
  "IconCardboards",
  "IconCards",
  "IconCaretDown",
  "IconCaretLeft",
  "IconCaretRight",
  "IconCaretUp",
  "IconCarouselHorizontal",
  "IconCarouselVertical",
  "IconCarrotOff",
  "IconCarrot",
  "IconCashBanknoteOff",
  "IconCashBanknote",
  "IconCashOff",
  "IconCash",
  "IconCastOff",
  "IconCast",
  "IconCat",
  "IconCategory2",
  "IconCategory",
  "IconCeOff",
  "IconCe",
  "IconCellSignal1",
  "IconCellSignal2",
  "IconCellSignal3",
  "IconCellSignal4",
  "IconCellSignal5",
  "IconCellSignalOff",
  "IconCell",
  "IconCertificate2Off",
  "IconCertificate2",
  "IconCertificateOff",
  "IconCertificate",
  "IconChairDirector",
  "IconChalkboardOff",
  "IconBuildingBroadcastTower",
  "IconBuildingCarousel",
  "IconBuildingCastle",
  "IconBuildingChurch",
  "IconBuildingCircus",
  "IconBuildingCommunity",
  "IconBuildingCottage",
  "IconBuildingEstate",
  "IconBuildingFactory2",
  "IconBuildingFactory",
  "IconBuildingFortress",
  "IconBuildingHospital",
  "IconBuildingLighthouse",
  "IconBuildingMonument",
  "IconBuildingPavilion",
  "IconBuildingSkyscraper",
  "IconBuildingStadium",
  "IconBuildingStore",
  "IconBuildingTunnel",
  "IconBuildingWarehouse",
  "IconBuildingWindTurbine",
  "IconBuilding",
  "IconBulbFilled",
  "IconBulbOff",
  "IconBulb",
  "IconBulldozer",
  "IconBusOff",
  "IconBusStop",
  "IconBus",
  "IconBusinessplan",
  "IconButterfly",
  "IconCactusOff",
  "IconCactus",
  "IconCakeOff",
  "IconCake",
  "IconCalculatorOff",
  "IconCalculator",
  "IconCalendarDue",
  "IconCalendarEvent",
  "IconCalendarMinus",
  "IconCalendarOff",
  "IconCalendarPlus",
  "IconCalendarStats",
  "IconCalendarTime",
  "IconCalendar",
  "IconCameraMinus",
  "IconCameraOff",
  "IconCameraPlus",
  "IconCameraRotate",
  "IconCameraSelfie",
  "IconBrandWechat",
  "IconBrandWeibo",
  "IconBrandWhatsapp",
  "IconBrandWindows",
  "IconBrandWindy",
  "IconBrandWish",
  "IconBrandWix",
  "IconBrandWordpress",
  "IconBrandXbox",
  "IconBrandXing",
  "IconBrandYahoo",
  "IconBrandYatse",
  "IconBrandYcombinator",
  "IconBrandYoutubeKids",
  "IconBrandYoutube",
  "IconBrandZalando",
  "IconBrandZapier",
  "IconBrandZeit",
  "IconBrandZhihu",
  "IconBrandZoom",
  "IconBrandZulip",
  "IconBrandZwift",
  "IconBreadOff",
  "IconBread",
  "IconBriefcaseOff",
  "IconBriefcase",
  "IconBrightness2",
  "IconBrightnessDown",
  "IconBrightnessHalf",
  "IconBrightnessOff",
  "IconBrightnessUp",
  "IconBrightness",
  "IconBroadcastOff",
  "IconBroadcast",
  "IconBrowserCheck",
  "IconBrowserOff",
  "IconBrowserPlus",
  "IconBrowserX",
  "IconBrowser",
  "IconBrushOff",
  "IconBrush",
  "IconBucketDroplet",
  "IconBucketOff",
  "IconBucket",
  "IconBugOff",
  "IconBug",
  "IconBuildingArch",
  "IconBuildingBank",
  "IconBuildingBridge2",
  "IconBuildingBridge",
  "IconBrandStripe",
  "IconBrandSublimeText",
  "IconBrandSugarizer",
  "IconBrandSupabase",
  "IconBrandSuperhuman",
  "IconBrandSupernova",
  "IconBrandSurfshark",
  "IconBrandSvelte",
  "IconBrandSymfony",
  "IconBrandTabler",
  "IconBrandTailwind",
  "IconBrandTaobao",
  "IconBrandTed",
  "IconBrandTelegram",
  "IconBrandTether",
  "IconBrandThreejs",
  "IconBrandTidal",
  "IconBrandTiktok",
  "IconBrandTinder",
  "IconBrandTopbuzz",
  "IconBrandTorchain",
  "IconBrandToyota",
  "IconBrandTrello",
  "IconBrandTripadvisor",
  "IconBrandTumblr",
  "IconBrandTwilio",
  "IconBrandTwitch",
  "IconBrandTwitter",
  "IconBrandTypescript",
  "IconBrandUber",
  "IconBrandUbuntu",
  "IconBrandUnity",
  "IconBrandUnsplash",
  "IconBrandUpwork",
  "IconBrandValorant",
  "IconBrandVercel",
  "IconBrandVimeo",
  "IconBrandVinted",
  "IconBrandVisa",
  "IconBrandVisualStudio",
  "IconBrandVite",
  "IconBrandVivaldi",
  "IconBrandVk",
  "IconBrandVolkswagen",
  "IconBrandVsco",
  "IconBrandVscode",
  "IconBrandVue",
  "IconBrandWalmart",
  "IconBrandWaze",
  "IconBrandWebflow",
  "IconBrandPatreon",
  "IconBrandPaypal",
  "IconBrandPaypay",
  "IconBrandPeanut",
  "IconBrandPepsi",
  "IconBrandPhp",
  "IconBrandPicsart",
  "IconBrandPinterest",
  "IconBrandPlanetscale",
  "IconBrandPocket",
  "IconBrandPolymer",
  "IconBrandPowershell",
  "IconBrandPrisma",
  "IconBrandProducthunt",
  "IconBrandPushbullet",
  "IconBrandPushover",
  "IconBrandPython",
  "IconBrandQq",
  "IconBrandRadixUi",
  "IconBrandReactNative",
  "IconBrandReact",
  "IconBrandReason",
  "IconBrandReddit",
  "IconBrandRedhat",
  "IconBrandRedux",
  "IconBrandRevolut",
  "IconBrandSafari",
  "IconBrandSamsungpass",
  "IconBrandSass",
  "IconBrandSentry",
  "IconBrandSharik",
  "IconBrandShazam",
  "IconBrandShopee",
  "IconBrandSketch",
  "IconBrandSkype",
  "IconBrandSlack",
  "IconBrandSnapchat",
  "IconBrandSnapseed",
  "IconBrandSnowflake",
  "IconBrandSocketIo",
  "IconBrandSolidjs",
  "IconBrandSoundcloud",
  "IconBrandSpacehey",
  "IconBrandSpotify",
  "IconBrandStackoverflow",
  "IconBrandStackshare",
  "IconBrandSteam",
  "IconBrandStorybook",
  "IconBrandStorytel",
  "IconBrandStrava",
  "IconBrandInstagram",
  "IconBrandIntercom",
  "IconBrandJavascript",
  "IconBrandJuejin",
  "IconBrandKickstarter",
  "IconBrandKotlin",
  "IconBrandLaravel",
  "IconBrandLastfm",
  "IconBrandLinkedin",
  "IconBrandLinktree",
  "IconBrandLinqpad",
  "IconBrandLoom",
  "IconBrandMailgun",
  "IconBrandMantine",
  "IconBrandMastercard",
  "IconBrandMastodon",
  "IconBrandMatrix",
  "IconBrandMcdonalds",
  "IconBrandMedium",
  "IconBrandMercedes",
  "IconBrandMessenger",
  "IconBrandMeta",
  "IconBrandMiniprogram",
  "IconBrandMixpanel",
  "IconBrandMonday",
  "IconBrandMongodb",
  "IconBrandMyOppo",
  "IconBrandMysql",
  "IconBrandNationalGeographic",
  "IconBrandNem",
  "IconBrandNetbeans",
  "IconBrandNeteaseMusic",
  "IconBrandNetflix",
  "IconBrandNexo",
  "IconBrandNextcloud",
  "IconBrandNextjs",
  "IconBrandNordVpn",
  "IconBrandNotion",
  "IconBrandNpm",
  "IconBrandNuxt",
  "IconBrandNytimes",
  "IconBrandOffice",
  "IconBrandOkRu",
  "IconBrandOnedrive",
  "IconBrandOnlyfans",
  "IconBrandOpenSource",
  "IconBrandOpenai",
  "IconBrandOpenvpn",
  "IconBrandOpera",
  "IconBrandPagekit",
  "IconBrandDrupal",
  "IconBrandEdge",
  "IconBrandElastic",
  "IconBrandEmber",
  "IconBrandEnvato",
  "IconBrandEtsy",
  "IconBrandEvernote",
  "IconBrandFacebook",
  "IconBrandFigma",
  "IconBrandFinder",
  "IconBrandFirebase",
  "IconBrandFirefox",
  "IconBrandFiverr",
  "IconBrandFlickr",
  "IconBrandFlightradar24",
  "IconBrandFlipboard",
  "IconBrandFlutter",
  "IconBrandFortnite",
  "IconBrandFoursquare",
  "IconBrandFramerMotion",
  "IconBrandFramer",
  "IconBrandFunimation",
  "IconBrandGatsby",
  "IconBrandGit",
  "IconBrandGithubCopilot",
  "IconBrandGithub",
  "IconBrandGitlab",
  "IconBrandGmail",
  "IconBrandGolang",
  "IconBrandGoogleAnalytics",
  "IconBrandGoogleBigQuery",
  "IconBrandGoogleDrive",
  "IconBrandGoogleFit",
  "IconBrandGoogleHome",
  "IconBrandGoogleOne",
  "IconBrandGooglePhotos",
  "IconBrandGooglePlay",
  "IconBrandGooglePodcasts",
  "IconBrandGoogle",
  "IconBrandGrammarly",
  "IconBrandGraphql",
  "IconBrandGravatar",
  "IconBrandGrindr",
  "IconBrandGuardian",
  "IconBrandGumroad",
  "IconBrandHbo",
  "IconBrandHeadlessui",
  "IconBrandHipchat",
  "IconBrandHtml5",
  "IconBrandInertia",
  "IconBrandBlender",
  "IconBrandBlogger",
  "IconBrandBooking",
  "IconBrandBootstrap",
  "IconBrandBulma",
  "IconBrandBumble",
  "IconBrandBunpo",
  "IconBrandCSharp",
  "IconBrandCake",
  "IconBrandCakephp",
  "IconBrandCampaignmonitor",
  "IconBrandCarbon",
  "IconBrandCashapp",
  "IconBrandChrome",
  "IconBrandCitymapper",
  "IconBrandCodecov",
  "IconBrandCodepen",
  "IconBrandCodesandbox",
  "IconBrandCohost",
  "IconBrandCoinbase",
  "IconBrandComedyCentral",
  "IconBrandCoreos",
  "IconBrandCouchdb",
  "IconBrandCouchsurfing",
  "IconBrandCpp",
  "IconBrandCss3",
  "IconBrandCtemplar",
  "IconBrandCucumber",
  "IconBrandCupra",
  "IconBrandCypress",
  "IconBrandD3",
  "IconBrandDaysCounter",
  "IconBrandDcos",
  "IconBrandDebian",
  "IconBrandDeezer",
  "IconBrandDeliveroo",
  "IconBrandDeno",
  "IconBrandDenodo",
  "IconBrandDeviantart",
  "IconBrandDingtalk",
  "IconBrandDiscord",
  "IconBrandDisney",
  "IconBrandDisqus",
  "IconBrandDjango",
  "IconBrandDocker",
  "IconBrandDoctrine",
  "IconBrandDolbyDigital",
  "IconBrandDouban",
  "IconBrandDribbble",
  "IconBrandDrops",
  "IconBoxMultiple8",
  "IconBoxMultiple9",
  "IconBoxMultiple",
  "IconBoxOff",
  "IconBoxPadding",
  "IconBoxSeam",
  "IconBox",
  "IconBracesOff",
  "IconBraces",
  "IconBracketsContainEnd",
  "IconBracketsContainStart",
  "IconBracketsContain",
  "IconBracketsOff",
  "IconBrackets",
  "IconBraille",
  "IconBrain",
  "IconBrand4chan",
  "IconBrandAbstract",
  "IconBrandAdobe",
  "IconBrandAdonisJs",
  "IconBrandAirbnb",
  "IconBrandAirtable",
  "IconBrandAlgolia",
  "IconBrandAlipay",
  "IconBrandAlpineJs",
  "IconBrandAmazon",
  "IconBrandAmd",
  "IconBrandAmigo",
  "IconBrandAmongUs",
  "IconBrandAndroid",
  "IconBrandAngular",
  "IconBrandAo3",
  "IconBrandAppgallery",
  "IconBrandAppleArcade",
  "IconBrandApplePodcast",
  "IconBrandApple",
  "IconBrandAppstore",
  "IconBrandAsana",
  "IconBrandBackbone",
  "IconBrandBadoo",
  "IconBrandBaidu",
  "IconBrandBandcamp",
  "IconBrandBandlab",
  "IconBrandBeats",
  "IconBrandBehance",
  "IconBrandBilibili",
  "IconBrandBinance",
  "IconBrandBing",
  "IconBrandBitbucket",
  "IconBrandBlackberry",
  "IconBookUpload",
  "IconBook",
  "IconBookmarkOff",
  "IconBookmark",
  "IconBookmarksOff",
  "IconBookmarks",
  "IconBooksOff",
  "IconBooks",
  "IconBorderAll",
  "IconBorderBottom",
  "IconBorderCorners",
  "IconBorderHorizontal",
  "IconBorderInner",
  "IconBorderLeft",
  "IconBorderNone",
  "IconBorderOuter",
  "IconBorderRadius",
  "IconBorderRight",
  "IconBorderSides",
  "IconBorderStyle2",
  "IconBorderStyle",
  "IconBorderTop",
  "IconBorderVertical",
  "IconBottleOff",
  "IconBottle",
  "IconBounceLeft",
  "IconBounceRight",
  "IconBow",
  "IconBowl",
  "IconBoxAlignBottomLeft",
  "IconBoxAlignBottomRight",
  "IconBoxAlignBottom",
  "IconBoxAlignLeft",
  "IconBoxAlignRight",
  "IconBoxAlignTopLeft",
  "IconBoxAlignTopRight",
  "IconBoxAlignTop",
  "IconBoxMargin",
  "IconBoxModel2Off",
  "IconBoxModel2",
  "IconBoxModelOff",
  "IconBoxModel",
  "IconBoxMultiple0",
  "IconBoxMultiple1",
  "IconBoxMultiple2",
  "IconBoxMultiple3",
  "IconBoxMultiple4",
  "IconBoxMultiple5",
  "IconBoxMultiple6",
  "IconBoxMultiple7",
  "IconBellMinusFilled",
  "IconBellMinus",
  "IconBellOff",
  "IconBellPlusFilled",
  "IconBellPlus",
  "IconBellRinging2Filled",
  "IconBellRinging2",
  "IconBellRingingFilled",
  "IconBellRinging",
  "IconBellSchool",
  "IconBellXFilled",
  "IconBellX",
  "IconBellZFilled",
  "IconBellZ",
  "IconBell",
  "IconBeta",
  "IconBible",
  "IconBikeOff",
  "IconBike",
  "IconBinaryOff",
  "IconBinaryTree2",
  "IconBinaryTree",
  "IconBinary",
  "IconBiohazardOff",
  "IconBiohazard",
  "IconBlade",
  "IconBleachChlorine",
  "IconBleachNoChlorine",
  "IconBleachOff",
  "IconBleach",
  "IconBlockquote",
  "IconBluetoothConnected",
  "IconBluetoothOff",
  "IconBluetoothX",
  "IconBluetooth",
  "IconBlurOff",
  "IconBlur",
  "IconBmp",
  "IconBoldOff",
  "IconBold",
  "IconBoltOff",
  "IconBolt",
  "IconBomb",
  "IconBoneOff",
  "IconBone",
  "IconBongOff",
  "IconBong",
  "IconBook2",
  "IconBookDownload",
  "IconBookOff",
  "IconBallBasketball",
  "IconBallBowling",
  "IconBallFootballOff",
  "IconBallFootball",
  "IconBallTennis",
  "IconBallVolleyball",
  "IconBalloonOff",
  "IconBalloon",
  "IconBallpenOff",
  "IconBallpen",
  "IconBan",
  "IconBandageOff",
  "IconBandage",
  "IconBarbellOff",
  "IconBarbell",
  "IconBarcodeOff",
  "IconBarcode",
  "IconBarrelOff",
  "IconBarrel",
  "IconBarrierBlockOff",
  "IconBarrierBlock",
  "IconBaseline",
  "IconBasketOff",
  "IconBasket",
  "IconBat",
  "IconBathFilled",
  "IconBathOff",
  "IconBath",
  "IconBattery1Filled",
  "IconBattery1",
  "IconBattery2Filled",
  "IconBattery2",
  "IconBattery3Filled",
  "IconBattery3",
  "IconBattery4Filled",
  "IconBattery4",
  "IconBatteryAutomotive",
  "IconBatteryCharging2",
  "IconBatteryCharging",
  "IconBatteryEco",
  "IconBatteryFilled",
  "IconBatteryOff",
  "IconBattery",
  "IconBeachOff",
  "IconBeach",
  "IconBedOff",
  "IconBed",
  "IconBeerOff",
  "IconBeer",
  "IconBellFilled",
  "IconArticle",
  "IconAspectRatioOff",
  "IconAspectRatio",
  "IconAssemblyOff",
  "IconAssembly",
  "IconAsset",
  "IconAsteriskSimple",
  "IconAsterisk",
  "IconAtOff",
  "IconAt",
  "IconAtom2Filled",
  "IconAtom2",
  "IconAtomOff",
  "IconAtom",
  "IconAugmentedReality2",
  "IconAugmentedRealityOff",
  "IconAugmentedReality",
  "IconAwardFilled",
  "IconAwardOff",
  "IconAward",
  "IconAxe",
  "IconAxisX",
  "IconAxisY",
  "IconBabyBottle",
  "IconBabyCarriage",
  "IconBackhoe",
  "IconBackpackOff",
  "IconBackpack",
  "IconBackspace",
  "IconBadge3d",
  "IconBadge4k",
  "IconBadge8k",
  "IconBadgeAd",
  "IconBadgeAr",
  "IconBadgeCc",
  "IconBadgeFilled",
  "IconBadgeHd",
  "IconBadgeOff",
  "IconBadgeSd",
  "IconBadgeTm",
  "IconBadgeVo",
  "IconBadgeVr",
  "IconBadgeWc",
  "IconBadge",
  "IconBadgesOff",
  "IconBadges",
  "IconBaguette",
  "IconBallAmericanFootballOff",
  "IconBallAmericanFootball",
  "IconBallBaseball",
  "IconArrowUp",
  "IconArrowWaveLeftDown",
  "IconArrowWaveLeftUp",
  "IconArrowWaveRightDown",
  "IconArrowWaveRightUp",
  "IconArrowZigZag",
  "IconArrowsCross",
  "IconArrowsDiagonal2",
  "IconArrowsDiagonalMinimize2",
  "IconArrowsDiagonalMinimize",
  "IconArrowsDiagonal",
  "IconArrowsDiff",
  "IconArrowsDoubleNeSw",
  "IconArrowsDoubleNwSe",
  "IconArrowsDoubleSeNw",
  "IconArrowsDoubleSwNe",
  "IconArrowsDownUp",
  "IconArrowsDown",
  "IconArrowsExchange2",
  "IconArrowsExchange",
  "IconArrowsHorizontal",
  "IconArrowsJoin2",
  "IconArrowsJoin",
  "IconArrowsLeftDown",
  "IconArrowsLeftRight",
  "IconArrowsLeft",
  "IconArrowsMaximize",
  "IconArrowsMinimize",
  "IconArrowsMoveHorizontal",
  "IconArrowsMoveVertical",
  "IconArrowsMove",
  "IconArrowsRandom",
  "IconArrowsRightDown",
  "IconArrowsRightLeft",
  "IconArrowsRight",
  "IconArrowsShuffle2",
  "IconArrowsShuffle",
  "IconArrowsSort",
  "IconArrowsSplit2",
  "IconArrowsSplit",
  "IconArrowsTransferDown",
  "IconArrowsTransferUp",
  "IconArrowsUpDown",
  "IconArrowsUpLeft",
  "IconArrowsUpRight",
  "IconArrowsUp",
  "IconArrowsVertical",
  "IconArtboardOff",
  "IconArtboard",
  "IconArticleOff",
  "IconArrowLeft",
  "IconArrowLoopLeft2",
  "IconArrowLoopLeft",
  "IconArrowLoopRight2",
  "IconArrowLoopRight",
  "IconArrowMergeBoth",
  "IconArrowMergeLeft",
  "IconArrowMergeRight",
  "IconArrowMerge",
  "IconArrowMoveDown",
  "IconArrowMoveLeft",
  "IconArrowMoveRight",
  "IconArrowMoveUp",
  "IconArrowNarrowDown",
  "IconArrowNarrowLeft",
  "IconArrowNarrowRight",
  "IconArrowNarrowUp",
  "IconArrowRampLeft2",
  "IconArrowRampLeft3",
  "IconArrowRampLeft",
  "IconArrowRampRight2",
  "IconArrowRampRight3",
  "IconArrowRampRight",
  "IconArrowRightBar",
  "IconArrowRightCircle",
  "IconArrowRightRhombus",
  "IconArrowRightSquare",
  "IconArrowRightTail",
  "IconArrowRight",
  "IconArrowRotaryFirstLeft",
  "IconArrowRotaryFirstRight",
  "IconArrowRotaryLastLeft",
  "IconArrowRotaryLastRight",
  "IconArrowRotaryLeft",
  "IconArrowRotaryRight",
  "IconArrowRotaryStraight",
  "IconArrowRoundaboutLeft",
  "IconArrowRoundaboutRight",
  "IconArrowSharpTurnLeft",
  "IconArrowSharpTurnRight",
  "IconArrowTopCircle",
  "IconArrowUpBar",
  "IconArrowUpCircle",
  "IconArrowUpLeftCircle",
  "IconArrowUpLeft",
  "IconArrowUpRhombus",
  "IconArrowUpRightCircle",
  "IconArrowUpRight",
  "IconArrowUpSquare",
  "IconArrowUpTail",
  "IconArrowBearRight2",
  "IconArrowBearRight",
  "IconArrowBigDownFilled",
  "IconArrowBigDownLineFilled",
  "IconArrowBigDownLine",
  "IconArrowBigDownLinesFilled",
  "IconArrowBigDownLines",
  "IconArrowBigDown",
  "IconArrowBigLeftFilled",
  "IconArrowBigLeftLineFilled",
  "IconArrowBigLeftLine",
  "IconArrowBigLeftLinesFilled",
  "IconArrowBigLeftLines",
  "IconArrowBigLeft",
  "IconArrowBigRightFilled",
  "IconArrowBigRightLineFilled",
  "IconArrowBigRightLine",
  "IconArrowBigRightLinesFilled",
  "IconArrowBigRightLines",
  "IconArrowBigRight",
  "IconArrowBigUpFilled",
  "IconArrowBigUpLineFilled",
  "IconArrowBigUpLine",
  "IconArrowBigUpLinesFilled",
  "IconArrowBigUpLines",
  "IconArrowBigUp",
  "IconArrowBounce",
  "IconArrowCurveLeft",
  "IconArrowCurveRight",
  "IconArrowDownBar",
  "IconArrowDownCircle",
  "IconArrowDownLeftCircle",
  "IconArrowDownLeft",
  "IconArrowDownRhombus",
  "IconArrowDownRightCircle",
  "IconArrowDownRight",
  "IconArrowDownSquare",
  "IconArrowDownTail",
  "IconArrowDown",
  "IconArrowFork",
  "IconArrowForwardUp",
  "IconArrowForward",
  "IconArrowGuide",
  "IconArrowIteration",
  "IconArrowLeftBar",
  "IconArrowLeftCircle",
  "IconArrowLeftRhombus",
  "IconArrowLeftRight",
  "IconArrowLeftSquare",
  "IconArrowLeftTail",
  "IconAntennaBars1",
  "IconAntennaBars2",
  "IconAntennaBars3",
  "IconAntennaBars4",
  "IconAntennaBars5",
  "IconAntennaBarsOff",
  "IconAntennaOff",
  "IconAntenna",
  "IconApertureOff",
  "IconAperture",
  "IconApiAppOff",
  "IconApiApp",
  "IconApiOff",
  "IconApi",
  "IconAppWindowFilled",
  "IconAppWindow",
  "IconApple",
  "IconAppsFilled",
  "IconAppsOff",
  "IconApps",
  "IconArchiveOff",
  "IconArchive",
  "IconArmchair2Off",
  "IconArmchair2",
  "IconArmchairOff",
  "IconArmchair",
  "IconArrowAutofitContentFilled",
  "IconArrowAutofitContent",
  "IconArrowAutofitDown",
  "IconArrowAutofitHeight",
  "IconArrowAutofitLeft",
  "IconArrowAutofitRight",
  "IconArrowAutofitUp",
  "IconArrowAutofitWidth",
  "IconArrowBackUp",
  "IconArrowBack",
  "IconArrowBadgeDown",
  "IconArrowBadgeLeft",
  "IconArrowBadgeRight",
  "IconArrowBadgeUp",
  "IconArrowBarDown",
  "IconArrowBarLeft",
  "IconArrowBarRight",
  "IconArrowBarToDown",
  "IconArrowBarToLeft",
  "IconArrowBarToRight",
  "IconArrowBarToUp",
  "IconArrowBarUp",
  "IconArrowBearLeft2",
  "IconArrowBearLeft",
  "IconAlbum",
  "IconAlertCircleFilled",
  "IconAlertCircle",
  "IconAlertOctagonFilled",
  "IconAlertOctagon",
  "IconAlertTriangleFilled",
  "IconAlertTriangle",
  "IconAlienFilled",
  "IconAlien",
  "IconAlignBoxBottomCenterFilled",
  "IconAlignBoxBottomCenter",
  "IconAlignBoxBottomLeftFilled",
  "IconAlignBoxBottomLeft",
  "IconAlignBoxBottomRightFilled",
  "IconAlignBoxBottomRight",
  "IconAlignBoxCenterMiddle",
  "IconAlignBoxLeftBottomFilled",
  "IconAlignBoxLeftBottom",
  "IconAlignBoxLeftMiddleFilled",
  "IconAlignBoxLeftMiddle",
  "IconAlignBoxLeftTopFilled",
  "IconAlignBoxLeftTop",
  "IconAlignBoxRightBottomFilled",
  "IconAlignBoxRightBottom",
  "IconAlignBoxRightMiddle",
  "IconAlignBoxRightTopFilled",
  "IconAlignBoxRightTop",
  "IconAlignBoxTopCenterFilled",
  "IconAlignBoxTopCenter",
  "IconAlignBoxTopLeftFilled",
  "IconAlignBoxTopLeft",
  "IconAlignBoxTopRightFilled",
  "IconAlignBoxTopRight",
  "IconAlignCenter",
  "IconAlignJustified",
  "IconAlignLeft",
  "IconAlignRight",
  "IconAlpha",
  "IconAlphabetCyrillic",
  "IconAlphabetGreek",
  "IconAlphabetLatin",
  "IconAmbulance",
  "IconAmpersand",
  "IconAnalyzeFilled",
  "IconAnalyzeOff",
  "IconAnalyze",
  "IconAnchorOff",
  "IconAnchor",
  "IconAngle",
  "IconAnkh",
  "Icon123",
  "Icon24Hours",
  "Icon2fa",
  "Icon360View",
  "Icon360",
  "Icon3dCubeSphereOff",
  "Icon3dCubeSphere",
  "Icon3dRotate",
  "IconAB2",
  "IconABOff",
  "IconAB",
  "IconAbacusOff",
  "IconAbacus",
  "IconAbc",
  "IconAccessPointOff",
  "IconAccessPoint",
  "IconAccessibleOffFilled",
  "IconAccessibleOff",
  "IconAccessible",
  "IconActivityHeartbeat",
  "IconActivity",
  "IconAd2",
  "IconAdCircleOff",
  "IconAdCircle",
  "IconAdFilled",
  "IconAdOff",
  "IconAd",
  "IconAddressBookOff",
  "IconAddressBook",
  "IconAdjustmentsAlt",
  "IconAdjustmentsFilled",
  "IconAdjustmentsHorizontal",
  "IconAdjustmentsOff",
  "IconAdjustments",
  "IconAerialLift",
  "IconAffiliateFilled",
  "IconAffiliate",
  "IconAirBalloon",
  "IconAirConditioningDisabled",
  "IconAirConditioning",
  "IconAlarmFilled",
  "IconAlarmMinusFilled",
  "IconAlarmMinus",
  "IconAlarmOff",
  "IconAlarmPlusFilled",
  "IconAlarmPlus",
  "IconAlarmSnoozeFilled",
  "IconAlarmSnooze",
  "IconAlarm",
  "IconAlbumOff"
];

// src/constants/image.ts
var carDamageModels = [
  "car_damage_v2_low_threshold",
  "rekognition_custom_labels_car_damage",
  "mmdetection_cardd",
  "sangle_car_damage_detection",
  "roboflow_bbox_damage_detection"
];

// src/constants/timezones.ts
var timezones = [
  {
    value: "Central Brazilian Standard Time",
    abbr: "CBST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Cuiaba",
    utc: ["America/Campo_Grande", "America/Cuiaba"]
  },
  {
    value: "Argentina Standard Time",
    abbr: "AST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Buenos Aires",
    utc: ["America/Argentina/Buenos_Aires"]
  },
  {
    value: "Paraguay Standard Time",
    abbr: "PYT",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Asuncion",
    utc: ["America/Asuncion"]
  }
];
var timezonesOptions = timezones.flatMap((timezone) => timezone.utc);

// src/constants/job.ts
var jobStatus = [
  "queued",
  "working",
  "failed",
  "completed",
  "killed"
];

// src/constants/validation.ts
var customValidationDefinitionTypes = ["object", "ocr"];

// src/constants/decimal.ts
var DEFAULT_DECIMAL_PRECISION = 2;

// src/constants/company.ts
var companyVariableNames = [
  "inspection_app_origin",
  "source_email",
  "wsp_cellphone",
  "sms_cellphone",
  "car_damage_model_redundancy",
  "template_logic_rules",
  "cellphone_inspection"
];

// src/constants/input.ts
var inputTypesWithOptions = ["select", "multiselect", "checkboxGroup"];
var inputStages = ["create", "update"];
var inspectionStages = inputStages;
var templateInputValidationTypes = [
  "maxDigits",
  "minDigits",
  "minDate",
  "maxDate",
  "maxDuration",
  "required",
  "email",
  "minLength",
  "maxLength",
  "minimum",
  "maximum"
];

// src/constants/inspection.ts
var inspectionTypes = [
  "custom",
  "goods",
  "people",
  "machinery",
  "car",
  "moto",
  "bike",
  "cellphone"
];
var sexTypes = ["male", "female", "not_applicable"];
var inspectionStatus = [
  "created",
  "started",
  "blocked",
  "processing",
  "completed"
];
var VIDEO_MAX_DURATION = 30;
var inspectionLimits = {
  images: {
    extra: 10
  },
  input: {
    options: 50
  },
  files: {
    size: 16
  },
  media: {
    duration: {
      audio: 120,
      video: 30
    },
    size: {
      video: 100
    }
  }
};
var eventTrackingScopes = [
  "inspection",
  "good_item",
  "checkout",
  "feedback_comment",
  "feedback_rating",
  "feedback",
  "custom_section",
  "document",
  "custom_image",
  "identity_image",
  "identity_images_section",
  "identity_inputs",
  "onboarding_sliders",
  "checkout_summary",
  "welcome",
  "terms_and_conditions",
  "damage_declaration",
  "product_inputs",
  "product_base_inputs",
  "product_custom_inputs",
  "identity_base_inputs",
  "identity_custom_inputs",
  "product_image",
  "custom_inputs",
  "identity_inputs"
];
var eventTrackingActions = [
  "sign",
  "finish",
  "add",
  "remove",
  "submit",
  "visit",
  "open",
  "complete",
  "accept",
  "upload",
  "start"
];
var inspectionClientRequestTrackingKey = [
  "unlockRequest",
  "recreateRequest"
];

// src/constants/inspection-products-meta.ts
var colors = [
  "yellow",
  "blue",
  "white",
  "gold",
  "grey",
  "orange",
  "black",
  "red",
  "green"
];
var vehicleUses = [
  "OFICIAL",
  "PRIVADO",
  "PARTICULAR",
  "TAXI",
  "REMIS",
  "AMBULANCIA",
  "ESCUELA DE CONDUCIR",
  "TRANSPORTE ESCOLAR O DE MENORES",
  "TRANSPORTE DE PASAJEROS INTERJURISDICCIONAL",
  "TRANSPORTE DE PASAJEROS INTRAJURISDICCIONAL",
  "TRANSPORTE DE CARGA INTERJURISDICCIONAL",
  "TRANSPORTE DE CARGA INTRAJURISDICCIONAL",
  "TRANSPORTE DE CARGA DE SUSTANCIAS PELIGROSAS",
  "SERVICIO DE ALQUILER SIN CONDUCTOR"
];

// src/constants/condition.ts
var booleanInputs = ["check", "dialogDecision"];
var conditionalsOperatorsRules = {
  greaterThan: ["numeric", "date"],
  lessThan: ["numeric", "date"],
  equals: [
    "dialogDecision",
    "check",
    "select",
    "text",
    "textarea",
    "numeric",
    "date"
  ],
  notEquals: [
    "dialogDecision",
    "check",
    "select",
    "text",
    "textarea",
    "numeric"
  ],
  contains: ["multiselect", "text", "textarea", "checkboxGroup"],
  notContains: ["multiselect", "text", "textarea", "checkboxGroup"],
  startsWith: ["text", "textarea"],
  notStartsWith: ["text", "textarea"],
  endsWith: ["text", "textarea"],
  notEndsWith: ["text", "textarea"],
  isEmpty: inputTypes.filter(
    (e) => !["checkboxGroup", "multiselect", ...booleanInputs].includes(e)
  ),
  isFilled: inputTypes.filter(
    (e) => !["checkboxGroup", "multiselect", ...booleanInputs].includes(e)
  )
};
var conditionalOperatorsList = Object.keys(conditionalsOperatorsRules);
var templateConditionTypes = ["existence"];
var templateConditionTermOperators = [
  "greaterThan",
  "lessThan",
  "equals",
  "notEquals",
  "contains",
  "notContains",
  "startsWith",
  "notStartsWith",
  "endsWith",
  "notEndsWith",
  "isEmpty",
  "isFilled"
];
var conditionTermTargetsType = [
  "input",
  "variable",
  "constant",
  "image"
];
var revertActionDispatches = {
  hide: "show",
  show: "hide"
};
var conditionActionDispatches = [
  "replace",
  "add",
  "sub",
  "divide",
  "multiply",
  "show",
  "hide"
];
var assetActionReferences = ["hide", "show"];
var variableActionReferences = [
  "variable",
  "constant",
  "input",
  "image"
];
var conditionTermTypes = ["variable", "input", "constant"];
var variableNumericDispatches = [
  "add",
  "sub",
  "multiply",
  "divide"
];
var variableTextDispatches = [
  "replace"
];
var constantDataType2VariableType = {
  string: "text",
  number: "numeric"
};
var templateConditionLinks = ["all", "some"];

// src/constants/dataset.ts
var datasetQueryEntities = ["car", "moto", "mobile", "laptop"];

// src/constants/signature.ts
var signatureModes = ["draw", "type"];

// src/constants/inspection-template-input.ts
var inspectionTemplateInputHintTypes = ["productInspection"];

// src/constants/document.ts
var documentFieldConditions = ["selected", "notSelected"];
var documentFieldTypes = ["signature"];
var documentFieldBoxSize = { width: 150, height: 32 };
var documentFieldMaxWidth = documentFieldBoxSize.width * 4;
var documentFieldMaxHeight = documentFieldBoxSize.height * 4;
var documentCanvasBoxSize = {
  width: 632,
  height: 817
};
var documentFieldFontSizeLimits = {
  max: 20,
  default: 14,
  min: 6
};

// src/constants/variable.ts
var VARIABLE_NAME_MAX_LENGTH = 255;

// src/constants/file.ts
var fileTypes = [
  "audio",
  "image",
  "video",
  "pdf",
  "document",
  "other"
];
var presignResourceTypes = ["input", "document"];

// src/constants/machinery.ts
var machineryTypes = [
  "tractor",
  "combine_harvester",
  "sprayer",
  "seeder",
  "fumigator",
  "baler",
  "round_baler",
  "paver",
  "leveler",
  "crane",
  "excavator",
  "carts",
  "grader",
  "loader",
  "scrapers",
  "compacting_machine",
  "machine_soil_treatment",
  "forklift",
  "motor_vehicle_with_coupling_device",
  "fertilizer",
  "backhoe",
  "bulldozer",
  "compactor",
  "hidrogua",
  "trencher",
  "mixer",
  "hopper",
  "mechanical_shovel"
];
var machineryPurposes = ["Agr\xEDcola", "Vial", "Industrial"];
var machineryUses = ["OFICIAL", "PRIVADO", "P\xDABLICO"];

// src/constants/inferred-entities.ts
var inferredEntities = ["passport"];

// src/constants/countries-iso3.ts
var countriesIso3 = [
  {
    code: "AFG",
    name: "Afghanistan",
    eu: false
  },
  {
    code: "ALA",
    name: "\xC5land Islands",
    eu: false
  },
  {
    code: "ALB",
    name: "Albania",
    eu: false
  },
  {
    code: "DZA",
    name: "Algeria",
    eu: false
  },
  {
    code: "ASM",
    name: "American Samoa",
    eu: false
  },
  {
    code: "AND",
    name: "Andorra",
    eu: false
  },
  {
    code: "AGO",
    name: "Angola",
    eu: false
  },
  {
    code: "AIA",
    name: "Anguilla",
    eu: false
  },
  {
    code: "ATA",
    name: "Antarctica",
    eu: false
  },
  {
    code: "ATG",
    name: "Antigua and Barbuda",
    eu: false
  },
  {
    code: "ARG",
    name: "Argentina",
    eu: false
  },
  {
    code: "ARM",
    name: "Armenia",
    eu: false
  },
  {
    code: "ABW",
    name: "Aruba",
    eu: false
  },
  {
    code: "AUS",
    name: "Australia",
    eu: false
  },
  {
    code: "AUT",
    name: "Austria",
    eu: true
  },
  {
    code: "AZE",
    name: "Azerbaijan",
    eu: false
  },
  {
    code: "BHS",
    name: "Bahamas",
    eu: false
  },
  {
    code: "BHR",
    name: "Bahrain",
    eu: false
  },
  {
    code: "BGD",
    name: "Bangladesh",
    eu: false
  },
  {
    code: "BRB",
    name: "Barbados",
    eu: false
  },
  {
    code: "BLR",
    name: "Belarus",
    eu: false
  },
  {
    code: "BEL",
    name: "Belgium",
    eu: true
  },
  {
    code: "BLZ",
    name: "Belize",
    eu: false
  },
  {
    code: "BEN",
    name: "Benin",
    eu: false
  },
  {
    code: "BMU",
    name: "Bermuda",
    eu: false
  },
  {
    code: "BTN",
    name: "Bhutan",
    eu: false
  },
  {
    code: "BOL",
    name: "Bolivia, Plurinational State of",
    eu: false
  },
  {
    code: "BES",
    name: "Bonaire, Sint Eustatius and Saba",
    eu: false
  },
  {
    code: "BIH",
    name: "Bosnia and Herzegovina",
    eu: false
  },
  {
    code: "BWA",
    name: "Botswana",
    eu: false
  },
  {
    code: "BVT",
    name: "Bouvet Island",
    eu: false
  },
  {
    code: "BRA",
    name: "Brazil",
    eu: false
  },
  {
    code: "IOT",
    name: "British Indian Ocean Territory",
    eu: false
  },
  {
    code: "BRN",
    name: "Brunei Darussalam",
    eu: false
  },
  {
    code: "BGR",
    name: "Bulgaria",
    eu: true
  },
  {
    code: "BFA",
    name: "Burkina Faso",
    eu: false
  },
  {
    code: "BDI",
    name: "Burundi",
    eu: false
  },
  {
    code: "KHM",
    name: "Cambodia",
    eu: false
  },
  {
    code: "CMR",
    name: "Cameroon",
    eu: false
  },
  {
    code: "CAN",
    name: "Canada",
    eu: false
  },
  {
    code: "CPV",
    name: "Cape Verde",
    eu: false
  },
  {
    code: "CYM",
    name: "Cayman Islands",
    eu: false
  },
  {
    code: "CAF",
    name: "Central African Republic",
    eu: false
  },
  {
    code: "TCD",
    name: "Chad",
    eu: false
  },
  {
    code: "CHL",
    name: "Chile",
    eu: false
  },
  {
    code: "CHN",
    name: "China",
    eu: false
  },
  {
    code: "CXR",
    name: "Christmas Island",
    eu: false
  },
  {
    code: "CCK",
    name: "Cocos (Keeling) Islands",
    eu: false
  },
  {
    code: "COL",
    name: "Colombia",
    eu: false
  },
  {
    code: "COM",
    name: "Comoros",
    eu: false
  },
  {
    code: "COG",
    name: "Congo",
    eu: false
  },
  {
    code: "COD",
    name: "Congo, the Democratic Republic of the",
    eu: false
  },
  {
    code: "COK",
    name: "Cook Islands",
    eu: false
  },
  {
    code: "CRI",
    name: "Costa Rica",
    eu: false
  },
  {
    code: "CIV",
    name: "C\xF4te d'Ivoire",
    eu: false
  },
  {
    code: "HRV",
    name: "Croatia",
    eu: true
  },
  {
    code: "CUB",
    name: "Cuba",
    eu: false
  },
  {
    code: "CUW",
    name: "Cura\xE7ao",
    eu: false
  },
  {
    code: "CYP",
    name: "Cyprus",
    eu: true
  },
  {
    code: "CZE",
    name: "Czech Republic",
    eu: true
  },
  {
    code: "DNK",
    name: "Denmark",
    eu: true
  },
  {
    code: "DJI",
    name: "Djibouti",
    eu: false
  },
  {
    code: "DMA",
    name: "Dominica",
    eu: false
  },
  {
    code: "DOM",
    name: "Dominican Republic",
    eu: false
  },
  {
    code: "ECU",
    name: "Ecuador",
    eu: false
  },
  {
    code: "EGY",
    name: "Egypt",
    eu: false
  },
  {
    code: "SLV",
    name: "El Salvador",
    eu: false
  },
  {
    code: "GNQ",
    name: "Equatorial Guinea",
    eu: false
  },
  {
    code: "ERI",
    name: "Eritrea",
    eu: false
  },
  {
    code: "EST",
    name: "Estonia",
    eu: true
  },
  {
    code: "ETH",
    name: "Ethiopia",
    eu: false
  },
  {
    code: "FLK",
    name: "Falkland Islands (Malvinas)",
    eu: false
  },
  {
    code: "FRO",
    name: "Faroe Islands",
    eu: false
  },
  {
    code: "FJI",
    name: "Fiji",
    eu: false
  },
  {
    code: "FIN",
    name: "Finland",
    eu: true
  },
  {
    code: "FRA",
    name: "France",
    eu: true
  },
  {
    code: "GUF",
    name: "French Guiana",
    eu: false
  },
  {
    code: "PYF",
    name: "French Polynesia",
    eu: false
  },
  {
    code: "ATF",
    name: "French Southern Territories",
    eu: false
  },
  {
    code: "GAB",
    name: "Gabon",
    eu: false
  },
  {
    code: "GMB",
    name: "Gambia",
    eu: false
  },
  {
    code: "GEO",
    name: "Georgia",
    eu: false
  },
  {
    code: "DEU",
    name: "Germany",
    eu: true
  },
  {
    code: "GHA",
    name: "Ghana",
    eu: false
  },
  {
    code: "GIB",
    name: "Gibraltar",
    eu: false
  },
  {
    code: "GRC",
    name: "Greece",
    eu: true
  },
  {
    code: "GRL",
    name: "Greenland",
    eu: false
  },
  {
    code: "GRD",
    name: "Grenada",
    eu: false
  },
  {
    code: "GLP",
    name: "Guadeloupe",
    eu: false
  },
  {
    code: "GUM",
    name: "Guam",
    eu: false
  },
  {
    code: "GTM",
    name: "Guatemala",
    eu: false
  },
  {
    code: "GGY",
    name: "Guernsey",
    eu: false
  },
  {
    code: "GIN",
    name: "Guinea",
    eu: false
  },
  {
    code: "GNB",
    name: "Guinea-Bissau",
    eu: false
  },
  {
    code: "GUY",
    name: "Guyana",
    eu: false
  },
  {
    code: "HTI",
    name: "Haiti",
    eu: false
  },
  {
    code: "HMD",
    name: "Heard Island and McDonald Islands",
    eu: false
  },
  {
    code: "VAT",
    name: "Holy See (Vatican City State)",
    eu: false
  },
  {
    code: "HND",
    name: "Honduras",
    eu: false
  },
  {
    code: "HKG",
    name: "Hong Kong",
    eu: false
  },
  {
    code: "HUN",
    name: "Hungary",
    eu: true
  },
  {
    code: "ISL",
    name: "Iceland",
    eu: false
  },
  {
    code: "IND",
    name: "India",
    eu: false
  },
  {
    code: "IDN",
    name: "Indonesia",
    eu: false
  },
  {
    code: "IRN",
    name: "Iran, Islamic Republic of",
    eu: false
  },
  {
    code: "IRQ",
    name: "Iraq",
    eu: false
  },
  {
    code: "IRL",
    name: "Ireland",
    eu: true
  },
  {
    code: "IMN",
    name: "Isle of Man",
    eu: false
  },
  {
    code: "ISR",
    name: "Israel",
    eu: false
  },
  {
    code: "ITA",
    name: "Italy",
    eu: true
  },
  {
    code: "JAM",
    name: "Jamaica",
    eu: false
  },
  {
    code: "JPN",
    name: "Japan",
    eu: false
  },
  {
    code: "JEY",
    name: "Jersey",
    eu: false
  },
  {
    code: "JOR",
    name: "Jordan",
    eu: false
  },
  {
    code: "KAZ",
    name: "Kazakhstan",
    eu: false
  },
  {
    code: "KEN",
    name: "Kenya",
    eu: false
  },
  {
    code: "KIR",
    name: "Kiribati",
    eu: false
  },
  {
    code: "PRK",
    name: "Korea, Democratic People's Republic of",
    eu: false
  },
  {
    code: "KOR",
    name: "Korea, Republic of",
    eu: false
  },
  {
    code: "KWT",
    name: "Kuwait",
    eu: false
  },
  {
    code: "KGZ",
    name: "Kyrgyzstan",
    eu: false
  },
  {
    code: "LAO",
    name: "Lao People's Democratic Republic",
    eu: false
  },
  {
    code: "LVA",
    name: "Latvia",
    eu: true
  },
  {
    code: "LBN",
    name: "Lebanon",
    eu: false
  },
  {
    code: "LSO",
    name: "Lesotho",
    eu: false
  },
  {
    code: "LBR",
    name: "Liberia",
    eu: false
  },
  {
    code: "LBY",
    name: "Libya",
    eu: false
  },
  {
    code: "LIE",
    name: "Liechtenstein",
    eu: false
  },
  {
    code: "LTU",
    name: "Lithuania",
    eu: true
  },
  {
    code: "LUX",
    name: "Luxembourg",
    eu: true
  },
  {
    code: "MAC",
    name: "Macao",
    eu: false
  },
  {
    code: "MKD",
    name: "Macedonia, the former Yugoslav Republic of",
    eu: false
  },
  {
    code: "MDG",
    name: "Madagascar",
    eu: false
  },
  {
    code: "MWI",
    name: "Malawi",
    eu: false
  },
  {
    code: "MYS",
    name: "Malaysia",
    eu: false
  },
  {
    code: "MDV",
    name: "Maldives",
    eu: false
  },
  {
    code: "MLI",
    name: "Mali",
    eu: false
  },
  {
    code: "MLT",
    name: "Malta",
    eu: true
  },
  {
    code: "MHL",
    name: "Marshall Islands",
    eu: false
  },
  {
    code: "MTQ",
    name: "Martinique",
    eu: false
  },
  {
    code: "MRT",
    name: "Mauritania",
    eu: false
  },
  {
    code: "MUS",
    name: "Mauritius",
    eu: false
  },
  {
    code: "MYT",
    name: "Mayotte",
    eu: false
  },
  {
    code: "MEX",
    name: "Mexico",
    eu: false
  },
  {
    code: "FSM",
    name: "Micronesia, Federated States of",
    eu: false
  },
  {
    code: "MDA",
    name: "Moldova, Republic of",
    eu: false
  },
  {
    code: "MCO",
    name: "Monaco",
    eu: false
  },
  {
    code: "MNG",
    name: "Mongolia",
    eu: false
  },
  {
    code: "MNE",
    name: "Montenegro",
    eu: false
  },
  {
    code: "MSR",
    name: "Montserrat",
    eu: false
  },
  {
    code: "MAR",
    name: "Morocco",
    eu: false
  },
  {
    code: "MOZ",
    name: "Mozambique",
    eu: false
  },
  {
    code: "MMR",
    name: "Myanmar",
    eu: false
  },
  {
    code: "NAM",
    name: "Namibia",
    eu: false
  },
  {
    code: "NRU",
    name: "Nauru",
    eu: false
  },
  {
    code: "NPL",
    name: "Nepal",
    eu: false
  },
  {
    code: "NLD",
    name: "Netherlands",
    eu: true
  },
  {
    code: "NCL",
    name: "New Caledonia",
    eu: false
  },
  {
    code: "NZL",
    name: "New Zealand",
    eu: false
  },
  {
    code: "NIC",
    name: "Nicaragua",
    eu: false
  },
  {
    code: "NER",
    name: "Niger",
    eu: false
  },
  {
    code: "NGA",
    name: "Nigeria",
    eu: false
  },
  {
    code: "NIU",
    name: "Niue",
    eu: false
  },
  {
    code: "NFK",
    name: "Norfolk Island",
    eu: false
  },
  {
    code: "MNP",
    name: "Northern Mariana Islands",
    eu: false
  },
  {
    code: "NOR",
    name: "Norway",
    eu: false
  },
  {
    code: "OMN",
    name: "Oman",
    eu: false
  },
  {
    code: "PAK",
    name: "Pakistan",
    eu: false
  },
  {
    code: "PLW",
    name: "Palau",
    eu: false
  },
  {
    code: "PSE",
    name: "Palestinian Territory, Occupied",
    eu: false
  },
  {
    code: "PAN",
    name: "Panama",
    eu: false
  },
  {
    code: "PNG",
    name: "Papua New Guinea",
    eu: false
  },
  {
    code: "PRY",
    name: "Paraguay",
    eu: false
  },
  {
    code: "PER",
    name: "Peru",
    eu: false
  },
  {
    code: "PHL",
    name: "Philippines",
    eu: false
  },
  {
    code: "PCN",
    name: "Pitcairn",
    eu: false
  },
  {
    code: "POL",
    name: "Poland",
    eu: true
  },
  {
    code: "PRT",
    name: "Portugal",
    eu: true
  },
  {
    code: "PRI",
    name: "Puerto Rico",
    eu: false
  },
  {
    code: "QAT",
    name: "Qatar",
    eu: false
  },
  {
    code: "REU",
    name: "R\xE9union",
    eu: false
  },
  {
    code: "ROU",
    name: "Romania",
    eu: true
  },
  {
    code: "RUS",
    name: "Russian Federation",
    eu: false
  },
  {
    code: "RWA",
    name: "Rwanda",
    eu: false
  },
  {
    code: "BLM",
    name: "Saint Barth\xE9lemy",
    eu: false
  },
  {
    code: "SHN",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    eu: false
  },
  {
    code: "KNA",
    name: "Saint Kitts and Nevis",
    eu: false
  },
  {
    code: "LCA",
    name: "Saint Lucia",
    eu: false
  },
  {
    code: "MAF",
    name: "Saint Martin (French part)",
    eu: false
  },
  {
    code: "SPM",
    name: "Saint Pierre and Miquelon",
    eu: false
  },
  {
    code: "VCT",
    name: "Saint Vincent and the Grenadines",
    eu: false
  },
  {
    code: "WSM",
    name: "Samoa",
    eu: false
  },
  {
    code: "SMR",
    name: "San Marino",
    eu: false
  },
  {
    code: "STP",
    name: "Sao Tome and Principe",
    eu: false
  },
  {
    code: "SAU",
    name: "Saudi Arabia",
    eu: false
  },
  {
    code: "SEN",
    name: "Senegal",
    eu: false
  },
  {
    code: "SRB",
    name: "Serbia",
    eu: false
  },
  {
    code: "SYC",
    name: "Seychelles",
    eu: false
  },
  {
    code: "SLE",
    name: "Sierra Leone",
    eu: false
  },
  {
    code: "SGP",
    name: "Singapore",
    eu: false
  },
  {
    code: "SXM",
    name: "Sint Maarten (Dutch part)",
    eu: false
  },
  {
    code: "SVK",
    name: "Slovakia",
    eu: true
  },
  {
    code: "SVN",
    name: "Slovenia",
    eu: true
  },
  {
    code: "SLB",
    name: "Solomon Islands",
    eu: false
  },
  {
    code: "SOM",
    name: "Somalia",
    eu: false
  },
  {
    code: "ZAF",
    name: "South Africa",
    eu: false
  },
  {
    code: "SGS",
    name: "South Georgia and the South Sandwich Islands",
    eu: false
  },
  {
    code: "SSD",
    name: "South Sudan",
    eu: false
  },
  {
    code: "ESP",
    name: "Spain",
    eu: true
  },
  {
    code: "LKA",
    name: "Sri Lanka",
    eu: false
  },
  {
    code: "SDN",
    name: "Sudan",
    eu: false
  },
  {
    code: "SUR",
    name: "Suriname",
    eu: false
  },
  {
    code: "SJM",
    name: "Svalbard and Jan Mayen",
    eu: false
  },
  {
    code: "SWZ",
    name: "Swaziland",
    eu: false
  },
  {
    code: "SWE",
    name: "Sweden",
    eu: true
  },
  {
    code: "CHE",
    name: "Switzerland",
    eu: false
  },
  {
    code: "SYR",
    name: "Syrian Arab Republic",
    eu: false
  },
  {
    code: "TWN",
    name: "Taiwan, Province of China",
    eu: false
  },
  {
    code: "TJK",
    name: "Tajikistan",
    eu: false
  },
  {
    code: "TZA",
    name: "Tanzania, United Republic of",
    eu: false
  },
  {
    code: "THA",
    name: "Thailand",
    eu: false
  },
  {
    code: "TLS",
    name: "Timor-Leste",
    eu: false
  },
  {
    code: "TGO",
    name: "Togo",
    eu: false
  },
  {
    code: "TKL",
    name: "Tokelau",
    eu: false
  },
  {
    code: "TON",
    name: "Tonga",
    eu: false
  },
  {
    code: "TTO",
    name: "Trinidad and Tobago",
    eu: false
  },
  {
    code: "TUN",
    name: "Tunisia",
    eu: false
  },
  {
    code: "TUR",
    name: "Turkey",
    eu: false
  },
  {
    code: "TKM",
    name: "Turkmenistan",
    eu: false
  },
  {
    code: "TCA",
    name: "Turks and Caicos Islands",
    eu: false
  },
  {
    code: "TUV",
    name: "Tuvalu",
    eu: false
  },
  {
    code: "UGA",
    name: "Uganda",
    eu: false
  },
  {
    code: "UKR",
    name: "Ukraine",
    eu: false
  },
  {
    code: "ARE",
    name: "United Arab Emirates",
    eu: false
  },
  {
    code: "GBR",
    name: "United Kingdom",
    eu: true
  },
  {
    code: "USA",
    name: "United States",
    eu: false
  },
  {
    code: "UMI",
    name: "United States Minor Outlying Islands",
    eu: false
  },
  {
    code: "URY",
    name: "Uruguay",
    eu: false
  },
  {
    code: "UZB",
    name: "Uzbekistan",
    eu: false
  },
  {
    code: "VUT",
    name: "Vanuatu",
    eu: false
  },
  {
    code: "VEN",
    name: "Venezuela, Bolivarian Republic of",
    eu: false
  },
  {
    code: "VNM",
    name: "Viet Nam",
    eu: false
  },
  {
    code: "VGB",
    name: "Virgin Islands, British",
    eu: false
  },
  {
    code: "VIR",
    name: "Virgin Islands, U.S.",
    eu: false
  },
  {
    code: "WLF",
    name: "Wallis and Futuna",
    eu: false
  },
  {
    code: "ESH",
    name: "Western Sahara",
    eu: false
  },
  {
    code: "YEM",
    name: "Yemen",
    eu: false
  },
  {
    code: "ZMB",
    name: "Zambia",
    eu: false
  },
  {
    code: "ZWE",
    name: "Zimbabwe",
    eu: false
  }
];
var countryISO2Dict = {
  AF: "Afghanistan",
  AX: "Aland Islands",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua And Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnia And Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CV: "Cape Verde",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo",
  CD: "Congo, Democratic Republic",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: 'Cote D"Ivoire',
  HR: "Croatia",
  CU: "Cuba",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Ethiopia",
  FK: "Falkland Islands (Malvinas)",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island & Mcdonald Islands",
  VA: "Holy See (Vatican City State)",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran, Islamic Republic Of",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle Of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KR: "Korea",
  KP: "North Korea",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: 'Lao People"s Democratic Republic',
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libyan Arab Jamahiriya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MK: "Macedonia",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia, Federated States Of",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  AN: "Netherlands Antilles",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestinian Territory, Occupied",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Reunion",
  RO: "Romania",
  RU: "Russian Federation",
  RW: "Rwanda",
  BL: "Saint Barthelemy",
  SH: "Saint Helena",
  KN: "Saint Kitts And Nevis",
  LC: "Saint Lucia",
  MF: "Saint Martin",
  PM: "Saint Pierre And Miquelon",
  VC: "Saint Vincent And Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome And Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia And Sandwich Isl.",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SJ: "Svalbard And Jan Mayen",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad And Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks And Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States",
  UM: "United States Outlying Islands",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Vietnam",
  VG: "Virgin Islands, British",
  VI: "Virgin Islands, U.S.",
  WF: "Wallis And Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe"
};

// src/constants/passports.ts
var passports = ["ARG", "CHL", "PRY", "BRA"];

// src/constants/alphabet.ts
var alphabet = {
  0: "A",
  1: "B",
  2: "C"
};

// src/constants/languages-iso-639-1.ts
var languagesISO6391 = [
  {
    code: "es",
    name: "Espa\xF1ol"
  },
  {
    code: "en",
    name: "Ingl\xE9s"
  }
];
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  DEFAULT_DECIMAL_PRECISION,
  EMPTY_RULE_ACTION,
  MAX_PROGRESS_TRACKER_STEPS,
  MAX_WEBHOOKS_PER_COMPANY,
  REPORT_REQUEST_AUTHENTICATION_HEADER,
  VARIABLE_NAME_MAX_LENGTH,
  VIDEO_MAX_DURATION,
  alignments,
  alphabet,
  assetActionReferences,
  assetTargets,
  assetTypes,
  booleanInputs,
  buttonActions,
  buttonShowWhenConditions,
  buttonVariants,
  canvasSlots,
  carDamageModels,
  colors,
  companyVariableNames,
  conditionActionDispatches,
  conditionTermTargetsType,
  conditionTermTypes,
  conditionalOperatorsList,
  conditionalsOperatorsRules,
  constantDataType2VariableType,
  containerSpacingOptions,
  countriesIso3,
  countryISO2Dict,
  customValidationDefinitionTypes,
  datasetQueryEntities,
  defaultTextAlignment,
  dialogDecisionActionTypes,
  documentCanvasBoxSize,
  documentDependantPaths,
  documentFieldBoxSize,
  documentFieldConditions,
  documentFieldFontSizeLimits,
  documentFieldMaxHeight,
  documentFieldMaxWidth,
  documentFieldTypes,
  eventTrackingActions,
  eventTrackingScopes,
  fallbackScreenTypes,
  fallbackSectionTypes,
  featureFieldOperators,
  featureFieldType,
  fileTypes,
  fontList,
  fontTypes,
  frequencyTypes,
  goodsCategoryList,
  goodsProductsList,
  headingLevelList,
  headingLevels,
  iconsNames,
  imageFeatureAngles,
  imageFeatureCarParts,
  imageFeatureColors,
  imageFeatureDamages,
  imageFeatureFields,
  imageFeatureObjects,
  imageFeatureSeverities,
  imageFeatureVehicleTypes,
  inferredEntities,
  inputBehaviour,
  inputStages,
  inputTypes,
  inputTypesWithOptions,
  inspectionClientRequestTrackingKey,
  inspectionLimits,
  inspectionStages,
  inspectionStatus,
  inspectionTemplateInputHintTypes,
  inspectionTypes,
  jobStatus,
  languagesISO6391,
  locales,
  logicRuleActions,
  logicRuleBlockTypes,
  logicRuleCarDamageSeverities,
  logicRuleCarDamages,
  logicRuleCarParts,
  logicRuleCombinations,
  logicRuleInputReferenceTypes,
  logicRuleOperators,
  logicRuleUses,
  machineryPurposes,
  machineryTypes,
  machineryUses,
  mentionTypes,
  messageTemplateVariables,
  messageTemplateVariablesPath,
  notificationEventChannels,
  notificationEventSources,
  notificationEvents,
  operatorsByFieldType,
  overwritablesSectionTypes,
  passports,
  pdfVariants,
  presignResourceTypes,
  progressTrackerVariants,
  revertActionDispatches,
  sectionChildrenTypes,
  sectionReorderConstraint,
  sectionTypes,
  sexTypes,
  signatureModes,
  templateConditionLinks,
  templateConditionTermOperators,
  templateConditionTypes,
  templateContainerAlignments,
  templateContainerTypes,
  templateInputValidationTypes,
  templateStatus,
  templateVariableTypes,
  textAlignmentTypes,
  textAlignments,
  themeBorderRadiusList,
  themeColorVariant,
  timezones,
  timezonesOptions,
  variableActionReferences,
  variableNumericDispatches,
  variableTextDispatches,
  vehicleUses,
  videoProviders,
  webhookEvents,
  widgetTypes
});
