import { Box, Spinner, Text } from '@chakra-ui/react';
import React from 'react';

export const LoadingDataWrapper = (props: {
  isLoading: boolean;
  error?: unknown;
  children: React.ReactNode;
}) => {
  if (props.isLoading) {
    return (
      <Box mt="5" w="full">
        <Spinner />
      </Box>
    );
  }

  if (props.error) {
    return <Text>Algo no salio como se esperaba</Text>;
  }

  return <>{props.children}</>;
};
