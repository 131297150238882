'use client'

import { IconX } from '@tabler/icons-react'
import * as React from 'react'
import { Badge } from './Badge'
import { ComboboxOption } from './ComboboxSelect'
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandEmpty,
} from './Command'
import { Command as CommandPrimitive } from 'cmdk'
import { cn } from '../utils/cn'

export const FancyMultiSelect = ({
  options,
  value: selected = [],
  onValueChange,
  noOptionsMessage = 'No hay opciones',
  className,
  placeholder = 'Selecciona opciones',
  inputClassName,
  containerClassName,
}: {
  options: ComboboxOption[]
  value: ComboboxOption[]
  onValueChange: (value: ComboboxOption[]) => void
  noOptionsMessage?: string
  className?: string
  placeholder?: string
  inputClassName?: string
  containerClassName?: string
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [open, setOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')

  const handleUnselect = React.useCallback(
    (opt: ComboboxOption) => {
      const nextSelected = selected.filter((s) => s.value !== opt.value)
      onValueChange(nextSelected)
    },
    [selected],
  )

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current
      if (input) {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          if (input.value === '') {
            const nextValue = Array.from(selected)
            nextValue.pop()
            onValueChange(nextValue)
          }
        }
        // This is not a default behaviour of the <input /> field
        if (e.key === 'Escape') {
          input.blur()
        }
      }
    },
    [selected],
  )

  const selectables = React.useMemo(
    () => options.filter((opt) => !selected.includes(opt)),
    [selected, options],
  )

  return (
    <Command
      onKeyDown={handleKeyDown}
      className={cn('overflow-visible bg-transparent', containerClassName)}
    >
      <div
        className={cn(
          'group border border-input px-3 py-2 text-sm ring-offset-background rounded-md focus-within:ring-2 focus-within:ring-ring/75 focus-within:ring-offset-2',
          className,
        )}
      >
        <div className='flex gap-1 flex-wrap'>
          {selected.map((framework) => {
            return (
              <Badge key={framework.value} variant='secondary'>
                {framework.label}
                <button
                  className='ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2'
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleUnselect(framework)
                    }
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  onClick={() => handleUnselect(framework)}
                >
                  <IconX className='h-3 w-3 text-muted-foreground hover:text-foreground' />
                </button>
              </Badge>
            )
          })}
          <CommandPrimitive.Input
            ref={inputRef}
            value={inputValue}
            onValueChange={setInputValue}
            onBlur={() => setOpen(false)}
            onFocus={() => setOpen(true)}
            placeholder={selected && selected.length > 0 ? '' : placeholder}
            className={cn(
              'ml-2 bg-transparent outline-none placeholder:text-muted-foreground flex-1',
              inputClassName,
            )}
          />
        </div>
      </div>
      <div className='relative mt-2'>
        {open && selectables.length > 0 && (
          <div className='absolute w-full z-10 top-0 rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in'>
            <CommandList withScroll>
              <CommandEmpty>{noOptionsMessage}</CommandEmpty>
              <CommandGroup className='h-full overflow-auto'>
                {selectables.map((selectable) => {
                  return (
                    <CommandItem
                      key={selectable.value}
                      onMouseDown={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                      onSelect={(value) => {
                        setInputValue('')
                        onValueChange([...selected, selectable])
                      }}
                      className={'cursor-pointer'}
                    >
                      {selectable.label}
                    </CommandItem>
                  )
                })}
              </CommandGroup>
            </CommandList>
          </div>
        )}
      </div>
    </Command>
  )
}
