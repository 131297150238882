'use client'

import * as SelectPrimitive from '@radix-ui/react-select'
import { IconCheck, IconChevronDown } from '@tabler/icons-react'
import { VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'
import { cn } from '../utils/cn'

const variants = cva('', {
  variants: {
    variant: {
      filled:
        'flex h-10 w-full items-center justify-between rounded-md border-input bg-transparent px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring/75 disabled:cursor-not-allowed disabled:opacity-50 bg-gray-100',
      underline:
        'flex flex-row space-between py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer',
      outline:
        'flex h-10 w-full items-center justify-between rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring/75 disabled:cursor-not-allowed disabled:opacity-50',
    },
    invalid: {
      true: 'ring-2 ring-destructive',
    },
    size: {
      xs: 'h-6 px-2 text-xs rounded',
      sm: 'h-8 px-3 text-sm',
      md: 'h-10 px-4',
      lg: 'h-12 px-6 text-lg',
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'outline',
  },
})

/**
 * Displays a list of options for the user to pick from—triggered by a button.
 */
const Select = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Root> & {
    behavior?: 'modal' | 'non-modal'
  }
>(({ behavior = 'modal', onOpenChange, ...props }, ref) => {
  return (
    <SelectPrimitive.Root
      ref={ref}
      onOpenChange={(open) => {
        if (onOpenChange) {
          onOpenChange(open)
        }

        if (behavior === 'non-modal') {
          if (open) {
            document.body.style.setProperty('overflow', 'unset', 'important')
          }

          if (!open) {
            document.body.style.removeProperty('overflow')
          }
        }
      }}
      {...props}
    />
  )
})

const SelectGroup = SelectPrimitive.Group

const SelectValue = SelectPrimitive.Value

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> &
    VariantProps<typeof variants>
>(({ className, children, ...props }, ref) => {
  if (props.variant === 'underline') {
    return (
      <SelectPrimitive.Trigger
        ref={ref}
        className={cn(
          variants({
            size: props.size,
            variant: props.variant,
            invalid: props.invalid,
          }),
          className,
        )}
        {...props}
      >
        <div className='flex flex-row justify-between w-full'>
          {children}
          <SelectPrimitive.Icon asChild>
            <IconChevronDown className='h-4 w-4 opacity-50' />
          </SelectPrimitive.Icon>
        </div>
      </SelectPrimitive.Trigger>
    )
  }

  return (
    <SelectPrimitive.Trigger
      ref={ref}
      className={cn(
        '[&_span]:truncate',
        variants({
          size: props.size,
          variant: props.variant,
          invalid: props.invalid,
        }),
        className,
      )}
      {...props}
    >
      {children}
      <SelectPrimitive.Icon asChild>
        <IconChevronDown className='h-4 w-4 opacity-50' />
      </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
  )
})

SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(
  (
    { className, children, position = 'popper', behavior = 'modal', ...props },
    ref,
  ) => {
    const hasOptions = React.useMemo(
      () =>
        Array.isArray(children)
          ? (children as React.ReactElement[])?.length > 0
          : !!children,
      [children],
    )

    return (
      <SelectPrimitive.Portal>
        <SelectPrimitive.Content
          ref={ref}
          className={cn(
            'relative z-50 min-w-[8rem] max-h-72 overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md animate-in fade-in-80',
            position === 'popper' && 'translate-y-1',
            className,
          )}
          position={position}
          {...props}
        >
          <SelectPrimitive.Viewport
            className={cn(
              'p-1',
              position === 'popper' &&
                'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]',
            )}
          >
            {!hasOptions ? (
              <span className='text-xs text-muted-foreground'>
                No hay opciones disponibles
              </span>
            ) : (
              children
            )}
          </SelectPrimitive.Viewport>
          {hasOptions && (
            <SelectPrimitive.ScrollDownButton className='flex items-center justify-center h-4 bg-inherit'>
              <IconChevronDown className='text-gray-400 h-4 w-4' />
            </SelectPrimitive.ScrollDownButton>
          )}
        </SelectPrimitive.Content>
      </SelectPrimitive.Portal>
    )
  },
)
SelectContent.displayName = SelectPrimitive.Content.displayName

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn('py-1.5 pl-8 pr-2 text-sm font-semibold', className)}
    {...props}
  />
))
SelectLabel.displayName = SelectPrimitive.Label.displayName

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      'relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className,
    )}
    {...props}
  >
    <span className='absolute left-2 flex h-3.5 w-3.5 items-center justify-center'>
      <SelectPrimitive.ItemIndicator>
        <IconCheck className='h-4 w-4' />
      </SelectPrimitive.ItemIndicator>
    </span>

    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
))
SelectItem.displayName = SelectPrimitive.Item.displayName

const SelectPortal = SelectPrimitive.Portal

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn('-mx-1 my-1 h-px bg-muted', className)}
    {...props}
  />
))
SelectSeparator.displayName = SelectPrimitive.Separator.displayName

export {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
  SelectPortal,
}
