import { yupResolver } from '@hookform/resolvers/yup'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import {
  DeliveryChannel,
  MessageTemplateCtx,
} from '../providers/MessageTemplate.provider'
import {
  IMessageTemplateSchema,
  MessageTemplateSchema,
} from '../schemas/message-template.schema'
import { useRouter } from 'next/router'

const bodyByType = {
  blocked: 'Te avisamos que la siguiente inspección se bloqueó por expiración.',
  pending:
    'Te damos la bienvenida nuevamente.  Para iniciar la autoinspección, presiona el botón que se encuentra abajo. \nTienes {{inspection.rules.deadlineDays}} días para ejecutarla, en caso contrario esta será bloqueada.',
  finished:
    'Te informamos que la inspección ha sido finalizada. Puedes descargar el certificado a continuación. \nSaludos, Equipo de Autoinspector',
}

const buttonLabelByType = {
  blocked: 'Desbloquear inspección',
  pending: 'Continuar inspección',
  finished: 'Descargar certificado',
}

export const useMessageTemplateForm = (props: {
  defaultValues?: IMessageTemplateSchema
  channel?: DeliveryChannel
}) => {
  const router = useRouter()
  const type = router?.query?.flow

  const getInitVals = () => {
    return (
      props.defaultValues || {
        subject: 'Hola',
        body:
          bodyByType[type] ||
          `Para iniciar la inspección, presiona el botón que se encuentra abajo\nTienes {{inspection.rules.deadlineDays}} días para ejecutarla`,
        buttonLabel: buttonLabelByType[type] || 'Iniciar inspección',
      }
    )
  }

  const [initVals, setInitVals] = useState(getInitVals())

  const { logo, deadlineDays } = useContext(MessageTemplateCtx)

  const form = useForm<IMessageTemplateSchema>({
    resolver: yupResolver(MessageTemplateSchema),
    defaultValues: {
      ...initVals,
      deadlineDays,
      logo,
      channel: props.channel,
    },
  })

  useEffect(() => {
    setInitVals(getInitVals())
    form.reset({
      ...getInitVals(),
      deadlineDays,
      logo,
      channel: props.channel,
    })
  }, [type])

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = form

  //TODO: refactor this
  const customSubjectRef = useRef<HTMLInputElement>()
  const customBodyRef = useRef<HTMLTextAreaElement>()
  const customFooterRef = useRef<HTMLTextAreaElement>()
  const customButtonLabelRef = useRef<HTMLTextAreaElement>()

  //TODO: refactor this
  const { ref: bodyRef, ...bodyRest } = register('body')
  const { ref: subjectRef, ...subjectRest } = register('subject')
  const { ref: footerRef, ...footerRest } = register('footer')
  const { ref: buttonLabelRef, ...buttonLabelRest } = register('buttonLabel')

  const fields = watch()

  const refs = {
    subject: customSubjectRef,
    body: customBodyRef,
    footer: customFooterRef,
  }

  const onAddVariable = useCallback(
    (input: keyof typeof refs, variable: string) => {
      const refToExtractCursorPosition = (refs[input] as any)?.current

      if (refToExtractCursorPosition) {
        //@ts-ignore
        const cursorPosition: number = refToExtractCursorPosition.selectionStart

        setValue(
          input,
          `${refToExtractCursorPosition.value.substring(
            0,
            cursorPosition,
          )} {{${variable}}} ${refToExtractCursorPosition.value.substring(
            cursorPosition,
            refToExtractCursorPosition.value.length,
          )}`,
        )
      }
    },
    [],
  )

  return {
    errors,
    onAddVariable,
    fields,
    handleSubmit,
    logo,
    deadlineDays,
    bodyRest,
    subjectRest,
    footerRest,
    buttonLabelRest,
    customButtonLabelRef,
    bodyRef,
    footerRef,
    subjectRef,
    buttonLabelRef,
    register,
    customSubjectRef,
    customBodyRef,
    customFooterRef,
    form,
  }
}
