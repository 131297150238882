import React from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { cn } from '../utils/cn'
import { theme } from 'tailwindcss/defaultConfig'
import colors from 'tailwindcss/colors'

import tinycolor from 'tinycolor2'

const tagVariants = cva(
  'inline-flex items-center border rounded font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        primary: 'bg-primary border-transparent text-primary-foreground',
        secondary: 'bg-secondary border-transparent text-secondary-foreground',
        destructive:
          'bg-destructive border-transparent text-destructive-foreground',
        outline: 'text-primary border-current',
      },
      size: {
        sm: 'text-xs min-w-[20px] min-h-[20px] ps-2 pe-2',
        md: 'text-sm min-w-[24px] min-h-[24px] ps-2 pe-2',
        lg: 'text-lg min-w-[32px] min-h-[32px] ps-3 pe-3',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  },
)

export interface TagProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof tagVariants> {
  colorScheme?: keyof typeof colors
}

function Tag({ className, variant, size, colorScheme, ...props }: TagProps) {
  const getColorClasses = (colorScheme: keyof typeof colors) => {
    const backgroundColor = colors[colorScheme]?.[500] || ''
    const textColor = getTextColor(backgroundColor)
    return backgroundColor
  }

  const getTextColor = (backgroundColor: string) => {
    const contrastColor = tinycolor.mostReadable(
      backgroundColor,
      ['#000', '#fff'],
      {
        includeFallbackColors: true,
      },
    )
    return contrastColor.isLight() ? 'text-black' : 'text-white'
  }

  const bgColor = getColorClasses(colorScheme)
  return (
    <div
      className={cn(
        tagVariants({ variant, size }),
        getColorClasses(colorScheme),
        className,
      )}
      style={{ backgroundColor: bgColor }}
      {...props}
    />
  )
}

export { Tag, tagVariants }
