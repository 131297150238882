import { buildPublicAssetURL } from 'utils/build-public-asset-url'

export const UnexpectedErrorMessage = () => {
  return (
    <div className='h-full max-w-[60ch] mx-auto'>
      <div className='relative'>
        <img
          className='w-[600px] h-[600px]'
          src={'/assets/svg/robot.svg'}
          alt='Error robot'
        />
        <p className='text-center font-bold text-3xl top-[60%] right-[35%] text-white'>
          500
        </p>
      </div>
      <div className='p-4 text-center'>
        <p className='text-3xl font-bold'>Algo salió mal</p>
        <p className='my-5'>
          El equipo de Autoinspector ya fue notificado del error y lo
          solucionara lo antes posible. Disculpa por las molestias ocasionadas.
        </p>
      </div>
    </div>
  )
}
