import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { Input } from 'ui'

export const NumberInputHookFormV2 = ({
  size = 'md',
  ...props
}: {
  controller: Control
  name: string
  error?: {
    message: string
  }
  variant?: string
  precision?: number
  size?: 'md' | 'sm'
}) => {
  return (
    <Controller
      control={props.controller}
      name={props.name}
      render={({ field }) => {
        return (
          <Input
            {...field}
            size={size}
            type='number'
            invalid={!!props.error}
            step={1}
            min={1}
            pattern='[0-9]*'
          />
        )
      }}
    />
  )
}
