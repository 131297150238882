'use client'
import { useCallback, useState } from 'react'

export interface UseDisclosureProps {
  isOpen?: boolean
  defaultIsOpen?: boolean
  onClose?(): void
  onOpen?(): void
  onToggle?(): void
}

export const useDisclosure = (initialIsOpen?: boolean) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen || false)

  const onToggle = useCallback(() => setIsOpen((prev) => !prev), [])

  const onOpen = useCallback(() => setIsOpen(true), [])

  const onClose = useCallback(() => setIsOpen(false), [])

  return {
    isOpen,
    onToggle,
    onOpen,
    onClose,
  }
}
