// import { Box, Stack, useTheme } from '@chakra-ui/react'
import { alphabet } from 'ai-constants'
import { addAlpha } from 'lib'
import React from 'react'
import { cn } from 'ui'
import { TemplateInputAction } from '../interfaces/inspection-template'

export type TemplateInputActionType = 'positive' | 'negative'

export const InputDialogDecision = (props: {
  onChecked: (checked: boolean) => void
  checked?: boolean
  actions: TemplateInputAction[]
  children: React.FC<{
    action: TemplateInputAction
  }>
}) => {
  const colors = {
    primary: {
      light: '#7b5edb26',
      darker: '#7B5EDB',
    },
  }

  return (
    <div className='flex flex-row font-bold text-center items-start space-x-4'>
      {props.actions.map((action, index) => {
        const currentChecked =
          action.type === 'positive' && props.checked === true
            ? true
            : action.type === 'negative' && props.checked === false
            ? true
            : false

        return (
          <div
            className={cn(
              'bg-primary/20 color-primary cursor-pointer flex flex-row hover:bg-primary/30 w-36 rounded-md p-2 border-primary transition-all',
              {
                'outline outline-1 outline-primary': !currentChecked,
                'outline outline-2 outline-primary': currentChecked,
              },
            )}
            onClick={() => {
              const checked = action.type === 'positive'
              props.onChecked(checked)
            }}
          >
            <div
              className={cn('px-1 rounded-sm mr-2 border border-primary', {
                'text-white bg-primary': currentChecked,
                'text-primary bg-white': !currentChecked,
              })}
            >
              {alphabet[index]}
            </div>
            <div className='text-primary font-bold flex items-center'>
              {props.children({
                action,
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}
