import React, { useMemo } from 'react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import {
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'ui'

export const ControlsPDF = ({
  scale,
  setScale,
}: {
  scale: number
  setScale: (scale: number) => void
}) => {
  const zoomOptions = useMemo(() => {
    return [
      {
        label: '100%',
        scale: 1,
      },
      {
        label: '150%',
        scale: 1.5,
      },
      {
        label: '200%',
        scale: 2,
      },
      {
        label: '250%',
        scale: 2.5,
      },
    ]
  }, [])

  const minScale = zoomOptions[0].scale
  const maxScale = zoomOptions[zoomOptions.length - 1].scale

  return (
    <div className='flex flex-row items-center space-x-2'>
      <Button
        disabled={scale === minScale}
        onClick={() => {
          const nextZoom = zoomOptions.find((opt) => opt.scale < scale)

          setScale(nextZoom?.scale || scale)
        }}
        aria-label='Zoom in'
        variant='ghost'
      >
        <AiOutlineMinus />
      </Button>
      <Select
        value={scale.toString()}
        onValueChange={(zoom) => setScale(+zoom)}
      >
        <SelectTrigger className='bg-white'>
          <SelectValue placeholder='Zoom' />
        </SelectTrigger>
        <SelectContent>
          {zoomOptions.map((opt, index) => {
            return (
              <SelectItem key={index} value={opt.scale.toString()}>
                {opt.label}
              </SelectItem>
            )
          })}
        </SelectContent>
      </Select>

      <Button
        disabled={scale === maxScale}
        onClick={() => {
          const nextZoom = zoomOptions.find((opt) => opt.scale > scale)

          setScale(nextZoom?.scale || scale)
        }}
        aria-label='Zoom out'
        variant='ghost'
      >
        <AiOutlinePlus />
      </Button>
    </div>
  )
}
