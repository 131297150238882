'use client'
import { IconChevronDown, IconX } from '@tabler/icons-react'
import { VariantProps, cva } from 'class-variance-authority'
import React from 'react'
import Select, {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  MultiValueProps,
  MultiValueRemoveProps,
  NoticeProps,
  components,
} from 'react-select'
import AsyncSelect from 'react-select/async'
import AsyncSelectCreatable from 'react-select/async-creatable'
import { AsyncPaginate } from 'react-select-async-paginate'
import { cn } from '../utils/cn'

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconChevronDown className='h-4 w-4 opacity-50' />
    </components.DropdownIndicator>
  )
}

const ClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <components.ClearIndicator {...props}>
      <IconX className='h-4 w-4 opacity-50' />
    </components.ClearIndicator>
  )
}

const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <IconX className='h-4 w-4 opacity-50' />
    </components.MultiValueRemove>
  )
}

const MultiValue = (props: MultiValueProps) => {
  return (
    <components.MultiValue {...props}>{props.children}</components.MultiValue>
  )
}

const LoadingMessage = (props: NoticeProps) => {
  return (
    <components.LoadingMessage {...props}>
      <p className='text-gray-300 my-4'>Cargando...</p>
    </components.LoadingMessage>
  )
}

const controlStyles = {
  focus:
    'outline-none ring-2 ring-ring ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 bg-gray-100',
  nonFocus: 'border-gray-300',
}

const placeholderStyles = 'flex items-center'
const selectInputStyles = 'text-sm'
const valueContainerStyles = 'p-1 gap-1'

const singleValueStyles = cva('text-sm leading-7 ml-1')

const multiValueStyles =
  'bg-gray-100 rounded items-center py-0.5 pl-2 pr-1 gap-1.5'

const multiValueLabelStyles = 'leading-6 py-0.5'

const multiValueRemoveStyles =
  'border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md'

const indicatorsContainerStyles = ''

const clearIndicatorStyles =
  'text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800'
const indicatorSeparatorStyles = 'hidden'
const dropdownIndicatorStyles =
  'hover:bg-gray-100 text-gray-500 rounded-md hover:text-black'
const menuStyles = 'p-1 mt-2 border border-gray-200 bg-white rounded-lg'
const groupHeadingStyles = 'ml-3 mt-2 mb-1 text-gray-500 text-sm'
const optionStyles = {
  base: "before:content-['✓'] before:invisible hover:cursor-pointer px-3 py-2 rounded before:mr-2",
  focus: 'bg-gray-100 active:bg-gray-200',
  selected: "before:content-['✓'] before:visible text-gray-500",
}
const noOptionsMessageStyles =
  'text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm'

const controlVariants = cva(
  'border border-input rounded-md bg-white hover:cursor-pointer !min-h-0',
  {
    variants: {
      variant: {
        filled:
          'flex w-full items-center justify-between rounded-md border-0 border-input bg-transparent text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring/75 disabled:cursor-not-allowed disabled:opacity-50 bg-gray-100 text-sm',
        underline:
          'flex flex-row space-between py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus-visible:outline-none focus-visible:ring-0 focus-visible:border-gray-200 peer',
        outline:
          'flex  w-full items-center justify-between rounded-md border border-input bg-transparent text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring/75 disabled:cursor-not-allowed disabled:opacity-50',
      },
      invalid: {
        true: 'ring-2 ring-destructive focus:ring-ring/75',
      },
      size: {
        xs: '[&>*]:h-6 [&>*]:px-2 [&>*]:text-xs rounded',
        sm: '[&>*]:h-8 [&>*]:px-3 [&>*]:text-sm',
        md: 'h-10 [&>*]:h-10 [&>*]:px-4 text-sm',
        lg: '[&>*]:h-12 [&>*]:px-6 [&>*]:text-lg',
      },
    },
    defaultVariants: {
      size: 'md',
      variant: 'outline',
    },
  },
)

const getCommonProps = (
  props: VariantProps<typeof singleValueStyles>,
): React.ComponentPropsWithRef<typeof Select> => {
  return {
    isMulti: true,
    closeMenuOnSelect: false,
    hideSelectedOptions: false,
    unstyled: true,
    styles: {
      input: (base) => ({
        ...base,
        'input:focus': {
          boxShadow: 'none',
        },
      }),
      // On mobile, the label will truncate automatically, so we want to
      // override that behaviour.
      multiValueLabel: (base) => ({
        ...base,
        whiteSpace: 'normal',
        overflow: 'visible',
      }),
      control: (base) => ({
        ...base,
        transition: 'none',
      }),
    },
    components: {
      DropdownIndicator,
      ClearIndicator,
      MultiValueRemove,
      LoadingMessage,
      MultiValue,
    },
    classNames: {
      control: ({ isFocused }) =>
        cn(
          isFocused ? controlStyles.focus : controlStyles.nonFocus,
          controlVariants(props),
        ),
      placeholder: () => placeholderStyles,
      input: () => selectInputStyles,
      // valueContainer: () => valueContainerStyles,
      singleValue: () => singleValueStyles(props),
      multiValue: () => multiValueStyles,
      multiValueLabel: () => multiValueLabelStyles,
      multiValueRemove: () => multiValueRemoveStyles,
      indicatorsContainer: () => indicatorsContainerStyles,
      clearIndicator: () => clearIndicatorStyles,
      indicatorSeparator: () => indicatorSeparatorStyles,
      dropdownIndicator: () => dropdownIndicatorStyles,
      menu: () => menuStyles,
      groupHeading: () => groupHeadingStyles,
      option: ({ isFocused, isSelected }) =>
        cn(
          optionStyles.base,
          isFocused && optionStyles.focus,
          isSelected && optionStyles.selected,
        ),
      noOptionsMessage: () => noOptionsMessageStyles,
    },
  }
}

/**
 * Displays a list of multiple options for the user to pick from—triggered by a button.
 */
export const MultiSelect = React.forwardRef<
  React.ElementRef<typeof Select>,
  React.ComponentPropsWithRef<typeof Select> &
    VariantProps<typeof controlVariants>
>(({ size, variant, invalid, ...props }, ref) => {
  return (
    <Select
      ref={ref}
      {...getCommonProps({
        size,
        variant,
        invalid,
      })}
      {...props}
    />
  )
})

MultiSelect.displayName = 'MultiSelect'

/**
 * Use the Async component to load options from a remote source as the user types.
 */
export const MultiSelectAsync = React.forwardRef<
  React.ElementRef<typeof AsyncSelect>,
  React.ComponentPropsWithRef<typeof AsyncSelect> &
    VariantProps<typeof controlVariants>
>(({ size, variant, invalid, ...props }, ref) => {
  return (
    <AsyncSelect
      isMulti
      ref={ref}
      noOptionsMessage={() => 'Sin resultados'}
      {...getCommonProps({
        size,
        variant,
        invalid,
      })}
      {...props}
    />
  )
})

MultiSelectAsync.displayName = 'MultiSelectAsync'

/**
 * Use the Async component to load options from a remote source as the user types or create option.
 */
export const SelectAsyncCreatable = React.forwardRef<
  React.ElementRef<typeof AsyncSelectCreatable>,
  React.ComponentPropsWithRef<typeof AsyncSelectCreatable> &
    VariantProps<typeof controlVariants>
>(({ size, variant, invalid, ...props }, ref) => {
  return (
    <AsyncSelectCreatable
      ref={ref}
      noOptionsMessage={() => 'Sin resultados'}
      formatCreateLabel={(inputValue) => `Agregar "${inputValue}"`}
      {...getCommonProps({
        size,
        variant,
        invalid,
      })}
      {...props}
    />
  )
})

SelectAsyncCreatable.displayName = 'SelectAsyncCreatable'

export const SelectAsyncPaginate = React.forwardRef<
  React.ElementRef<typeof AsyncPaginate>,
  React.ComponentPropsWithRef<typeof AsyncPaginate> &
    VariantProps<typeof controlVariants>
>(({ size, variant, invalid, ...props }, ref) => {
  return (
    <AsyncPaginate
      ref={ref}
      noOptionsMessage={() => 'Sin resultados'}
      {...getCommonProps({
        size,
        variant,
        invalid,
      })}
      {...props}
    />
  )
})

SelectAsyncPaginate.displayName = 'SelectAsyncPaginate'
