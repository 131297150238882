import React from 'react';
import { useTheme } from '@chakra-ui/react';
import { IIcon } from './BikeIcon';

function CarFrontIcon({ height, width }: IIcon) {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Capa_1"
      data-name="Capa 1"
      viewBox="0 0 1811.89 1276.82"
      width={width}
      height={height}
    >
      <defs>
        <style>
          {`.car-front-cls-1{fill:${theme.colors.brand[100]}}.car-front-cls-2{fill:${theme.colors.primary}}.car-front-cls-3{fill:${theme.colors.brand[50]}}`}
        </style>
      </defs>
      <path
        className="car-front-cls-1"
        d="M113.43 942.07v224.23c0 23.71 21.38 42.94 47.75 42.94H289.1c26.37 0 47.74-19.23 47.74-42.94V946.49Z"
      />
      <path
        className="car-front-cls-2"
        d="M289.1 1223.06H161.18c-33.95 0-61.57-25.47-61.57-56.76V942.07a13.83 13.83 0 0 1 14.1-13.82l223.4 4.42a13.82 13.82 0 0 1 13.55 13.82v219.81c0 31.29-27.66 56.76-61.56 56.76Zm-161.85-266.9v210.14c0 16 15.22 29.12 33.93 29.12H289.1c18.7 0 33.92-13.07 33.92-29.12V960Z"
      />
      <path
        className="car-front-cls-1"
        d="M1483.81 942.07v224.23c0 23.71 21.38 42.94 47.74 42.94h127.93c26.36 0 47.74-19.23 47.74-42.94V946.49Z"
      />
      <path
        className="car-front-cls-2"
        d="M1659.48 1223.06h-127.93c-33.94 0-61.56-25.47-61.56-56.76V942.07a13.83 13.83 0 0 1 14.1-13.82l223.4 4.42a13.82 13.82 0 0 1 13.51 13.82v219.81c0 31.29-27.58 56.76-61.52 56.76Zm-161.85-266.9v210.14c0 16 15.22 29.12 33.92 29.12h127.93c18.7 0 33.92-13.07 33.92-29.12V960Z"
      />
      <path
        className="car-front-cls-3"
        d="m275.55 387.79-30.61-34.59a64 64 0 0 0-69.67-27.2l-45 11.61a43 43 0 0 0-32.22 41.6 43 43 0 0 0 37.12 42.56l106.35 14.56Z"
      />
      <path
        className="car-front-cls-2"
        d="M241.52 450.17a13.3 13.3 0 0 1-1.88-.13L133.3 435.48a56.78 56.78 0 0 1-6.48-111.23l45-11.61a77.66 77.66 0 0 1 84.12 32.14l30 33.85a13.81 13.81 0 0 1 1 17.09l-34 48.56a13.81 13.81 0 0 1-11.42 5.89ZM191.29 337.8a50.08 50.08 0 0 0-12.56 1.6l-45 11.61a29.14 29.14 0 0 0 3.33 57.09l98 13.42L258 388.79l-23.39-26.44a12.55 12.55 0 0 1-1.25-1.65 50.07 50.07 0 0 0-42.07-22.9Z"
      />
      <path
        className="car-front-cls-3"
        d="M1695.16 615.8c-49.5-114.19-96.28-125.86-154.31-216.49-47.51-74.2-108.89-164.68-152-228.08a178.45 178.45 0 0 0-147.09-78.13l-326.47-.87h-14.18l-326.47.87a178.43 178.43 0 0 0-147.1 78.13c-43.1 63.4-104.47 153.88-152 228.08-58 90.63-104.81 102.3-154.31 216.49C94.32 677.92 95 933.68 95 933.68c0 103.1-1.15 166.16 137 166.16h1352.36c138.19 0 137-63.06 137-166.16.03 0 .72-255.76-26.2-317.88Z"
      />
      <path
        className="car-front-cls-2"
        d="M1707.84 608c-30.57-70.54-60.17-103.16-91.51-137.7-20.26-22.33-41.21-45.41-63.84-80.76-45.58-71.17-102.46-155.21-152.2-228.4A192.14 192.14 0 0 0 1241.8 77l-326.55-.9H901.2l-326.6.9a192.14 192.14 0 0 0-158.49 84.17c-49.73 73.17-106.6 157.2-152.2 228.4-22.63 35.35-43.58 58.44-63.84 80.76-31.34 34.54-60.94 67.17-91.51 137.7-27.75 64-27.4 312.85-27.37 323.37v2.53c0 53.4 0 95.57 19.13 127.08 21.15 34.83 61.77 50.36 131.73 50.36h1352.31c70 0 110.58-15.53 131.73-50.36 19.13-31.51 19.13-73.68 19.12-127.08v-2.49c.03-10.58.38-259.44-27.37-323.44Zm-.26 323.37v2.54c0 49.07 0 87.83-15.12 112.73-10.48 17.25-32.24 37.06-108.1 37.06H232.05c-75.87 0-97.63-19.81-108.11-37.06-15.12-24.9-15.12-63.66-15.11-112.73v-2.58c-.19-70.34 4-263.57 25.09-312.34 28.75-66.34 56.86-97.32 86.62-130.11 21-23.18 42.77-47.14 66.65-84.43 45.4-70.9 102.15-154.74 151.81-227.76a164.53 164.53 0 0 1 135.71-72.07l333.52-.9 333.53.9a164.54 164.54 0 0 1 135.71 72.07c49.63 73 106.39 156.89 151.77 227.77 23.88 37.29 45.63 61.25 66.65 84.43 29.76 32.79 57.87 63.77 86.62 130.11 21.11 48.77 25.25 242 25.07 312.38Z"
      />
      <path
        className="car-front-cls-3"
        d="m1540.85 387.79 30.61-34.59a64 64 0 0 1 69.67-27.2l45 11.61a43 43 0 0 1 32.23 41.6 43 43 0 0 1-37.13 42.56l-106.35 14.56Z"
      />
      <path
        className="car-front-cls-2"
        d="M1574.88 450.17a13.8 13.8 0 0 1-11.31-5.89l-34-48.56a13.84 13.84 0 0 1 1-17.09l30-33.85a77.65 77.65 0 0 1 84.12-32.14l45 11.61a56.78 56.78 0 0 1-6.48 111.23L1576.76 450a13.3 13.3 0 0 1-1.88.17Zm-16.46-61.38 22.94 32.73 98-13.42a29.14 29.14 0 0 0 3.33-57.09l-45-11.61a50.06 50.06 0 0 0-54.61 21.3 13.57 13.57 0 0 1-1.26 1.65Z"
      />
      <path
        d="M1335.71 166.39c-31.5-48.11-78-75.87-127-75.7l-630.52 2.17c-54.52.19-105.46 34.86-136.1 92.67-27.32 51.54-62.47 118.35-96 183.62L1470 370.89c-37.91-76.25-102.47-155.89-134.29-204.5Z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        className="car-front-cls-2"
        d="M1470 384.71 346 383a13.82 13.82 0 0 1-12.27-20.14c28-54.5 60.37-116.33 96.12-183.77C463 116.63 518.39 79.25 578.14 79l630.54-2.17h.49c53.67 0 104 29.84 138.1 81.95 9.4 14.35 21.54 31.23 35.6 50.78 32.29 44.91 72.48 100.8 99.5 155.14a13.82 13.82 0 0 1-12.38 20ZM368.7 355.37 1447.11 357c-25.39-46.07-59-92.79-86.68-131.29-13.66-19-26.55-36.92-36.28-51.78-28.92-44.16-70.81-69.46-115-69.46h-.4l-630.53 2.18c-49.38.17-95.71 32.06-123.94 85.32-31.35 59.21-60.09 114.03-85.58 163.4Z"
      />
      <path
        className="car-front-cls-1"
        d="M224 541.32c161.31 17 228.73 50.24 253 66.54a8.21 8.21 0 0 1-1.75 14.51l-60.75 22.56a27.58 27.58 0 0 1-12.92 1.54L179 626.31a19.56 19.56 0 0 1-17.22-19.42l50.82-60.29a14.87 14.87 0 0 1 11.4-5.28Z"
      />
      <path
        className="car-front-cls-2"
        d="M404.86 660.48a41 41 0 0 1-4.7-.27l-222.38-20.14h-.41A33.41 33.41 0 0 1 148 606.89a13.82 13.82 0 0 1 3.25-8.91l50.83-60.28A28.61 28.61 0 0 1 224 527.5a13.9 13.9 0 0 1 1.44.08c161.77 17 231.89 50.42 259.29 68.81a22 22 0 0 1-4.65 38.94l-60.75 22.56a41.72 41.72 0 0 1-14.47 2.59Zm-224.33-47.91 222.31 20.13h.41a13.9 13.9 0 0 0 6.45-.77l49.94-18.55c-28.07-15.75-95.05-43.3-236-58.25a1 1 0 0 0-.49.33l-46.33 55a5.78 5.78 0 0 0 3.71 2.11ZM535.54 670.3H1284a16.18 16.18 0 0 1 16.18 16.18v39.1H519.37v-39.1a16.18 16.18 0 0 1 16.17-16.18Z"
      />
      <path
        className="car-front-cls-2"
        d="m854.48 723.27 55.27 55.28 55.28-55.28H854.48z"
      />
      <path
        className="car-front-cls-1"
        d="m439.6 917.58-279.17-64.51a12.37 12.37 0 0 0-15.21 14.11l17.55 101.22a69.2 69.2 0 0 0 62.5 57.16l160.35 11.33a49 49 0 0 0 47.65-26.56l25.63-50.23a29.86 29.86 0 0 0-19.3-42.52Z"
      />
      <path
        className="car-front-cls-2"
        d="M389.72 1050.88c-1.73 0-3.48-.08-5.24-.22l-160.18-11.31a83.09 83.09 0 0 1-75.15-68.59l-17.54-101.22a26.19 26.19 0 0 1 32-29.91l279.06 64.49.26.06a43.67 43.67 0 0 1 28.24 62.2l-25.63 50.23a62.55 62.55 0 0 1-55.82 34.27ZM159.21 867l17.17 99a55.4 55.4 0 0 0 50 45.75l160.18 11.31a35.1 35.1 0 0 0 34.44-19.01l25.63-50.23a16 16 0 0 0-10.3-22.82ZM475.93 595.08h861.38v27.64H475.93z"
      />
      <path
        className="car-front-cls-1"
        d="M1590.75 541.32c-161.32 17-228.74 50.24-253 66.54a8.21 8.21 0 0 0 1.76 14.51l60.74 22.56a27.61 27.61 0 0 0 12.92 1.54l222.57-20.16a19.56 19.56 0 0 0 17.23-19.42l-50.83-60.29a14.85 14.85 0 0 0-11.36-5.28Z"
      />
      <path
        className="car-front-cls-2"
        d="M1409.88 660.48a41.77 41.77 0 0 1-14.47-2.59l-60.74-22.56a22 22 0 0 1-4.65-38.94c27.4-18.39 97.52-51.78 259.29-68.81a13.9 13.9 0 0 1 1.44-.08 28.61 28.61 0 0 1 21.93 10.2l50.82 60.3a13.83 13.83 0 0 1 3.26 8.91 33.41 33.41 0 0 1-29.39 33.09h-.41l-222.39 20.14a41 41 0 0 1-4.69.34Zm-54.79-47.05L1405 632a13.94 13.94 0 0 0 6.46.77h.41l222.3-20.13a5.75 5.75 0 0 0 3.67-2.11l-46.32-55a1 1 0 0 0-.5-.33c-140.87 14.93-207.85 42.48-235.93 58.23ZM100.53 763.21a14.24 14.24 0 0 1-2-.15 13.83 13.83 0 0 1-11.64-15.7c5.84-39.31 16.71-112.47 94.3-187.55 98.51-95.33 169-226.48 169.74-227.8a13.82 13.82 0 0 1 24.39 13c-2.94 5.51-73.2 136.22-174.92 234.65-70.84 68.56-80.81 135.69-86.17 171.75a13.82 13.82 0 0 1-13.7 11.8Z"
      />
      <path
        className="car-front-cls-2"
        d="M1715 763.21a13.82 13.82 0 0 1-13.65-11.79c-5.36-36.06-15.33-103.19-86.18-171.75-101.71-98.43-172-229.14-174.91-234.65a13.82 13.82 0 0 1 24.39-13c.7 1.32 71.23 132.47 169.74 227.8 77.59 75.08 88.46 148.24 94.29 187.55a13.81 13.81 0 0 1-11.63 15.7 14.35 14.35 0 0 1-2.05.14Z"
      />
      <path
        className="car-front-cls-1"
        d="m1374.47 917.58 279.17-64.51a12.38 12.38 0 0 1 15.21 14.11L1651.3 968.4a69.2 69.2 0 0 1-62.51 57.16l-160.34 11.33a49 49 0 0 1-47.66-26.56l-25.62-50.23a29.85 29.85 0 0 1 19.3-42.52Z"
      />
      <path
        className="car-front-cls-2"
        d="M1424.24 1050.89a62.54 62.54 0 0 1-55.76-34.28l-25.62-50.23a43.67 43.67 0 0 1 28.24-62.2l.26-.06 279.05-64.48a26.18 26.18 0 0 1 32 29.9l-17.54 101.22a83 83 0 0 1-75 68.57l-160.51 11.34c-1.67.14-3.36.22-5.12.22ZM1377.74 931a16 16 0 0 0-10.26 22.81l25.62 50.23a35 35 0 0 0 34.21 19.06l160.51-11.33a55.38 55.38 0 0 0 49.87-45.77l17.17-99Z"
      />
    </svg>
  );
}

export default CarFrontIcon;
