import * as React from 'react'
import { cn } from '../utils/cn'
import { VariantProps, cva } from 'class-variance-authority'

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const variants = cva(
  'flex h-20 w-full rounded-md border border-input px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        outline: 'bg-transparent',
        filled: 'bg-gray-100',
      },
      invalid: {
        true: 'border-red-500 border-2 outline-none',
        false:
          'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring/75',
      },
      resize: {
        false: 'resize-none',
      },
    },
    defaultVariants: {
      variant: 'outline',
      invalid: false,
      resize: true,
    },
  },
)

/**
 * Displays a form textarea or a component that looks like a textarea.
 */
const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  TextareaProps & VariantProps<typeof variants>
>(({ className, variant, invalid, resize, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        variants({
          variant,
          className,
          invalid,
          resize,
        }),
      )}
      ref={ref}
      {...props}
    />
  )
})

Textarea.displayName = 'Textarea'

export { Textarea }
