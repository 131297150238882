import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useTheme,
  useToast,
} from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { messageTemplateVariables } from 'ai-constants'
import debounce from 'lodash.debounce'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { BiMessageAltError } from 'react-icons/bi'
import { IoMdAdd } from 'react-icons/io'
import { useError } from '../hooks/useError'
import { useMessageTemplateEdit } from '../hooks/useMessageTemplateEdit'
import { useMessageTemplate } from '../hooks/useMessageTemplates'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import {
  DeliveryChannel,
  deliveryChannels,
  IMessageTemplate,
  MessageTemplateCtx,
} from '../providers/MessageTemplate.provider'
import { IMessageTemplateSchema } from '../schemas/message-template.schema'
import { CreateMessageTemplate } from './CreateMessageTemplate'
import { MessageTemplateEditor } from './MessageTemplateEditor'
import { TimelineStyles } from './TimelineStyles'
import { TranslatorOnlyString } from './Translator'

export const MESSAGE_TEMPLATES_QUERY_KEY = 'message-templates'

const MessageTemplateGatewayStatus = (props: {
  gatewayStatus?: IMessageTemplate['gatewayStatus']
}) => {
  return (
    <Box maxW='md'>
      {(() => {
        switch (props.gatewayStatus) {
          case 'pending':
            return (
              <Alert borderRadius={'lg'} status='info'>
                <AlertIcon />
                El proveedor de mensajería está revisando la plantilla
              </Alert>
            )

          case 'rejected':
            return (
              <Alert alignItems={'start'} borderRadius={'lg'} status='error'>
                <AlertIcon
                  style={{
                    marginTop: '5px',
                  }}
                />
                El proveedor de mensajería rechazó la plantilla. Para saber mas
                detalles, contacta con soporte.
              </Alert>
            )

          case 'approved':
            return (
              <Alert status='success'>
                <AlertIcon />
                El proveedor de mensajería acepto la plantilla, por lo que la
                plantilla sera tomada en cuenta.
              </Alert>
            )

          default:
            return null
        }
      })()}
    </Box>
  )
}

const MessageTemplateEdit = (props: { messageTemplate: IMessageTemplate }) => {
  const { debounceSwitch, formId, isLoading, onUpdateMessageTemplate } =
    useMessageTemplateEdit(props)

  const toast = useToast()

  return (
    <Box pos='relative'>
      {props.messageTemplate.gatewayStatus && (
        <Box>
          <MessageTemplateGatewayStatus
            gatewayStatus={props.messageTemplate.gatewayStatus}
          />
        </Box>
      )}

      <Box top={0} right={0} pos='absolute'>
        <FormControl display='flex' alignItems='center'>
          <FormLabel htmlFor='email-alerts' mb='0'>
            Activar
          </FormLabel>
          <Switch
            defaultChecked={props.messageTemplate.status === 'active'}
            onChange={(e) => debounceSwitch(e.target.checked)}
          />
        </FormControl>
      </Box>

      <Box py={8}>
        <MessageTemplateEditor
          gatewayStatus={props.messageTemplate.gatewayStatus}
          defaultValues={props.messageTemplate.template}
          onSubmit={(data) =>
            onUpdateMessageTemplate(data, () => {
              toast({
                description: 'Plantilla de mensaje actualizada',
                status: 'success',
                isClosable: true,
              })
            })
          }
          formId={formId}
          channel={props.messageTemplate.channel}
        >
          <Button
            mt='4'
            isLoading={isLoading}
            isDisabled={isLoading}
            type='submit'
            variant='primary'
            form={formId}
          >
            Guardar
          </Button>
        </MessageTemplateEditor>
      </Box>
    </Box>
  )
}

const MultipleMessageTemplatesDetail = (props: {
  messageTemplates: IMessageTemplate[]
  channel: IMessageTemplate['channel']
}) => {
  const [currentMessageTemplateId, setCurrentMessageTemplateId] = useState('')

  const currentMessageTemplate = props.messageTemplates.find(
    (messageTemplate) => messageTemplate._id === currentMessageTemplateId,
  )

  return (
    <Box>
      <Box>
        <Box display={'flex'} flexDir='row' alignItems={'center'}>
          <FormLabel>Selecciona una plantilla:</FormLabel>
          <Select
            maxW='2xl'
            bg='white'
            onChange={(e) => setCurrentMessageTemplateId(e.target.value)}
          >
            <option selected disabled>
              Ninguna seleccionada
            </option>
            {props.messageTemplates.map((messageTemplate) => {
              return (
                <option key={messageTemplate._id} value={messageTemplate._id}>
                  {messageTemplate._id}
                </option>
              )
            })}
          </Select>
        </Box>
        <CreateMessageTemplate channel={props.channel} />
      </Box>

      {currentMessageTemplate && (
        <Box>
          <Divider my='10' />
          <Box>
            <Text color='gray.700' fontWeight={'bold'} fontSize='2xl'>
              EDITOR
            </Text>
            <MessageTemplateEdit
              key={currentMessageTemplate._id}
              messageTemplate={currentMessageTemplate}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export const MessageTemplates = (props: {
  messageTemplates: IMessageTemplate[]
}) => {
  const {
    currentChannel,
    currentMessageTemplates,
    onCreateMessageTemplate,
    setTabIndex,
    isLoading,
    tabIndex,
    channels,
    multiple,
    createMessageTemplate,
    setCreateMessageTemplate,
    formId,
  } = useMessageTemplate({
    messageTemplates: props.messageTemplates,
  })

  const theme = useTheme()

  const toast = useToast()

  return (
    <Box>
      <TimelineStyles />
      <Box py='2'>
        <Box minH={'20rem'} mt='5'>
          <Tabs onChange={setTabIndex} index={tabIndex}>
            <TabList>
              {channels.map((channel, index) => {
                return (
                  <Tab textTransform={'uppercase'} key={index}>
                    {TranslatorOnlyString({ children: channel })}
                  </Tab>
                )
              })}
            </TabList>
          </Tabs>
          <Box mt='5' key={currentChannel}>
            {!currentMessageTemplates.length ? (
              <Box
                display='flex'
                flexDir={'column'}
                w='full'
                alignItems={'center'}
                justifyContent='center'
              >
                <BiMessageAltError
                  color={theme.colors['gray']['500']}
                  fontSize={'3rem'}
                />
                <Box>
                  <Text fontSize={'sm'}>
                    No hay plantilla de mensaje para este canal
                  </Text>
                </Box>
                <CreateMessageTemplate channel={currentChannel} />
              </Box>
            ) : multiple ? (
              <MultipleMessageTemplatesDetail
                channel={currentChannel}
                messageTemplates={currentMessageTemplates}
              />
            ) : (
              <MessageTemplateEdit
                messageTemplate={currentMessageTemplates[0]}
              />
            )}
          </Box>
          <Modal
            size='6xl'
            isOpen={!!createMessageTemplate}
            onClose={() => setCreateMessageTemplate(undefined)}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                Crear plantilla para{' '}
                {TranslatorOnlyString({ children: createMessageTemplate })}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <MessageTemplateEditor
                  onSubmit={(data) =>
                    onCreateMessageTemplate(data, () => {
                      toast({
                        description: 'Plantilla de mensaje creada',
                        status: 'success',
                      })
                    })
                  }
                  formId={formId}
                  channel={createMessageTemplate}
                />
              </ModalBody>

              <ModalFooter>
                <Button
                  type='submit'
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  form={formId}
                  variant='primary'
                  mr={3}
                >
                  Crear
                </Button>
                <Button
                  isDisabled={isLoading}
                  onClick={() => setCreateMessageTemplate(undefined)}
                  variant='ghost'
                >
                  Cancelar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      </Box>
    </Box>
  )
}
