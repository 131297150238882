import React, { createContext } from 'react';
import { QueryClient } from '@tanstack/react-query';

export interface AIComponentsContext {
  queryClientCtx: React.Context<QueryClient | undefined>;
}

export const AIComponentsContext = createContext<AIComponentsContext>(
  {} as any
);

export const AIComponentsProvider: React.FC<
  React.PropsWithChildren<AIComponentsContext>
> = ({ children, ...rest }) => {
  return (
    <AIComponentsContext.Provider value={rest}>
      {children}
    </AIComponentsContext.Provider>
  );
};
