import React from 'react';
import { useTheme } from '@chakra-ui/react';

export interface IIcon {
  width: string;
  height: string;
}

function BikeIcon(props: IIcon) {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 425.05 254.09"
      {...props}
    >
      <defs>
        <style>
          {`.bike-cls-1{fill:${theme.colors.brand[100]}}.bike-cls-2{fill:${theme.colors.brand[50]}}.bike-cls-3{fill:${theme.colors.primary}}.bike-cls-4{fill:#fff}`}
        </style>
      </defs>
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path
            className="bike-cls-1"
            d="M345.89 250.53a75.6 75.6 0 1 1 0-151.2"
          />
          <path
            className="bike-cls-2"
            d="M345.89 99.33a75.6 75.6 0 1 1 0 151.2"
          />
          <path
            className="bike-cls-3"
            d="M345.89 95.77a79.16 79.16 0 1 0 79.16 79.16 79.25 79.25 0 0 0-79.16-79.16Zm0 151.2a72 72 0 1 1 72.05-72A72.13 72.13 0 0 1 345.89 247Z"
          />
          <path
            className="bike-cls-4"
            d="M344.63 122.1a63.33 63.33 0 0 1 8 .53l-1.42-.19a61 61 0 0 1 15.39 4.19l-1.28-.54A61.07 61.07 0 0 1 378.8 134l-1.08-.83a61.5 61.5 0 0 1 10.82 10.81l-.84-1.08a60.68 60.68 0 0 1 7.87 13.45L395 155a60.36 60.36 0 0 1 4.19 15.38L399 169a61.49 61.49 0 0 1 .53 8 5.34 5.34 0 1 0 10.67 0 65.46 65.46 0 0 0-11.92-37.68A68.75 68.75 0 0 0 384 124.63a65.32 65.32 0 0 0-39.4-13.2 5.34 5.34 0 0 0 0 10.67Z"
          />
          <circle className="bike-cls-4" cx={345.83} cy={174.93} r={11.34} />
          <path
            className="bike-cls-3"
            d="M345.83 189.82a14.9 14.9 0 1 1 14.9-14.89 14.91 14.91 0 0 1-14.9 14.89Zm0-22.67a7.78 7.78 0 1 0 7.78 7.78 7.79 7.79 0 0 0-7.78-7.78Z"
          />
          <path
            className="bike-cls-1"
            d="M79.16 250.53a75.6 75.6 0 0 1 0-151.2"
          />
          <path
            className="bike-cls-2"
            d="M79.16 99.33a75.6 75.6 0 0 1 0 151.2"
          />
          <path
            className="bike-cls-3"
            d="M79.16 95.77a79.16 79.16 0 1 0 79.16 79.16 79.25 79.25 0 0 0-79.16-79.16Zm0 151.2a72 72 0 1 1 72-72 72.13 72.13 0 0 1-72 72.03Z"
          />
          <path
            className="bike-cls-3"
            d="M186.62 212.57a3.48 3.48 0 0 1-.79-.09L73.3 187.08a3.54 3.54 0 0 1-2.77-3.47v-17.23a3.55 3.55 0 0 1 2.82-3.48l112.54-23.58a3.55 3.55 0 0 1 .73-.08c18.43 0 33.43 16.45 33.43 36.66s-15 36.67-33.43 36.67Zm-109-31.8L187 205.45c14.34-.23 25.93-13.39 25.93-29.55s-11.61-29.33-26-29.54L77.64 169.27Z"
          />
          <circle className="bike-cls-4" cx={79.16} cy={174.93} r={11.34} />
          <path
            className="bike-cls-3"
            d="M79.16 189.82a14.9 14.9 0 1 1 14.89-14.89 14.92 14.92 0 0 1-14.89 14.89Zm0-22.67a7.78 7.78 0 1 0 7.78 7.78 7.79 7.79 0 0 0-7.78-7.78Z"
          />
          <path
            className="bike-cls-3"
            d="m338.91 165.78-43.22-111H151.41L88 163.63l-6.15-3.57L146.29 49.4a3.56 3.56 0 0 1 3.08-1.77h148.76a3.56 3.56 0 0 1 3.31 2.26l44.09 113.31Z"
          />
          <path
            className="bike-cls-3"
            transform="rotate(-16.2 162.675 98.1)"
            d="M159.14 49.34h7.11v97.51h-7.11z"
          />
          <path
            className="bike-cls-3"
            transform="rotate(-45.49 257.055 110.304)"
            d="M191.56 106.75h130.98v7.11H191.56z"
          />
          <path className="bike-cls-3" d="M146.75 24.64h7.11v27.24h-7.11z" />
          <rect
            className="bike-cls-2"
            x={112.77}
            y={3.45}
            width={72.65}
            height={21.19}
            rx={6.2}
          />
          <path
            className="bike-cls-3"
            d="M175.05 27.61h-51.9a13.36 13.36 0 0 1-13.34-13.34v-.44A13.35 13.35 0 0 1 123.15.49h51.9a13.36 13.36 0 0 1 13.34 13.34v.44a13.36 13.36 0 0 1-13.34 13.34Zm-51.9-21.19a7.41 7.41 0 0 0-7.41 7.41v.44a7.41 7.41 0 0 0 7.41 7.41h51.9a7.42 7.42 0 0 0 7.41-7.41v-.44a7.42 7.42 0 0 0-7.41-7.41Z"
          />
          <path
            className="bike-cls-4"
            d="M87.33 226.91a61.14 61.14 0 0 1-14.56-.16l1.41.19a60.76 60.76 0 0 1-15.48-4.23l1.28.54a61.26 61.26 0 0 1-13.32-7.78l1.08.83a61 61 0 0 1-10.9-10.88l.83 1.08A61 61 0 0 1 29.8 193l.54 1.27A61 61 0 0 1 26.18 179l.19 1.42c-.07-.49-.13-1-.18-1.46a5.79 5.79 0 0 0-1.56-3.77 5.32 5.32 0 0 0-7.55 0 5.17 5.17 0 0 0-1.56 3.77 70.35 70.35 0 0 0 2 10.77 67.36 67.36 0 0 0 3.66 10 64.61 64.61 0 0 0 10.77 16.43A65.7 65.7 0 0 0 64.86 236a64.9 64.9 0 0 0 22.47 1.58 5.84 5.84 0 0 0 3.78-1.58 5.46 5.46 0 0 0 1.56-3.78 5.4 5.4 0 0 0-1.56-3.77 5.17 5.17 0 0 0-3.78-1.56Z"
          />
          <rect
            className="bike-cls-2"
            x={241.84}
            y={2.96}
            width={74.68}
            height={17.78}
            rx={5.21}
          />
          <path
            className="bike-cls-3"
            d="M307.63 23.71h-56.9a11.86 11.86 0 1 1 0-23.71h56.9a11.86 11.86 0 0 1 0 23.71Zm-56.9-17.78a5.93 5.93 0 1 0 0 11.85h56.9a5.93 5.93 0 1 0 0-11.85Z"
          />
          <ellipse
            className="bike-cls-2"
            cx={249.08}
            cy={11.85}
            rx={6.79}
            ry={8.89}
          />
          <path
            className="bike-cls-3"
            d="M249.08 23.71c-5.38 0-9.75-5.32-9.75-11.86S243.7 0 249.08 0s9.75 5.32 9.75 11.85-4.37 11.86-9.75 11.86Zm0-17.78c-2 0-3.83 2.77-3.83 5.92s1.79 5.93 3.83 5.93 3.82-2.77 3.82-5.93-1.78-5.92-3.82-5.92Zm50.55 52.66a3.57 3.57 0 0 1-3.35-2.34L277.73 5a3.56 3.56 0 0 1 6.69-2.42L303 53.82a3.56 3.56 0 0 1-2.13 4.56 3.67 3.67 0 0 1-1.24.21Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default BikeIcon;
