'use client'

import * as React from 'react'
import * as SliderPrimitive from '@radix-ui/react-slider'
import { cn } from '../utils/cn'
import { cva } from 'class-variance-authority'

const variants = cva('relative flex touch-none select-none items-center', {
  variants: {
    orientation: {
      vertical: 'flex flex-col w-2 h-40',
      horizontal: 'w-full',
    },
  },
  defaultVariants: {
    orientation: 'horizontal',
  },
})

/**
 * An input where the user selects a value from within a given range.
 */
const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & {
    thumbIcon?: React.ReactNode
  }
>(({ className, orientation = 'horizontal', thumbIcon, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    orientation={orientation}
    className={cn(
      variants({
        orientation,
        className,
      }),
    )}
    {...props}
  >
    <SliderPrimitive.Track className='relative h-2 w-full grow overflow-hidden rounded-full bg-secondary'>
      <SliderPrimitive.Range
        className={cn('absolute h-full bg-primary', {
          'w-full': orientation === 'vertical',
        })}
      />
    </SliderPrimitive.Track>
    {thumbIcon ? (
      <SliderPrimitive.Thumb
        asChild
        className='shadow-lg border block h-5 w-5 rounded-full border-primary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50'
      >
        {thumbIcon}
      </SliderPrimitive.Thumb>
    ) : (
      <SliderPrimitive.Thumb className='block h-5 w-5 rounded-full border-2 border-primary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50' />
    )}
  </SliderPrimitive.Root>
))
Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }
