import React, { useState } from 'react'
import { BiMessageAltError } from 'react-icons/bi'
import {
  Alert,
  AlertIcon,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator,
  Switch,
  Tabs,
  TabsList,
  TabsTrigger,
  useToast,
} from 'ui'
import { useMessageTemplateEdit } from '../hooks/useMessageTemplateEdit'
import { useMessageTemplate } from '../hooks/useMessageTemplates'
import {
  IMessageTemplate,
  deliveryChannels,
} from '../providers/MessageTemplate.provider'
import { CreateMessageTemplate } from './CreateMessageTemplate'
import { CreateMessageTemplateV2 } from './CreateMessageTemplateV2'
import { MessageTemplateEditor } from './MessageTemplateEditor'
import { MessageTemplateEditorV2 } from './MessageTemplateEditorV2'
import { TimelineStyles } from './TimelineStyles'
import { TranslatorOnlyString } from './Translator'

const MessageTemplateGatewayStatus = (props: {
  gatewayStatus?: IMessageTemplate['gatewayStatus']
}) => {
  return (
    <div className='max-w-md w-full'>
      {(() => {
        switch (props.gatewayStatus) {
          case 'pending':
            return (
              <Alert className='rounded-lg' status='info'>
                <AlertIcon />
                El proveedor de mensajería está revisando la plantilla
              </Alert>
            )

          case 'rejected':
            return (
              <Alert className='items-start rounded-lg' status='error'>
                <AlertIcon className='mt-[5px]' />
                El proveedor de mensajería rechazó la plantilla. Para saber mas
                detalles, contacta con soporte.
              </Alert>
            )

          case 'approved':
            return (
              <Alert status='success'>
                <AlertIcon />
                El proveedor de mensajería acepto la plantilla, por lo que la
                plantilla sera tomada en cuenta.
              </Alert>
            )

          default:
            return null
        }
      })()}
    </div>
  )
}

const MessageTemplateEdit = (props: { messageTemplate: IMessageTemplate }) => {
  const { debounceSwitch, formId, isLoading, onUpdateMessageTemplate } =
    useMessageTemplateEdit(props)

  const { toast } = useToast()

  return (
    <div className='relative'>
      {props.messageTemplate.gatewayStatus && (
        <div>
          <MessageTemplateGatewayStatus
            gatewayStatus={props.messageTemplate.gatewayStatus}
          />
        </div>
      )}

      <div className='top-0 right-0 absolute'>
        <div className='flex items-center'>
          <label className='mb-0 mr-2 text-sm' htmlFor='email-alerts'>
            Activar
          </label>
          <Switch
            size='md'
            defaultChecked={props.messageTemplate.status === 'active'}
            onCheckedChange={debounceSwitch}
          />
        </div>
      </div>

      <div>
        <MessageTemplateEditorV2
          gatewayStatus={props.messageTemplate.gatewayStatus}
          defaultValues={props.messageTemplate.template}
          onSubmit={(data) =>
            onUpdateMessageTemplate(data, () => {
              toast({
                description: 'Plantilla de mensaje actualizada',
                variant: 'success',
              })
            })
          }
          formId={formId}
          channel={props.messageTemplate.channel}
        >
          <Button
            className='mt-4'
            loading={isLoading}
            disabled={isLoading}
            type='submit'
            form={formId}
            size='sm'
          >
            Guardar
          </Button>
        </MessageTemplateEditorV2>
      </div>
    </div>
  )
}

const MultipleMessageTemplatesDetail = (props: {
  messageTemplates: IMessageTemplate[]
  channel: IMessageTemplate['channel']
}) => {
  const [currentMessageTemplateId, setCurrentMessageTemplateId] = useState('')

  const currentMessageTemplate = props.messageTemplates.find(
    (messageTemplate) => messageTemplate._id === currentMessageTemplateId,
  )

  return (
    <div>
      <div>
        <div className='flex flex-row items-center'>
          <label>Selecciona una plantilla:</label>
          <Select onValueChange={setCurrentMessageTemplateId}>
            <SelectTrigger className='max-w-2xl w-full bg-white'>
              <SelectValue placeholder='Ninguna seleccionada' />
            </SelectTrigger>
            <SelectContent>
              {props.messageTemplates.map((messageTemplate) => {
                return (
                  <SelectItem
                    key={messageTemplate._id}
                    value={messageTemplate._id}
                  >
                    {messageTemplate._id}
                  </SelectItem>
                )
              })}
            </SelectContent>
          </Select>
        </div>
        <CreateMessageTemplate channel={props.channel} />
      </div>

      {currentMessageTemplate && (
        <div>
          <Separator className='my-10' />
          <div>
            <p className='text-gray-700 font-bold text-2xl'>EDITOR</p>
            <MessageTemplateEdit
              key={currentMessageTemplate._id}
              messageTemplate={currentMessageTemplate}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export const MessageTemplatesV2 = (props: {
  messageTemplates: IMessageTemplate[]
  currentChannel: (typeof deliveryChannels)[number]
}) => {
  const {
    currentMessageTemplates,
    onCreateMessageTemplate,
    setTabIndex,
    isLoading,
    tabIndex,
    channels,
    multiple,
    createMessageTemplate,
    setCreateMessageTemplate,
    formId,
  } = useMessageTemplate({
    messageTemplates: props.messageTemplates,
    currentChannel: props.currentChannel,
  })

  const { toast } = useToast()

  return (
    <div>
      <TimelineStyles />
      <div>
        <div className='mt-5 min-w-[20rem] p-6 bg-white rounded'>
          <div className='mt-5' key={props.currentChannel}>
            {!currentMessageTemplates.length ? (
              <div className='flex flex-col w-full items-start justify-center'>
                <MessageTemplateEditorV2
                  onSubmit={(data) =>
                    onCreateMessageTemplate(data, props.currentChannel, () => {
                      toast({
                        description: 'Plantilla de mensaje creada',
                        variant: 'success',
                      })
                    })
                  }
                  formId={formId}
                  channel={props.currentChannel}
                >
                  <Button
                    type='submit'
                    disabled={isLoading}
                    loading={isLoading}
                    form={formId}
                    variant='primary'
                    className='w-24'
                    size='sm'
                  >
                    Guardar
                  </Button>
                </MessageTemplateEditorV2>
              </div>
            ) : multiple ? (
              <MultipleMessageTemplatesDetail
                channel={props.currentChannel}
                messageTemplates={currentMessageTemplates}
              />
            ) : (
              <MessageTemplateEdit
                messageTemplate={currentMessageTemplates[0]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
