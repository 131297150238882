'use client'

import * as React from 'react'
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'
import { cn } from '../utils/cn'

export interface ScrollAreaProps
  extends React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> {
  withScrollbar?: boolean
  orientation: 'vertical' | 'horizontal'
  withContentPadding?: boolean
  viewportClassName?: string
  viewportId?: string
  viewportRef?: React.RefObject<HTMLDivElement>
  viewportStyle?: React.CSSProperties
}

/**
 * Augments native scroll functionality for custom, cross-browser styling.
 */
const ScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  ScrollAreaProps
>(
  (
    {
      className,
      children,
      withContentPadding,
      orientation = 'vertical',
      viewportClassName,
      viewportId,
      viewportRef,
      onThumbRendered,
      viewportStyle,
      withScrollbar = true,
      ...props
    },
    ref,
  ) => (
    <ScrollAreaPrimitive.Root
      ref={ref}
      className={cn('relative overflow-hidden h-full', className)}
      {...props}
    >
      <ScrollAreaPrimitive.Viewport
        className={cn(
          'h-full w-full rounded-[inherit]',
          {
            'px-8 py-8': withContentPadding,
          },
          viewportClassName,
        )}
        id={viewportId}
        ref={viewportRef}
        style={viewportStyle}
      >
        {children}
      </ScrollAreaPrimitive.Viewport>
      <ScrollBar
        withScrollbar={withScrollbar}
        onThumbRendered={onThumbRendered}
        className='h-full'
        orientation={orientation}
      />
      <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
  ),
)
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName

const Notifier = ({ onThumbRendered }: { onThumbRendered: () => void }) => {
  React.useEffect(() => {
    if (onThumbRendered) {
      onThumbRendered()
    }
  }, [])

  return null
}

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar> & {
    withScrollbar?: boolean
  },
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>
>(
  (
    {
      className,
      orientation = 'vertical',
      withScrollbar = true,
      onThumbRendered,
      ...props
    },
    ref,
  ) => (
    <ScrollAreaPrimitive.ScrollAreaScrollbar
      ref={ref}
      orientation={orientation}
      className={cn(
        'flex touch-none select-none transition-colors',
        orientation === 'vertical' &&
          'h-full w-2.5 border-l border-l-transparent p-[1px]',
        orientation === 'horizontal' &&
          'h-2.5 border-t border-t-transparent p-[1px]',
        className,
      )}
      {...props}
    >
      {withScrollbar && (
        <ScrollAreaPrimitive.ScrollAreaThumb className='relative flex-1 rounded-full bg-border'>
          <Notifier onThumbRendered={onThumbRendered} />
        </ScrollAreaPrimitive.ScrollAreaThumb>
      )}
    </ScrollAreaPrimitive.ScrollAreaScrollbar>
  ),
)
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName

export { ScrollArea, ScrollBar }
