import * as yup from 'yup'

export const MessageTemplateSchema = yup.object({
  logo: yup.mixed().nullable().optional(),
  deadlineDays: yup.mixed().nullable().optional(),
  subject: yup
    .string()
    .required('Campo requerido!')
    .max(60, 'Máximo 60 caracteres!'),
  body: yup
    .string()
    .required('Campo requerido!')
    .max(1024, 'Máximo 1024 caracteres!'),
  footer: yup.string().max(60, 'Máximo 60 caracteres!').nullable().optional(),
  buttonLabel: yup
    .string()
    .max(60, 'Máximo 60 caracteres!')
    .when('channel', {
      is: 'email',
      then: (yup) => yup.required('Campo requerido!'),
    }),
  channel: yup.string(),
  type: yup.string().nullable().optional(),
})

export interface IMessageTemplateSchema
  extends yup.InferType<typeof MessageTemplateSchema> {}
