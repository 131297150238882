import {
  Menu,
  MenuList,
  MenuListProps,
  UseDisclosureProps,
  useOutsideClick,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { GiPlantRoots } from 'react-icons/gi';

export interface IMenuCustomProps {
  children: React.ReactNode;
  menuListChildren: React.ReactNode;
  menuListProps?: MenuListProps;
  menuActions?: UseDisclosureProps;
}

export const MenuCustom = (props: IMenuCustomProps) => {
  const ref = useRef<HTMLElement>(null);
  useOutsideClick({
    ref: ref,
    handler: () => {
      if (props.menuActions) {
        // @ts-ignore
        props.menuActions.onClose();
      }
    },
  });

  return (
    <Menu isLazy isOpen={props.menuActions?.isOpen}>
      {({ isOpen }) => (
        <>
          <MenuList
            // @ts-ignore
            ref={ref}
            size="xs"
            sx={{
              opacity: isOpen ? '1!important' : 0,
              ...(isOpen && { transform: 'none!important' }),
            }}
            {...props.menuListProps}
          >
            {props.menuListChildren}
          </MenuList>
          {props.children}
        </>
      )}
    </Menu>
  );
};
