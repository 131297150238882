import tinycolor from 'tinycolor2'
import Color from 'color'

export function hexToHsl(hex: string): string | null {
  // Validar si el formato hexadecimal es válido
  if (!/^#([0-9A-F]{3}){1,2}$/i.test(hex)) {
    return null // Formato no válido
  }

  // Eliminar el '#' del comienzo, si está presente
  hex = hex.replace(/^#/, '')

  // Convertir un valor hexadecimal de 3 caracteres a 6 caracteres
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('')
  }

  // Parsear los componentes RGB
  const r = parseInt(hex.slice(0, 2), 16) / 255
  const g = parseInt(hex.slice(2, 4), 16) / 255
  const b = parseInt(hex.slice(4, 6), 16) / 255

  // Calcular los componentes HSL
  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h = 0
  let s = 0
  const l = (max + min) / 2

  if (max !== min) {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }

    h /= 6
  }

  // Convertir los componentes HSL a cadena y retornar sin parentesis y comas para el uso que necesitamos
  const hsl = `${Math.round(h * 360)} ${Math.round(s * 100)}% ${Math.round(
    l * 100,
  )}%`
  return hsl
}

export const isValidHex = (color: string) => {
  if (!color || typeof color !== 'string') return false

  return tinycolor(color).isValid()
}

export const getLightenColor = (color: string, lighten = 20) => {
  return tinycolor(color).lighten(lighten).toHexString()
}

export const getSecondaryColor = (mainColor: string) => {
  const colorsAmount = 1
  const colorsShiftAmount = 25
  const mixColor = 'white'
  const rotate = 0
  const saturation = 0
  const colorsList = []
  const givenColor = isValidHex(mainColor) ? mainColor : '#000000'

  let step
  for (step = 0; step < colorsAmount; step++) {
    colorsList.push(
      Color(givenColor)
        .rotate(((step + 1) / colorsAmount) * -rotate)
        .saturate(((step + 1) / colorsAmount) * (saturation / 100))
        .mix(
          Color(mixColor),
          ((colorsShiftAmount / 100) * (step + 1)) / colorsAmount,
        )
        .hex(),
    )
  }

  return colorsList
}
