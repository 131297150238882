import React, { useMemo } from 'react'
import { useLocale, useNumberField } from 'react-aria'
import { NumberFieldStateOptions, useNumberFieldState } from 'react-stately'
import { Button as ButtonUi, Input, InputProps, cn } from 'ui'
import { useObjectRef } from '../hooks/useObjectRef'

import { ButtonProps } from '@chakra-ui/react'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import { useButton } from 'react-aria'

const Button = React.forwardRef<
  React.ComponentRef<typeof Button>,
  {
    size?: 'xs' | 'sm' | 'md' | 'lg'
  } & ButtonProps
>(({ className, size, ...props }, ref) => {
  const forwardRef = useObjectRef(ref)
  let { buttonProps } = useButton(props, ref)
  return (
    <ButtonUi
      {...buttonProps}
      size={size}
      className={cn('z-10 bg-transparent hover:bg-transparent', className)}
      ref={forwardRef}
      variant='ghost'
    >
      {props.children}
    </ButtonUi>
  )
})

export const NumberField = React.forwardRef<
  React.ComponentRef<typeof Input>,
  Omit<NumberFieldStateOptions, 'locale' | 'formatOptions'> & {
    formatOptions?: Intl.NumberFormatOptions
    error?: any
    variant?: 'filled' | 'unstyled'
    size?: 'xs' | 'sm' | 'md' | 'lg'
    locale?: string
  } & InputProps
>(
  (
    { error, variant, className, locale, formatOptions, name, size, ...props },
    ref,
  ) => {
    const { locale: inferredLocale } = useLocale()

    const formatOptionsMemoized = useMemo((): Intl.NumberFormatOptions => {
      if (!formatOptions?.useGrouping) {
        return {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          useGrouping: false,
        }
      }

      return formatOptions
    }, [])

    let state = useNumberFieldState({
      ...props,
      formatOptions: formatOptionsMemoized,
      locale: locale || inferredLocale,
    })

    const forwardRef = useObjectRef(ref)

    let { inputProps, groupProps, decrementButtonProps, incrementButtonProps } =
      useNumberField(
        {
          ...props,
          formatOptions: formatOptionsMemoized,
        },
        state,
        forwardRef,
      )

    return (
      <div {...groupProps} className={cn('relative', className)}>
        <Button
          {...decrementButtonProps}
          size={size}
          className='absolute top-0 left-0'
        >
          <IconChevronDown className='w-3 h-3' />
        </Button>
        <Input
          {...inputProps}
          className={cn('[&>input]:text-center', className)}
          name={name}
          size={size}
          invalid={!!error}
          ref={forwardRef}
          variant={variant}
        />
        <Button
          {...incrementButtonProps}
          size={size}
          className='absolute top-0 right-0'
        >
          <IconChevronUp className='w-3 h-3' />
        </Button>
      </div>
    )
  },
)

NumberField.displayName = 'NumberField'

export function NumberFieldV2(props) {
  let { locale } = useLocale()
  let state = useNumberFieldState({ ...props, locale })
  let inputRef = React.useRef(null)
  let {
    labelProps,
    groupProps,
    inputProps,
    incrementButtonProps,
    decrementButtonProps,
  } = useNumberField(props, state, inputRef)

  return (
    <div>
      <label {...labelProps}>{props.label}</label>
      <div {...groupProps}>
        <Button {...decrementButtonProps}>-</Button>
        <Input {...inputProps} ref={inputRef} />
        <Button {...incrementButtonProps}>+</Button>
      </div>
    </div>
  )
}
