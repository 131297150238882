import { IconX } from '@tabler/icons-react'
import React, { forwardRef } from 'react'
import { Button, ButtonProps, cn } from 'ui'

export const SignaturePlaceholder = forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement> & {
    withCloseIcon?: boolean
  }
>(({ children, withCloseIcon = true, ...props }, ref) => {
  return (
    <div className='w-full p-5' {...props}>
      <div className='flex flex-row border-b border-b-[#555d65] items-center space-x-2 pb-2'>
        {withCloseIcon && <IconX color='#555d65' fontSize='1rem' />}

        {children}
      </div>
    </div>
  )
})

export const SignatureToolbar = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <div className='absolute z-[10000] top-0 right-0'>
      <div className='flex flex-row items-center space-x-2 p-2'>{children}</div>
    </div>
  )
}

export const SignatureToolbarButton = forwardRef<
  React.ElementRef<typeof Button>,
  ButtonProps
>((props, ref) => {
  return (
    <Button
      className='text-gray-500'
      size='xs'
      variant={'ghost'}
      ref={ref}
      {...props}
    />
  )
})

export const SignatureClearButton = forwardRef<
  React.ElementRef<typeof Button>,
  ButtonProps
>((props, ref) => {
  return <SignatureToolbarButton {...props}>LIMPIAR</SignatureToolbarButton>
})

export const SignatureCard = forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement> & {
    isInvalid?: boolean
  }
>(({ isInvalid, ...props }, ref) => {
  return (
    <div
      className={cn(
        'pointer rounded-md h-[12rem] flex items-end justify-start relative bg-gray-100',
        {
          'border border-red-500': isInvalid,
          'border border-[#e9ecef]': !isInvalid,
        },
      )}
      ref={ref}
      {...props}
    />
  )
})
