import { Component, ErrorInfo } from 'react'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)

    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error: Error) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log('error', error)
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      return <p>error</p>
    }

    // Return children components in case of no error
    return this.props.children
  }
}

export default ErrorBoundary
