import React from 'react'

export const useSafeContext = <T>(context: React.Context<T>): T => {
  const contextValue = React.useContext(context)

  if (contextValue === undefined) {
    throw new Error(
      `You need to wrap your component in a <${context.displayName}> provider in order to use this component`,
    )
  }

  return contextValue
}
