'use client'
import * as React from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { cn } from '../utils/cn'
import { useSafeContext } from '../hooks/useSafeContext'
import {
  IconAlertTriangle,
  IconCircleCheck,
  IconExclamationCircle,
  IconInfoCircle,
} from '@tabler/icons-react'

const STATUSES = {
  info: { icon: IconInfoCircle },
  warning: { icon: IconAlertTriangle },
  success: { icon: IconCircleCheck },
  error: { icon: IconExclamationCircle },
}

export type AlertStatus = keyof typeof STATUSES

export function getStatusIcon(status: AlertStatus) {
  return STATUSES[status].icon
}

const alertStatus = cva(
  'relative w-full rounded border p-4 [&>svg]:absolute [&>svg]:text-foreground [&>svg]:left-4 [&>svg]:top-[0.8rem] [&>svg+div]:translate-y-[-3px] [&:has(svg)]:pl-12',
  {
    variants: {
      status: {
        error:
          'bg-error text-error border-error/50 dark:border-error [&>svg]:text-error text-error',
        success:
          'bg-success text-success border-success/50 dark:border-success [&>svg]:text-success text-success',
        info: 'bg-primary text-primary border-primary/50 dark:border-primary [&>svg]:text-primary text-primary',
        warning:
          'bg-warning text-warning border-warning/50 dark:border-warning [&>svg]:text-warning text-warning',
      },
    },
  },
)

const alertVariant = cva('', {
  variants: {
    variant: {
      solid: 'text-white [&>svg]:text-white',
      outline: 'bg-transparent',
    },
  },
  defaultVariants: {
    variant: 'solid',
  },
})

interface IAlertContext extends VariantProps<typeof alertStatus> {}

const AlertContext = React.createContext<IAlertContext>({
  status: 'info',
})

const AlertProvider = (props: React.PropsWithChildren<IAlertContext>) => {
  return (
    <AlertContext.Provider value={props}>
      {props.children}
    </AlertContext.Provider>
  )
}

const useAlertContext = () => useSafeContext(AlertContext)

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof alertStatus> &
    VariantProps<typeof alertVariant>
>(({ className, status, variant, children, ...props }, ref) => (
  <AlertProvider status={status}>
    <div
      ref={ref}
      role='alert'
      className={cn(
        alertStatus({ status }),
        alertVariant({ variant }),
        className,
      )}
      {...props}
    >
      {children}
    </div>
  </AlertProvider>
))
Alert.displayName = 'Alert'

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn('mb-1 font-medium leading-none tracking-tight', className)}
    {...props}
  />
))
AlertTitle.displayName = 'AlertTitle'

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('text-sm [&_p]:leading-relaxed', className)}
    {...props}
  />
))
AlertDescription.displayName = 'AlertDescription'

const AlertIcon = React.forwardRef<
  SVGSVGElement,
  React.HTMLAttributes<SVGSVGElement>
>(({ className, ...props }, ref) => {
  const ctx = useAlertContext()
  const Icon = getStatusIcon(ctx.status)
  return <Icon ref={ref} className={cn('', className)} {...props} />
})
AlertIcon.displayName = 'AlertIcon'

export { Alert, AlertTitle, AlertDescription, AlertIcon }
