import React from 'react';
import { IIcon } from './BikeIcon';

const Active = ({ width, height }: IIcon) => (
  <svg
    style={{ width, height }}
    xmlns="http://www.w3.org/2000/svg"
    data-name="Capa 1"
    viewBox="0 0 660.47 660.47"
  >
    <path
      d="M271.5 184.1l58.81 29.75 62.74-29.75S368.3 135 333 136.51c0 0-36.13-2.29-61.5 47.59z"
      fill="#dcd9ed"
      stroke="#dcd9ed"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4.058}
    />
    <path
      d="M262.42 466.34v-94.92s64.19-63.09 132.78 0v41.7"
      fill="none"
      stroke="#7b5edb"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={15}
    />
    <path
      d="M262.42 413.12H235l-18.52-58.77c-8.4-26.64-16.5-53.35-25.2-79.9a3.32 3.32 0 01-.17-2.25 3.26 3.26 0 011.9-1.47l22.45-9.53c6.63-2.81 13.8-6 18.52-11.62 5.59-6.68 9.33-16.06 13.72-23.62 10.52-18.12 21-36.28 30.84-54.77s29.48-31.4 50.35-33c23.05-1.74 42.82 11.83 54.73 30.78 7.08 11.26 12 23.64 18.26 35.36Q409 217.81 416 231.32c4.54 8.71 7 20.14 16.6 24.59 12.72 5.87 25.87 11 38.9 16.1-8.62 25.41-17.75 50.62-26.62 75.94l-22.73 64.91-.09.26H395.2v53.22"
      fill="none"
      stroke="#7b5edb"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={15}
    />
    <path
      fill="none"
      stroke="#7b5edb"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={15}
      d="M264.63 196.18l66.48 35.33 66.5-35.33M482.43 213.85l-68.03 14.34M178.04 213.85l68.03 14.34"
    />
    <rect
      x={303.98}
      y={374}
      width={63.59}
      height={164.75}
      rx={24.5}
      fill="#dcd9ed"
    />
    <rect
      x={292.64}
      y={390.95}
      width={60.3}
      height={156.24}
      rx={23.24}
      fill="none"
      stroke="#7b5edb"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={15}
    />
    <rect
      x={286.73}
      y={268.29}
      width={98.08}
      height={41.29}
      rx={15.23}
      fill="#dcd9ed"
    />
    <rect
      x={273.76}
      y={277.26}
      width={98.08}
      height={41.29}
      rx={15.23}
      fill="none"
      stroke="#7b5edb"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={11.138}
    />
  </svg>
);

function FrontIconMoto({ width, height }: IIcon) {
  return <Active height={height} width={width} />;
}
export default FrontIconMoto;
