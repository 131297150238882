'use client'

import * as React from 'react'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import { DayPicker, DropdownProps } from 'react-day-picker'
import { cn } from '../utils/cn'
import { buttonVariants } from './Button'
import { es } from 'date-fns/locale'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './Select'

export type CalendarProps = React.ComponentProps<typeof DayPicker>

/**
 * A date field component that allows users to enter and edit date.
 */
function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      locale={es}
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      fromYear={1800}
      toYear={new Date().getFullYear()}
      captionLayout='dropdown'
      classNames={{
        caption_dropdowns: 'flex flex-row space-x-2 items-center',
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          buttonVariants({ variant: 'outline' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell:
          'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: 'text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-9 w-9 p-0 font-normal aria-selected:opacity-100',
        ),
        day_selected:
          'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
        day_today: 'bg-accent text-accent-foreground',
        day_outside: 'text-muted-foreground opacity-50',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_middle:
          'aria-selected:bg-accent aria-selected:text-accent-foreground',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        CaptionLabel: () => null,
        Dropdown: ({
          value,
          onChange,
          children,
          name,
          style,
          ...props
        }: DropdownProps) => {
          const options = React.Children.toArray(
            children,
          ) as React.ReactElement<React.HTMLProps<HTMLOptionElement>>[]

          const selected = options.find((child) => child.props.value === value)

          const handleChange = (value: string) => {
            const changeEvent = {
              target: { value },
            } as React.ChangeEvent<HTMLSelectElement>
            onChange?.(changeEvent)
          }

          console.log('dropdown')

          return (
            <Select
              defaultValue={value?.toString()}
              onValueChange={(value) => handleChange(value)}
              {...style}
            >
              <SelectTrigger>
                <SelectValue
                  className='uppercase'
                  placeholder='Selecciona un mes'
                />
              </SelectTrigger>
              <SelectContent>
                {options.map((option, id: number) => (
                  <SelectItem
                    className='capitalize'
                    data-select-option={option.props.value?.toString() ?? ''}
                    key={`${option.props.value}-${id}`}
                    value={option.props.value?.toString() ?? ''}
                  >
                    {option.props.children}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )
        },
        IconLeft: ({ ...props }) => <IconChevronLeft className='h-4 w-4' />,
        IconRight: ({ ...props }) => <IconChevronRight className='h-4 w-4' />,
      }}
      {...props}
    />
  )
}
Calendar.displayName = 'Calendar'

export { Calendar }
