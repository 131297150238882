'use client'
import { IconFileUnknown, IconLoader2 } from '@tabler/icons-react'
import axios from 'axios'
import Papa from 'papaparse'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Column, useTable } from 'react-table'
import '../styles/docx.scss'
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Table as TableUi,
} from './Table'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsListProvider,
  TabsTrigger,
} from './Tabs'

interface Props {
  filename: string
  src: string
}

const Table = <T extends object>(props: {
  data: T[]
  columns: Column<T>[]
  noDataMessage?: string
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: props.columns, data: props.data })

  return (
    <div className='shadow-md rounded-md max-w-full bg-white p-4 overflow-auto'>
      <TableUi {...getTableProps()}>
        <TableHeader>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <TableHead {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody {...getTableBodyProps()} className='text-center'>
          {!rows.length && props.noDataMessage && (
            <p className='mt-4 text-center'>{props.noDataMessage}</p>
          )}
          {rows.map((row) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </TableUi>
    </div>
  )
}

const DownloadFallback = (props: { src: string; context: string }) => {
  return (
    <div className='w-full flex flex-col items-center justify-center text-center space-y-2'>
      <div className='w-full flex flex-col items-center justify-center text-center space-y-2'>
        <IconFileUnknown className='w-12 h-12' />
        <div>
          <p className='m-0 font-bold text-xl'>Archivo no soportado</p>
        </div>
      </div>

      <p className='text-sm'>
        {props.context}. En su lugar, puedes{' '}
        <a
          className='text-primary font-bold hover:underline'
          target={'_blank'}
          rel='noreferrer'
          href={props.src}
        >
          descargarlo
        </a>
        .
      </p>
    </div>
  )
}

const Loader = () => {
  return (
    <div className='w-full h-full flex items-center justify-center'>
      <IconLoader2 className='h-6 w-6 animate-spin text-primary' />
    </div>
  )
}

const DOCX = (props: { src: string }) => {
  const ref = useRef<HTMLDivElement>(null)

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)

  const convertDocxToHtml = useCallback(async () => {
    axios
      .get(props.src, { responseType: 'arraybuffer', withCredentials: false })
      .then((res) => {
        import('mammoth/mammoth.browser').then((mammoth) => {
          mammoth
            .convertToHtml(
              { arrayBuffer: res.data },
              { includeDefaultStyleMap: true },
            )
            .then((res) => {
              const docEl = window.document.createElement('div')
              docEl.className = 'document-container'
              docEl.innerHTML = res.value
              if (ref.current) {
                ref.current.innerHTML = docEl.outerHTML
              }
            })
            .catch(setError)
            .finally(() => setIsLoading(false))
        })
      })
      .catch(setError)
      .finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    convertDocxToHtml()
  }, [])

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    return (
      <DownloadFallback
        context={'Hubo un error al cargar el archivo'}
        src={props.src}
      />
    )
  }

  return (
    <div
      className='w-full p-7 overflow-y-scroll max-h-[800px] bg-white'
      id='docx'
      ref={ref}
    />
  )
}

const XLSX = (props: { src: string }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [sheets, setSheets] = useState<{ name: string; csv: string }[]>([])

  const readXLSX = useCallback(async () => {
    axios
      .get(props.src, { responseType: 'arraybuffer', withCredentials: false })
      .then((res) => {
        import('xlsx').then((xlsx) => {
          const workbook = xlsx.read(res.data)

          console.log('workbook.Sheets', workbook.Sheets)

          setSheets(
            Object.keys(workbook.Sheets).map((sheet) => {
              return {
                name: sheet,
                csv: xlsx.utils.sheet_to_csv(workbook.Sheets[sheet], {
                  blankrows: false,
                }),
              }
            }),
          )
        })
      })
      .finally(() => setIsLoading(false))
      .catch((err) => {
        setError(err)
      })
  }, [props.src])

  useEffect(() => {
    readXLSX()
  }, [])

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    return (
      <DownloadFallback
        context={'Hubo un error al cargar el archivo'}
        src={props.src}
      />
    )
  }

  return (
    <TabsListProvider variant='underline'>
      <Tabs>
        <TabsList>
          {sheets.map(({ name }, index) => {
            return (
              <TabsTrigger key={index} value={name}>
                {name}
              </TabsTrigger>
            )
          })}
        </TabsList>
        {sheets.map((sheet, index) => {
          return (
            <TabsContent value={sheet.name} key={index}>
              <CSV key={index} src={sheet.csv} inline />
            </TabsContent>
          )
        })}
      </Tabs>
    </TabsListProvider>
  )
}

const CSV = (props: { src: string; inline?: boolean }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [columns, setColumns] = useState<Column<object>[]>([])
  const [data, setData] = useState<object[]>([])

  const readCSV = useCallback(() => {
    new Promise<Papa.ParseResult<unknown>>((resolve, reject) =>
      Papa.parse(props.src, {
        transformHeader: (header) => header.trim(),
        skipEmptyLines: true,
        download: props.inline ? false : true,
        header: true,
        complete: (results) => resolve(results),
        error: (error) => reject(error),
      }),
    )
      .then((res) => {
        if (res.meta.fields) {
          setColumns(
            res.meta.fields?.map((field, index) => ({
              id: !field ? `empty-${index}` : field,
              Header: field,
              accessor: field,
            })),
          )
        }

        setData(res.data)
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false))
  }, [props.src])

  useEffect(() => {
    readCSV()
  }, [])

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    return (
      <DownloadFallback
        context={'Hubo un error al cargar el archivo'}
        src={props.src}
      />
    )
  }

  return (
    <div className='w-full'>
      <Table columns={columns} data={data} />
    </div>
  )
}

const Drivers = (props: { filename: string; src: string }) => {
  const getFileFormat = useCallback(
    (filename: string) => filename.split('.').pop()?.toLowerCase(),
    [],
  )

  switch (getFileFormat(props.filename)) {
    case 'bmp':
    case 'gif':
    case 'png':
    case 'jpg':
    case 'jpeg':
      return (
        <div className='flex w-full h-full justify-center items-center p-4'>
          <img src={props.src} alt={props.filename} />
        </div>
      )

    case 'docx':
      return <DOCX src={props.src} />

    case 'pdf':
      return (
        <div className='w-full'>
          <object
            data={props.src}
            type='application/pdf'
            width='100%'
            height='800px'
          >
            <DownloadFallback
              context='Tu navegador no puede mostrar este PDF'
              src={props.src}
            />
          </object>
        </div>
      )

    case 'wav':
    case 'mp3':
      return (
        <div className='flex items-center justify-center w-full'>
          <audio controls src={props.src}>
            <DownloadFallback
              context='Tu navegador no puede reproducir este audio'
              src={props.src}
            />
          </audio>
        </div>
      )

    case 'mov':
    case 'webm':
    case 'mp4':
      return (
        <div className='fuelx items-center justify-center w-full'>
          <video controls src={props.src}>
            <DownloadFallback
              context='Tu navegador no puede reproducir este video'
              src={props.src}
            />
          </video>
        </div>
      )

    case 'csv':
      return <CSV src={props.src} />

    case 'xlsx':
      return <XLSX src={props.src} />

    default:
      return (
        <DownloadFallback
          context='No se puede visualizar este archivo'
          src={props.src}
        />
      )
  }
}

export const FileViewer = (props: Props) => {
  return (
    <div className='file-viewer-wrapper p-4 bg-white flex justify-center items-center w-full h-full'>
      <Drivers src={props.src} filename={props.filename} />
    </div>
  )
}
