'use client'
import * as React from 'react'
import { cn } from '../utils/cn'
import { VariantProps, cva } from 'class-variance-authority'
import { useSafeContext } from '../hooks/useSafeContext'

const tableBodyVariants = cva('', {
  variants: {
    variant: {
      striped: '[&_tr:nth-child(odd)]:bg-muted/50',
    },
  },
})

const tableRowVariants = cva(
  'border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted',
  {
    variants: {
      size: {
        sm: 'text-sm [&>*]:p-2',
        md: 'text-md [&>*]:p-4',
        lg: 'text-lg [&>*]:p-6',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
)

interface TableContext
  extends VariantProps<typeof tableBodyVariants>,
    VariantProps<typeof tableRowVariants> {}

const TableContext = React.createContext<TableContext>({})

const useTableContext = () => useSafeContext(TableContext)

/**
 * A responsive table component.
 */
const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement> & TableContext
>(({ className, variant, size, ...props }, ref) => {
  return (
    <TableContext.Provider
      value={{
        variant,
        size,
      }}
    >
      <div className='w-full overflow-auto'>
        <table
          ref={ref}
          className={cn('w-full caption-bottom text-sm', className)}
          {...props}
        />
      </div>
    </TableContext.Provider>
  )
})
Table.displayName = 'Table'

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead ref={ref} className={cn('[&_tr]:border-b', className)} {...props} />
))
TableHeader.displayName = 'TableHeader'

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => {
  const ctx = useTableContext()

  return (
    <tbody
      ref={ref}
      className={cn(
        tableBodyVariants({
          className,
          variant: ctx.variant,
        }),
      )}
      {...props}
    />
  )
})
TableBody.displayName = 'TableBody'

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn('bg-primary font-medium text-primary-foreground', className)}
    {...props}
  />
))
TableFooter.displayName = 'TableFooter'

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => {
  const ctx = useTableContext()

  return (
    <tr
      ref={ref}
      className={cn(
        tableRowVariants({
          size: ctx.size,
          className,
        }),
      )}
      {...props}
    />
  )
})
TableRow.displayName = 'TableRow'

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      'h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0',
      className,
    )}
    {...props}
  />
))
TableHead.displayName = 'TableHead'

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn('p-4 align-middle [&:has([role=checkbox])]:pr-0', className)}
    {...props}
  />
))
TableCell.displayName = 'TableCell'

const tableCaptionVariants = cva('mt-4 text-muted-foreground', {
  variants: {
    size: {
      sm: 'text-sm',
      md: 'text-md',
      lg: 'text-lg',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => {
  const ctx = useTableContext()

  return (
    <caption
      ref={ref}
      className={cn(
        tableCaptionVariants({
          className,
          size: ctx.size,
        }),
      )}
      {...props}
    />
  )
})

TableCaption.displayName = 'TableCaption'

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
}
