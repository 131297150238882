import { useObjectRef } from 'ai-components'
import React, { useMemo } from 'react'
import { useLocale, useNumberField } from 'react-aria'
import { NumberFieldStateOptions, useNumberFieldState } from 'react-stately'
import { Input } from 'ui'

export const NumberFieldLocal = React.forwardRef<
  React.ComponentRef<typeof Input>,
  Partial<
    Omit<NumberFieldStateOptions, 'locale' | 'formatOptions'> &
      Omit<
        React.ComponentPropsWithoutRef<typeof Input>,
        'onChange' | 'value' | 'defaultValue' | 'max' | 'min'
      > & {
        formatOptions?: Intl.NumberFormatOptions
        error?: any
        variant?: 'filled' | 'unstyled'
        locale?: string
        onChange: (value?: number) => void
        value?: number
        defaultValue?: string | number
      }
  >
>(({ error, variant, locale, formatOptions, name, ...props }, ref) => {
  const { locale: inferredLocale } = useLocale()

  const formatOptionsMemoized = useMemo((): Intl.NumberFormatOptions => {
    if (!formatOptions?.useGrouping) {
      return {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: false,
      }
    }

    return formatOptions
  }, [])

  let state = useNumberFieldState({
    ...props,
    formatOptions: formatOptionsMemoized,
    locale: locale || inferredLocale,
  })

  const forwardRef = useObjectRef(ref)

  let { inputProps } = useNumberField(
    {
      ...props,
      formatOptions: formatOptionsMemoized,
    },
    state,
    forwardRef,
  )

  return (
    <Input
      {...inputProps}
      // pattern='[0-9]*'
      name={name}
      size={props.size}
      invalid={!!error}
      ref={forwardRef}
      variant={variant}
    />
  )
})
