'use client'

import { IconTrash } from '@tabler/icons-react'
import { useClickAway } from '@uidotdev/usehooks'
import { documentFieldMaxHeight, documentFieldMaxWidth } from 'ai-constants'
import React, { useEffect, useState } from 'react'
import { Rnd } from 'react-rnd'
import {
  Button,
  cn,
  Label,
  NumberFieldLocal,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'ui'

export const ResizeHandler = ({
  hovering,
}: {
  hovering: boolean
}): React.ReactElement<any> => {
  return (
    <div className='w-full h-full relative'>
      <div className='w-full h-full absolute flex items-center justify-center'>
        <button
          className={cn(
            'border border-primary rounded-full bg-white w-[10px] h-[10px] z-[10000] transition-all',
            {
              'opacity-0': !hovering,
            },
          )}
        />
      </div>
    </div>
  )
}

export interface DocumentField {
  height: number
  width: number
  identifier: string
  page: number
  readonly?: boolean
  positionX: number
  positionY: number
  label: string
  fontSize: number
}

export const EditableField = ({
  field,
  onDelete,
  onFocusField,
  onBlurField,
  onFieldChange,
}: {
  field: DocumentField
  onFieldChange: (state: Partial<DocumentField>) => void
  onDelete: () => void
  onFocusField: () => void
  onBlurField: () => void
}) => {
  const nodeRef = React.createRef<HTMLDivElement>()

  const [isDragging, setIsDragging] = useState(false)

  const [controlledPosition, setControlledPosition] = useState({
    x: field.positionX,
    y: field.positionY,
  })

  const [open, setOpen] = useState(false)

  const [isResizing, setIsResizing] = useState(false)

  const popoverRef = useClickAway<HTMLDivElement>(() => {
    setOpen(false)
    onBlurField()
  })

  const [hovering, setHovering] = useState(false)

  const pxToValue = (px: string) => Number(px.split('px')[0])

  useEffect(() => {
    setControlledPosition({
      x: field.positionX,
      y: field.positionY,
    })
  }, [field?.positionX, field?.positionY])

  return (
    <Popover
      onOpenChange={(open) => {
        if (!open) {
          onBlurField()
          setOpen(false)
        }
      }}
      open={open}
    >
      <Rnd
        bounds={'parent'}
        resizeHandleComponent={{
          bottomRight: <ResizeHandler hovering={hovering} />,
          bottomLeft: <ResizeHandler hovering={hovering} />,
          bottom: <ResizeHandler hovering={hovering} />,
          left: <ResizeHandler hovering={hovering} />,
          right: <ResizeHandler hovering={hovering} />,
          top: <ResizeHandler hovering={hovering} />,
          topLeft: <ResizeHandler hovering={hovering} />,
          topRight: <ResizeHandler hovering={hovering} />,
        }}
        maxWidth={documentFieldMaxWidth}
        maxHeight={documentFieldMaxHeight}
        className={cn(
          'flex items-center justify-center absolute top-0 left-0 cursor-move select-none p-[2px] text-center text-[15px] font-bold bg-[#7f11e017]',
          {
            'border-2 border-[#7B5EDB]': hovering,
            'border-b-2 border-b-[#7B5EDB]': !hovering,
          },
        )}
        position={{
          x: controlledPosition.x,
          y: controlledPosition.y,
        }}
        size={{
          width: field.width,
          height: field.height,
        }}
        onDrag={() => setIsDragging(true)}
        onDragStop={(e, d) => {
          onFieldChange({ positionX: d.x, positionY: d.y })

          setControlledPosition({
            x: d.x,
            y: d.y,
          })

          setTimeout(() => {
            setIsDragging(false)
          }, 100)
        }}
        onResizeStart={(e, dir, ref) => {
          const nextState = !isResizing

          setIsResizing(!isResizing)
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
          onFieldChange({
            width: pxToValue(ref.style.width),
            height: pxToValue(ref.style.height),
            positionX: position.x,
            positionY: position.y,
          })
        }}
      >
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <PopoverTrigger asChild>
                <div
                  className='w-full h-full flex items-center justify-center'
                  onMouseOver={() => setHovering(true)}
                  onMouseOut={() => setHovering(false)}
                  onMouseLeave={() => setHovering(false)}
                  onMouseEnter={() => setHovering(true)}
                  onClick={() => {
                    if (isDragging) return

                    const nextState = !open

                    if (nextState) {
                      onFocusField()
                    } else {
                      onBlurField()
                    }

                    setOpen(nextState)
                  }}
                >
                  <p className='mx-2 truncate text-[#000000] font-bold text-xs'>
                    {field.label}
                  </p>
                </div>
              </PopoverTrigger>
            </TooltipTrigger>
            {!(isDragging || isResizing) && (
              <TooltipContent className='z-[100000]'>
                <p>{field.label}</p>
              </TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider>
      </Rnd>
      <PopoverContent
        sideOffset={10}
        className='cursor-default z-[100] w-[320px]'
        ref={popoverRef}
      >
        <div className='flex flex-col space-y-2'>
          <div className='flex flex-row z-[100] items-center justify-between'>
            <h2 className='font-bold text-gray-500 text-sm'>
              CONFIGURACIÓN DEL CAMPO
            </h2>
            <Button
              onClick={() => {
                onBlurField()
                onDelete()
              }}
              size='sm'
              aria-label='remove'
              variant='ghost'
            >
              <IconTrash className='w-[20px] h-[20px]  text-gray-500' />
            </Button>
          </div>
          <div>
            <div className='flex flex-row space-x-10 items-center'>
              <Label className='text-sm'>Fuente</Label>
              <NumberFieldLocal
                minValue={1}
                maxValue={100}
                defaultValue={field.fontSize}
                onChange={(value) => onFieldChange({ fontSize: value })}
                size='sm'
              />
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
