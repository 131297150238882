import { useRef } from 'react'

export const useBoxDimensions = () => {
  const ref = useRef<HTMLDivElement | HTMLButtonElement>(null)

  return [
    ref,
    {
      width: ref.current?.offsetWidth,
      height: ref.current?.offsetHeight,
    },
  ] as [
    typeof ref,
    {
      width: number | undefined
      height: number | undefined
    },
  ]
}
