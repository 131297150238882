'use client'

import * as React from 'react'
import * as SwitchPrimitives from '@radix-ui/react-switch'
import { cn } from '../utils/cn'
import { VariantProps, cva } from 'class-variance-authority'

const variants = cva(
  'box-content peer inline-flex h-[24px] w-[44px] p-0.5 shrink-0 cursor-pointer items-center rounded-full border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input',
  {
    variants: {
      size: {
        sm: 'h-3 w-[22px]',
        md: 'h-4 w-[30px]',
        lg: 'h-6 w-[46px]',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
)

/**
 * A control that allows the user to toggle between checked and not checked.
 */
const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> &
    VariantProps<typeof variants>
>(({ className, size = 'lg', ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      variants({
        size,
        className,
      }),
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        'pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform',
        {
          'h-3 w-3 data-[state=checked]:translate-x-2.5 data-[state=unchecked]:translate-x-0':
            size === 'sm',
          'h-4 w-4 data-[state=checked]:translate-x-3.5 data-[state=unchecked]:translate-x-0':
            size === 'md',
          'h-6 w-6 data-[state=checked]:translate-x-[22px] data-[state=unchecked]:translate-x-0':
            size === 'lg',
        },
      )}
    />
  </SwitchPrimitives.Root>
))
Switch.displayName = SwitchPrimitives.Root.displayName

export { Switch }
