import { messageTemplateVariables } from 'ai-constants'
import React from 'react'
import { MdAddCircleOutline } from 'react-icons/md'
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  Separator,
  Textarea,
  cn,
} from 'ui'
import { useMessageTemplateForm } from '../hooks/useMessageTemplateForm'
import {
  DeliveryChannel,
  IMessageTemplate,
} from '../providers/MessageTemplate.provider'
import { IMessageTemplateSchema } from '../schemas/message-template.schema'
import { MessageTemplatePreview } from './MessageTemplatePreviews'
import { TranslatorOnlyString } from './Translator'
import { IconPlus } from '@tabler/icons-react'

const AddVariable = (props: { onVariableSelected: (path: string) => void }) => {
  return (
    <div>
      <Popover modal>
        <PopoverTrigger asChild>
          <Button
            variant='link'
            className='text-gray-600 font-normal hover:no-underline text-foreground text-xs pl-0'
            size='sm'
          >
            <IconPlus className='mr-2 h-4 w-4' />
            Añadir variable
          </Button>
        </PopoverTrigger>
        <PopoverContent className='pr-2'>
          <ScrollArea
            orientation='vertical'
            className='max-h-60 h-full flex flex-col'
          >
            <div className='flex flex-col pr-4'>
              {messageTemplateVariables.map((ele, index) => {
                return (
                  <div key={index}>
                    <p className='font-bold text-sm'>
                      {TranslatorOnlyString({
                        children: ele.group,
                      })}
                    </p>
                    {ele.paths.map((path) => {
                      return (
                        <div>
                          {(() => {
                            if (path.path) {
                              return (
                                <p
                                  className='transition-all cursor-pointer p-1 ml-2 text-xs'
                                  onClick={() =>
                                    props.onVariableSelected(
                                      `${ele.group}.${path.path}`,
                                    )
                                  }
                                >
                                  {TranslatorOnlyString({
                                    children: path.path,
                                  })}
                                </p>
                              )
                            }

                            return (
                              <div>
                                <Separator className='my-1' />
                                <p className='font-bold text-sm'>
                                  {TranslatorOnlyString({
                                    children: path.group,
                                  })}
                                </p>
                                {(path.paths as { path: string }[])?.map(
                                  (nestedPath, index: number) => {
                                    return (
                                      <p
                                        key={index}
                                        className='transition-all ml-2 cursor-pointer p-1 text-xs'
                                        onClick={() =>
                                          props.onVariableSelected(
                                            `${ele.group}.${path.group}.${nestedPath.path}`,
                                          )
                                        }
                                      >
                                        {TranslatorOnlyString({
                                          children: nestedPath.path,
                                        })}
                                      </p>
                                    )
                                  },
                                )}
                              </div>
                            )
                          })()}
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </ScrollArea>
        </PopoverContent>
      </Popover>
    </div>
  )
}

const FormFieldFooterMessage = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <FormDescription className='m-0 flex-shrink-0 text-xs'>
      {children}
    </FormDescription>
  )
}

const FormFieldFooter = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div
      className={cn(
        'h-6 mt-2 flex flex-row justify-between items-center',
        className,
      )}
    >
      {children}
    </div>
  )
}

export const MessageTemplateEditorV2 = (props: {
  gatewayStatus?: IMessageTemplate['gatewayStatus']
  children?: React.ReactNode
  channel?: DeliveryChannel
  formId: string
  onSubmit: (data: IMessageTemplateSchema) => void
  defaultValues?: IMessageTemplateSchema
}) => {
  const {
    bodyRest,
    buttonLabelRest,
    customButtonLabelRef,
    deadlineDays,
    fields,
    footerRest,
    handleSubmit,
    logo,
    onAddVariable,
    subjectRest,
    errors,
    bodyRef,
    buttonLabelRef,
    footerRef,
    subjectRef,
    register,
    customSubjectRef,
    customBodyRef,
    customFooterRef,
    form,
  } = useMessageTemplateForm({
    channel: props.channel,
    defaultValues: props.defaultValues,
  })



  return (
    <div className='grid grid-cols-2 gap-6 divide-x'>
      <div>
        <Form {...form}>
          <form
            onSubmit={handleSubmit(props.onSubmit)}
            id={props.formId}
            className='space-y-5 flex flex-col'
          >
            <FormField
              control={form.control}
              name={'subject'}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel className='text-sm'>Encabezado *</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        {...subjectRest}
                        ref={(e) => {
                          subjectRef(e)

                          customSubjectRef!.current! = e!
                        }}
                        size='sm'
                      />
                    </FormControl>
                    <FormFieldFooter>
                      <AddVariable
                        onVariableSelected={(path) =>
                          onAddVariable('subject', path)
                        }
                      />
                      <FormFieldFooterMessage>
                        Máximo 60 caracteres
                      </FormFieldFooterMessage>
                    </FormFieldFooter>
                  </FormItem>
                )
              }}
            />

            <FormField
              control={form.control}
              name={'body'}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel className='text-sm'>Mensaje *</FormLabel>
                    <FormControl>
                      <Textarea
                        {...field}
                        {...bodyRest}
                        className='h-48'
                        ref={(e) => {
                          bodyRef(e)

                          customBodyRef.current! = e!
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                    <FormFieldFooter>
                      <AddVariable
                        onVariableSelected={(path) =>
                          onAddVariable('body', path)
                        }
                      />
                      <FormFieldFooterMessage>
                        Máximo 1024 caracteres
                      </FormFieldFooterMessage>
                    </FormFieldFooter>
                  </FormItem>
                )
              }}
            />

            <FormField
              control={form.control}
              name={'buttonLabel'}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel className='text-sm'>Botón</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        {...buttonLabelRest}
                        ref={(e) => {
                          buttonLabelRef(e)
                          customButtonLabelRef!.current! = e!
                        }}
                        size='sm'
                      />
                    </FormControl>
                    <FormMessage />
                    <FormFieldFooter className='justify-end'>
                      <FormFieldFooterMessage>
                        Máximo 60 caracteres
                      </FormFieldFooterMessage>
                    </FormFieldFooter>
                  </FormItem>
                )
              }}
            />
          </form>
        </Form>
      </div>
      <div className='pl-6 pt-2 min-h-[550px] '>
        <div className='h-full flex flex-col justify-between items-end'>
          <MessageTemplatePreview fields={fields} channel={props.channel} />
          {props.children}
        </div>
      </div>
    </div>
  )
}
