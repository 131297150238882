import getConfig from 'next/config'
const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()
export const API_URL =
  serverRuntimeConfig?.apiUrl || publicRuntimeConfig?.apiUrl

console.log('API_URL', API_URL)

export const SOCKET_URL = publicRuntimeConfig?.SOCKET_URL

export const INSPECTION_SERVICE_WEB_SOCKETS_BASE_URL =
  publicRuntimeConfig?.INSPECTION_SERVICE_WEB_SOCKETS_BASE_URL

export const DASHBOARD_PATH = publicRuntimeConfig?.DASHBOARD_PATH
export const LANDING_PATH = publicRuntimeConfig?.LANDING_PATH
export const API_URL_DATASET = publicRuntimeConfig?.API_URL_DATASET

export const IS_DEV = publicRuntimeConfig?.FRONTEND_ENV === 'development'

export const OPEN_API_BASE_URL = publicRuntimeConfig?.OPEN_API_BASE_URL
