import React from 'react'
import { cn } from '../utils/cn'
import { VariantProps, cva } from 'class-variance-authority'
import { IconLoader2 } from '@tabler/icons-react'

const variants = cva('w-8 h-8 mr-2 text-primary animate-spin shrink-0', {
  variants: {
    size: {
      xs: 'w-4 h-4',
      sm: 'w-6 h-6',
      md: 'w-8 h-8',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
})

export const Spinner = React.forwardRef<
  SVGSVGElement,
  React.HTMLAttributes<SVGSVGElement> & VariantProps<typeof variants>
>(({ className, size, ...props }, ref) => {
  return (
    <IconLoader2
      className={cn(
        '',
        variants({
          size,
        }),
        className,
      )}
      ref={ref}
      {...props}
    />
  )
})

Spinner.displayName = 'Spinner'
