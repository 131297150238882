'use client'
import { Box, BoxProps, Stack } from '@chakra-ui/react'
import { documentFieldBoxSize } from 'ai-constants'
import React, { useMemo, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { DocumentField, EditableField } from './DocumentEditionLayer'
import ErrorBoundary from './ErrorBoundary'
import { LoadingDocumentSkeleton } from './LoadingDocumentSkeleton'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString()

export interface PDFViewerProps {
  resizing?: boolean
  refreshPageURL: (refreshToken: string) => Promise<{ url: string }>
  style?: BoxProps['style']
  fields?: DocumentField[]
  onFieldChange?: (
    fieldIdentifier: string,
    state: Partial<DocumentField>,
  ) => void
  onDeleteField?: (fieldIdentifier: string) => void
  onFocusField?: (fieldIdentifier: string) => void
  onBlurField?: (fieldIdentifier: string) => void
  scale?: number
  onDocumentLoadSuccess?: (data: {
    clientWidth: number
    clientHeight: number
    originalWidth: number
    originalHeight: number
  }) => void
  fieldDimensions?: {
    width: number
    height: number
  }
  pages?: number
  responsive?: boolean
  onMouseDown?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index?: number,
  ) => void
  onMouseUp?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index?: number,
  ) => void
  pdf:
    | string
    | {
        data: Uint8Array
      }
}

export const PDFViewer = ({
  style,
  fields,
  onFieldChange,
  onDeleteField,
  onFocusField,
  onBlurField,
  fieldDimensions = documentFieldBoxSize,
  pdf,
  onMouseDown,
  onMouseUp,
  pages,
  responsive = false,
  onDocumentLoadSuccess,
  scale = 1,
}: Omit<React.ComponentPropsWithoutRef<typeof Document>, 'file'> &
  PDFViewerProps) => {
  const [numPages, setNumPages] = useState<number | undefined>(
    pages || undefined,
  )
  const [loading, setLoading] = useState(
    typeof numPages === 'number' ? false : true,
  )

  const [containerRef, setContainerRef] = useState<HTMLDivElement>()

  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
  }

  const file = useMemo(() => {
    console.log('file recalculateD!')
    return pdf
  }, [])

  const pagesList = useMemo(() => {
    return new Array(numPages).fill(0)
  }, [numPages])

  return (
    <Box ref={(ref) => setContainerRef(ref!)} h='full' w='full' pos='relative'>
      <style>
        {`

    .pdf-document-container{
      margin: auto;
      width: auto;
      background-color: white;
    }

    .react-pdf__Document{
      background-color: white;
    }
          `}
      </style>

      <ErrorBoundary>
        <Document
          onError={(err) => {
            console.log('err', err)
          }}
          onLoadError={(err) => {
            console.log('err', err)
          }}
          file={file}
          onLoadSuccess={async (pdfObject) => {
            console.log('document loaded successfully!')
            setNumPages(pdfObject.numPages)
          }}
          options={options}
          renderMode='canvas'
          className='pdf-document-container'
          loading={() => ''}
        >
          {numPages && numPages > 0 && (
            <Stack direction={'column'} spacing={5}>
              {pagesList.map((_, pageIndex) => {
                return (
                  <Box
                    onMouseDown={(e) => {
                      if (e.button === 0 && onMouseDown)
                        onMouseDown(e, pageIndex)
                    }}
                    onMouseUp={(e) => {
                      if (e.button === 0 && onMouseUp) onMouseUp(e, pageIndex)
                    }}
                    zIndex={10}
                    key={pageIndex}
                    style={style}
                  >
                    <Box
                      opacity={loading ? 0 : 1}
                      display={'flex'}
                      alignItems='center'
                      justifyContent={'start'}
                      shadow='lg'
                      bg='white'
                      pos='relative'
                    >
                      <Page
                        scale={scale}
                        canvasBackground='white'
                        width={containerRef?.clientWidth}
                        loading={() => ''}
                        className='pdf-page'
                        key={`page_${pageIndex + 1}`}
                        pageNumber={pageIndex + 1}
                        renderAnnotationLayer={true}
                        renderTextLayer={false}
                        onRenderSuccess={(page) => {
                          if (pageIndex === 0 && onDocumentLoadSuccess) {
                            onDocumentLoadSuccess({
                              clientHeight: Math.ceil(page.height),
                              clientWidth: Math.ceil(page.width),
                              originalHeight: Math.ceil(page.originalHeight),
                              originalWidth: Math.ceil(page.originalWidth),
                            })
                          }
                        }}
                        onLoadSuccess={(page) => {
                          setLoading(false)
                        }}
                        onRenderError={() => {
                          setLoading(false)
                        }}
                      />
                      {!loading &&
                        fields &&
                        fields
                          .filter((field) => field.page === pageIndex)
                          .map((field) => {
                            if (field.readonly) {
                              return null
                            }

                            return (
                              <EditableField
                                onBlurField={() => {
                                  if (onBlurField) {
                                    onBlurField(field.identifier)
                                  }
                                }}
                                onFocusField={() => {
                                  if (onFocusField) {
                                    onFocusField(field.identifier)
                                  }
                                }}
                                onDelete={() => {
                                  if (onDeleteField) {
                                    onDeleteField(field.identifier)
                                  }
                                }}
                                key={field.identifier}
                                field={field}
                                onFieldChange={(data) => {
                                  if (onFieldChange) {
                                    onFieldChange(field.identifier, data)
                                  }
                                }}
                              />
                            )
                          })}
                    </Box>
                  </Box>
                )
              })}
            </Stack>
          )}
        </Document>
      </ErrorBoundary>

      <Box inset='0' pos='absolute'>
        <LoadingDocumentSkeleton hidden={!loading} />
      </Box>
    </Box>
  )
}
