import {
  InspectionType,
  revertActionDispatches,
  TemplateConditionLink,
} from 'ai-constants'
import {
  IConditionActions,
  IDynamicFieldAnswer,
  IDynamicFormType,
  InspectionVariable,
  TemplateCondition,
  TemplateConditionAction,
  TemplateConditionTerm,
  TemplateConditionTermVars,
  TemplateInput,
} from 'bff'
import { get } from 'lodash'
import { useCallback, useMemo } from 'react'
import { TemplateConditionTermOperator } from 'ai-constants'

export const useCondition = ({
  inputs = [],
  inspectionType,
  variables = [],
}: {
  inputs: Pick<
    TemplateInput,
    'identifier' | 'value' | 'behaviour' | 'target' | 'path'
  >[]
  inspectionType: InspectionType
  variables: InspectionVariable[]
}) => {
  const conditionOperators = useMemo<{
    [key in TemplateConditionTermOperator]: (
      base: any,
      targetVal: any,
    ) => boolean
  }>(() => {
    type MultiselectSelected = { selected: string }

    return {
      contains: (base, target) => {
        const isArray = Array.isArray(base)

        if (isArray) {
          return (base as MultiselectSelected[]).some(
            (opt) => opt.selected == target,
          )
        }

        return base?.includes(target)
      },
      notContains: (base: string, target) => {
        const isArray = Array.isArray(base)

        if (isArray) {
          return (base as MultiselectSelected[]).every(
            (opt) => opt.selected != target,
          )
        }

        return !base?.includes(target)
      },
      endsWith: (base: string, target) => base?.endsWith(target),
      equals: (base, target) => base == target,
      greaterThan: (base, target) => base > target,
      //TODO: Try to find another way to verify if empty when compare with declared info from server and current state in local
      isEmpty: (base) => base == '' || base == false,
      isFilled: (base) => !!base,
      lessThan: (base, target) => base < target,
      notEndsWith: (base: string, target) => !base?.endsWith(target),
      notEquals: (base, target) => base != target,
      notStartsWith: (base, target) => !base?.startsWith(target),
      startsWith: (base, target) => base?.startsWith(target),
    }
  }, [])

  const findConditionTargetVal = useCallback(
    (
      form: IDynamicFormType | TemplateInput[],
      target: TemplateConditionTermVars[1],
    ) => {
      if (!target) return ''

      if (Array.isArray(form)) {
        return target?.value
      }

      switch (target.type) {
        case 'input': {
          const referenceList = Array.isArray(form.inputs)
            ? form.inputs
            : Object.values(form.inputs)

          return referenceList.find(
            (input) => input.identifier === target.value,
          )?.value
        }

        case 'variable':
          return variables.find((v) => v.name === target.value)?.value

        case 'constant':
          return target.value
      }
    },
    [variables],
  )

  const meetTerms = useCallback(
    (form: IDynamicFormType | TemplateInput[], term: TemplateConditionTerm) => {
      if (!Object.keys(form).length || !term.vars) return

      let base: Pick<TemplateInput | IDynamicFieldAnswer, 'value'> | undefined =
        undefined

      const [left, right] = term.vars

      const referenceMatched = (() => {
        switch (left.type) {
          case 'input': {
            if (!Array.isArray(form)) {
              const input = inputs.find(
                (input) => input.identifier === left.value,
              )

              return {
                type: 'input',
                data: input,
              } as const
            }

            const input = form.find((input) => input.identifier === left.value)

            return {
              type: 'input',
              data: input,
            } as const
          }

          case 'variable': {
            const variable = (variables || []).find(
              (variable) => variable.name === left.value,
            )

            return {
              type: 'variable',
              data: variable,
            } as const
          }
        }
      })()

      if (!referenceMatched.data) return

      if (referenceMatched.type === 'input') {
        const inputMatched = referenceMatched.data

        if (inputMatched?.behaviour === 'dynamic') {
          if (Array.isArray(form)) {
            base = form.find((ele) => ele.identifier === left.value)
          }

          if (!Array.isArray(form) && 'inputs' in form) {
            base = Object.values((form as IDynamicFormType).inputs).find(
              (ele) => ele.identifier === left.value,
            )
          }
        }

        if (inputMatched?.behaviour === 'static') {
          base = {
            value:
              inputMatched.value ||
              get(
                form,
                `${
                  inputMatched.target === 'identity'
                    ? 'consumer'
                    : inspectionType
                }.${inputMatched.path}`,
              ),
          }
        }
      }

      if (referenceMatched.type === 'variable') {
        base = {
          value: referenceMatched.data.value,
        }
      }

      if (!base) return false

      const target = findConditionTargetVal(form, right)

      return conditionOperators[term.operator](base.value, target)
    },
    [variables],
  )

  const meetsTermsIterator = useCallback(
    (
      form: IDynamicFormType | TemplateInput[],
      terms: TemplateConditionTerm[],
      link: TemplateConditionLink,
    ): boolean => {
      if (link === 'all') {
        return terms.every((term) => meetTerms(form, term))
      }

      return terms.some((term) => meetTerms(form, term))
    },
    [variables],
  )

  const checkConditions = useCallback(
    (
      fields: IDynamicFormType | object,
      conditions: TemplateCondition[] = [],
    ) => {
      const events: IConditionActions[] = []

      conditions.forEach((condition) => {
        const conditionPass = [fields, inputs].some((declared) =>
          meetsTermsIterator(declared, condition.terms, condition.link),
        )

        const actions = condition.actions
          .filter(
            (
              action,
            ): action is Omit<TemplateConditionAction, 'dispatch'> & {
              dispatch: 'show' | 'hide'
            } => action.dispatch === 'show' || action.dispatch === 'hide',
          )
          .map((action) => {
            if (conditionPass) {
              return {
                event: action.dispatch,
                detail: action.target,
              }
            }

            const reverse = revertActionDispatches[action.dispatch]

            return {
              event: reverse,
              detail: action.target,
            }
          })

        events.push(...actions)
      })

      return events.map((event, index) => ({
        ...event,
        detail: {
          ...event.detail,
          value: event.detail.value?.filter(
            (asset) =>
              !events.find(
                (e, eventIterIndex) =>
                  e.event === 'show' &&
                  e.detail.value.includes(asset) &&
                  eventIterIndex !== index,
              ),
          ),
        },
      }))
    },
    [],
  )

  return {
    meetsTermsIterator,
    checkConditions,
  }
}
