import React, { createContext, useContext } from 'react'

export const deliveryChannels = ['wsp', 'sms', 'email'] as const

export type DeliveryChannel = (typeof deliveryChannels)[number]

import { MessageTemplateTypes } from 'ai-commons'

export interface IMessageTemplate {
  _id: string
  scope: 'company' | 'inspection_template'
  companyId: string
  userId: string
  status: 'active' | 'inactive'
  gatewayId?: string
  gatewayStatus?: 'approved' | 'rejected' | 'pending'
  channel: DeliveryChannel
  template: {
    footer?: string | null | undefined
    subject: string
    body: string
  }
  type?: MessageTemplateTypes
}

export interface IMessageTemplateContext {
  multiple?: boolean
  buttonForegroundColor?: string
  buttonBackgroundColor?: string
  logo?: string
  deadlineDays?: number
  channels: (typeof deliveryChannels)[number][]
  postMethod: (
    input: Pick<IMessageTemplate, 'template' | 'channel' | 'type'>,
  ) => Promise<any>
  updateMethod: (
    messageTemplateId: string,
    update: IMessageTemplate['template'],
  ) => Promise<any>
  updateStatusMethod: (
    messageTemplateId: string,
    update: Pick<IMessageTemplate, 'status' | 'type'>,
  ) => Promise<any>
}

export const MessageTemplateCtx = createContext<
  IMessageTemplateContext | undefined
>(undefined)

export const useMessageTemplate = () => useContext(MessageTemplateCtx)!

export const MessageTemplateProvider: React.FC<
  React.PropsWithChildren<IMessageTemplateContext>
> = ({ children, ...rest }) => {
  return (
    <MessageTemplateCtx.Provider value={rest}>
      {children}
    </MessageTemplateCtx.Provider>
  )
}
