import {
  Box,
  Button,
  chakra,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { messageTemplateVariables } from 'ai-constants'
import React, { useCallback, useContext, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { GoLinkExternal } from 'react-icons/go'
import { MdAddCircleOutline } from 'react-icons/md'
import { TransitionAllStyles } from '../constants/prebuild-styles'
import { useMessageTemplateForm } from '../hooks/useMessageTemplateForm'
import {
  DeliveryChannel,
  IMessageTemplate,
  MessageTemplateCtx,
} from '../providers/MessageTemplate.provider'
import {
  IMessageTemplateSchema,
  MessageTemplateSchema,
} from '../schemas/message-template.schema'
import { MessageTemplatePreview } from './MessageTemplatePreviews'
import { TranslatorOnlyString } from './Translator'

const AddVariable = (props: { onVariableSelected: (path: string) => void }) => {
  return (
    <Box>
      <Popover placement='bottom'>
        {
          // @ts-ignore
          <PopoverTrigger>
            <Button
              size='sm'
              color='gray.600'
              variant={'ghost'}
              fontWeight='normal'
              leftIcon={<MdAddCircleOutline />}
            >
              Añadir variable
            </Button>
          </PopoverTrigger>
        }

        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader fontWeight={'bold'}>Variables</PopoverHeader>
          <PopoverBody overflowY={'scroll'} maxH='15rem'>
            {messageTemplateVariables.map((ele, index) => {
              return (
                <Box key={index}>
                  <chakra.strong>
                    {TranslatorOnlyString({
                      children: ele.group,
                    })}
                  </chakra.strong>
                  {ele.paths.map((path) => {
                    return (
                      <Box>
                        {(() => {
                          if (path.path) {
                            return (
                              <Text
                                {...TransitionAllStyles}
                                cursor='pointer'
                                p={2}
                                _hover={{
                                  bg: 'gray.100',
                                }}
                                ml='2'
                                onClick={() =>
                                  props.onVariableSelected(
                                    `${ele.group}.${path.path}`,
                                  )
                                }
                              >
                                {TranslatorOnlyString({
                                  children: path.path,
                                })}
                              </Text>
                            )
                          }

                          return (
                            <Box>
                              <Divider my='2' />
                              <chakra.strong>
                                {TranslatorOnlyString({
                                  children: path.group,
                                })}
                              </chakra.strong>
                              {(path.paths as { path: string }[])?.map(
                                (nestedPath, index: number) => {
                                  return (
                                    <Text
                                      {...TransitionAllStyles}
                                      ml='2'
                                      key={index}
                                      cursor='pointer'
                                      p={2}
                                      _hover={{
                                        bg: 'gray.100',
                                      }}
                                      onClick={() =>
                                        props.onVariableSelected(
                                          `${ele.group}.${path.group}.${nestedPath.path}`,
                                        )
                                      }
                                    >
                                      {TranslatorOnlyString({
                                        children: nestedPath.path,
                                      })}
                                    </Text>
                                  )
                                },
                              )}
                            </Box>
                          )
                        })()}
                      </Box>
                    )
                  })}
                </Box>
              )
            })}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

const FormFieldFooterMessage = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <FormHelperText m={0} flexShrink={0}>
      {children}
    </FormHelperText>
  )
}

const FormFieldFooter = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      h={'6'}
      mt='2'
      display={'flex'}
      flexDir='row'
      justifyContent={'space-between'}
      alignItems='center'
    >
      {children}
    </Box>
  )
}

export const MessageTemplateEditor = (props: {
  gatewayStatus?: IMessageTemplate['gatewayStatus']
  children?: React.ReactNode
  channel?: DeliveryChannel
  formId: string
  onSubmit: (data: IMessageTemplateSchema) => void
  defaultValues?: IMessageTemplateSchema
}) => {
  const {
    bodyRest,
    buttonLabelRest,
    customButtonLabelRef,
    deadlineDays,
    fields,
    footerRest,
    handleSubmit,
    logo,
    onAddVariable,
    subjectRest,
    errors,
    bodyRef,
    buttonLabelRef,
    footerRef,
    subjectRef,
    register,
    customSubjectRef,
    customBodyRef,
    customFooterRef,
  } = useMessageTemplateForm({
    channel: props.channel,
    defaultValues: props.defaultValues,
  })

  return (
    <Box className='space-x-10' display={'flex'} flexDir='row'>
      <Box w='50%'>
        <chakra.form
          onSubmit={handleSubmit(props.onSubmit)}
          id={props.formId}
          className='space-y-5'
          display={'flex'}
          flexDir='column'
        >
          <FormControl isRequired isInvalid={!!errors.subject}>
            <FormLabel>Encabezado</FormLabel>
            <Input
              {...register('subject')}
              {...subjectRest}
              bg='white'
              ref={(e) => {
                subjectRef(e)

                customSubjectRef!.current! = e!
              }}
            />
            <FormFieldFooter>
              <FormFieldFooterMessage>
                Máximo 60 caracteres
              </FormFieldFooterMessage>
              <AddVariable
                onVariableSelected={(path) => onAddVariable('subject', path)}
              />
            </FormFieldFooter>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.body}>
            <FormLabel>Mensaje</FormLabel>
            <Textarea
              {...bodyRest}
              bg='white'
              ref={(e) => {
                bodyRef(e)
                customBodyRef.current! = e!
              }}
            />

            <FormFieldFooter>
              <FormFieldFooterMessage>
                Máximo 1024 caracteres
              </FormFieldFooterMessage>
              <AddVariable
                onVariableSelected={(path) => onAddVariable('body', path)}
              />
            </FormFieldFooter>
          </FormControl>
          <FormControl isInvalid={!!errors.buttonLabel}>
            <FormLabel>Texto del botón</FormLabel>
            <Input
              {...register('buttonLabel')}
              {...buttonLabelRest}
              bg='white'
              ref={(e) => {
                buttonLabelRef(e)

                customButtonLabelRef!.current! = e!
              }}
            />
            <FormFieldFooter>
              <FormFieldFooterMessage>
                Máximo 60 caracteres
              </FormFieldFooterMessage>
            </FormFieldFooter>
          </FormControl>
          <FormControl isInvalid={!!errors.footer}>
            <FormLabel>Pie de pagina</FormLabel>
            <Input
              {...register('footer')}
              {...footerRest}
              bg='white'
              ref={(e) => {
                footerRef(e)

                customFooterRef!.current! = e!
              }}
            />
            <FormFieldFooter>
              <FormFieldFooterMessage>
                Máximo 60 caracteres
              </FormFieldFooterMessage>
            </FormFieldFooter>
          </FormControl>

          {props.children}
        </chakra.form>
      </Box>
      <Box w='60%'>
        <MessageTemplatePreview fields={fields} channel={props.channel} />
      </Box>
    </Box>
  )
}
