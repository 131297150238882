import React from 'react';

export const TimelineStyles = () => {
  return (
    <style
      //@ts-ignore
      jsx={true}
      global
    >{`
      .time-line-box {
        padding: 2rem 0;
        width: 100%;
      }

      .time-line-box .timeline {
        list-style-type: none;
        display: flex;
        padding: 0;
        text-align: center;
      }

      .time-line-box .timestamp {
        margin: auto;
        margin-bottom: 5px;
        padding: 0px 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .time-line-box .status {
        padding: 0px 10px;
        display: flex;
        justify-content: center;
        border-top: 3px solid #455efc;
        position: relative;
        transition: all 200ms ease-in;
      }
      .time-line-box .status span {
        padding-top: 8px;
      }
      .time-line-box .status span:before {
        content: '';
        width: 12px;
        height: 12px;
        background-color: #455efc;
        border-radius: 12px;
        border: 2px solid #455efc;
        position: absolute;
        left: 50%;
        top: 0%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        transition: all 200ms ease-in;
      }

      .swiper-container {
        width: 100%;
        margin: auto;
      }
      .swiper-wrapper {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow-y: auto;
        width: 100%;
      }
      .swiper-container::-webkit-scrollbar-track {
        background: #a8a8a8b6;
      }
      .swiper-container::-webkit-scrollbar {
        height: 2px;
      }
      .swiper-container::-webkit-scrollbar-thumb {
        background: #4f4f4f !important;
      }
      .swiper-slide {
        text-align: center;
        font-size: 12px;
        width: 14rem;
        height: 100%;
        position: relative;
      }
    `}</style>
  );
};
