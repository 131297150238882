import { format } from 'date-fns'
import { forwardRef } from 'react'
import { BsCalendarEvent } from 'react-icons/bs'
import {
  Button,
  cn,
  inputVariants,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Calendar,
  useBoxDimensions,
  mergeRefs,
} from 'ui'

export const CalendarField = forwardRef<
  HTMLButtonElement,
  {
    value?: string
    onChange: (date: Date | undefined) => void
  } & React.HTMLProps<HTMLButtonElement>
>(({ className, value, onChange, ...props }, ref) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          ref={ref}
          variant='outline'
          className={cn(
            inputVariants({
              variant: 'outline',
            }),
            'w-full flex items-center justify-start font-normal',
            className,
          )}
          {...props}
        >
          <BsCalendarEvent className='mr-2' />
          {value
            ? format(new Date(value), 'LLL dd, y')
            : 'Selecciona una fecha'}
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-full'>
        <Calendar
          captionLayout='dropdown'
          numberOfMonths={1}
          mode='single'
          selected={value ? new Date(value) : undefined}
          onSelect={onChange}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
})
